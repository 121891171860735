<!-- Created by henian.xu on 2019/10/29. -->

<template>
    <div class="article">
        <slot name="header">
            <div
                class="header"
                v-if="label"
            >
                <h3 class="label">{{ label }}</h3>
                <slot name="info">
                    <div class="info">
                        <span
                            v-if="author"
                            class="item"
                        >作者: {{ author }}</span>
                        <span
                            v-if="source"
                            class="item"
                        >来源: {{ source }}</span>
                        <span
                            v-if="time"
                            class="item"
                        >时间: {{ time | moment }}</span>
                    </div>
                </slot>
            </div>
        </slot>
        <div class="body">
            <slot name="body">
                <div :class="contentClasses">
                    <slot />
                    <div
                        v-if="fold"
                        class="fold"
                    >
                        <slot name="fold"></slot>
                    </div>
                </div>
            </slot>
        </div>
        <!--<div class="footer"></div>-->
    </div>
</template>

<script>
export default {
    name: 'Article',
    data() {
        return {};
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        author: {
            type: String,
            default: '',
        },
        time: {
            type: [String, Number],
            default: '',
        },
        source: {
            type: String,
            default: '',
        },
        fold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        contentClasses() {
            const { fold } = this;
            return [
                'content',
                { fold },
                // add
            ];
        },
    },
};
</script>

<style lang="scss">
.article {
    background-color: $color-component-bgc;
    > .header {
        padding: $padding;
        > .label {
            margin: 0;
            text-align: center;
        }
        > .info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: $margin;
            color: $color-text-minor;
            > span {
                + span {
                    margin-left: $margin;
                }
            }
        }
    }
    > .body {
        @include rich-text-content;

        > .content {
            &.fold {
                position: relative;
                height: 40vh;
                overflow: hidden;
            }

            > .fold {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(
                    to top,
                    rgba(255, 255, 255, 1) 30%,
                    rgba(255, 255, 255, 0.8) 75%,
                    rgba(255, 255, 255, 0.5)
                );
                color: $color-text-minor;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                text-align: center;
                padding-bottom: $padding-big * 2;
            }
        }
    }

    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: $margin 0;
        color: $gray5;
        > span {
            + span {
                margin-left: $margin;
            }
        }
    }
}
</style>
