/**
 * Created by henian.xu on 2020/5/9.
 *
 */
import vueAwesomeSwiper from 'vue-awesome-swiper';
// eslint-disable-next-line import/no-extraneous-dependencies
// import 'swiper/css/swiper.css';

export default Vue => {
    const {
        SwiperSlide: { options },
    } = vueAwesomeSwiper;
    // const oldRender = options.render;
    // hook SwiperSlide 的 render 方法来添加事件绑定
    options.render = function render(createElement) {
        return createElement(
            'div',
            {
                class: this.slideClass,
                on: {
                    ...this.$listeners,
                },
            },
            this.$slots.default,
        );
    };
    Vue.use(vueAwesomeSwiper);
};
