<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <div class="comment-item">
        <Avatar
            radius="all"
            size="50"
        />
        <div class="content">
            <div class="header">
                <div class="nickname">nickname</div>
                <div class="agree">
                    <XIcon content="f111"></XIcon>
                    <div class="value">123</div>
                </div>
            </div>
            <div class="body">data.comments</div>
            <div class="footer">releaseTime</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommentItem',
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.comment-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .avatar {
        flex: 0 0 auto;
        margin-right: $margin-small;
    }
    > .content {
        flex: 1;
        > .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > .nickname {
                font-weight: bold;
            }

            > .agree {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                line-height: 1;
            }
        }
    }
}
</style>
