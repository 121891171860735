/**
 * Created by henian.xu on 2020/4/9.
 *
 */

import { get } from 'api/request';

export function getPortalInfoConfig() {
    return get(this.url);
}
