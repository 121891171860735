/**
 * Created by henian.xu on 2020/4/10.
 *
 */

import Vue from 'vue';
import { Api } from '@/api';

export const state = Vue.observable({
    childCache: {},
    childMap: {},
});

export function recordChildMap(list) {
    list.reduce((pre, cur) => {
        // Vue.prototype.$set(pre, cur.id, cur);
        pre[cur.id] = cur;
        return pre;
    }, state.childMap);
}

export async function getChild(pId) {
    const { childCache } = state;
    const cache = childCache[pId];
    if (cache) return cache;
    const { data } = await Api.Member.Sv.CertCat.getChild(pId);
    recordChildMap(data);
    Vue.prototype.$set(childCache, pId, data);
    return data;
    // console.log(data);
}
