<!-- Created by henian.xu on 2020/4/2. -->

<template>
    <div class="pay-popup">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <slot></slot>
        </div>

        <transition
            v-if="!lazy"
            name="show"
        >
            <div
                ref="popup"
                v-show="popupShow"
                class="popup down pay-popup"
                @click.self="close"
            >
                <transition name="popup-down">
                    <div class="inner">
                        <div class="body">
                            <CellGroup border>
                                <Cell
                                    v-for="(item,index) in payMethods"
                                    :key="index"
                                    :label="item.label"
                                    :sub-label="item.exportStr"
                                    :extra="item.extra"
                                    link
                                    @click="()=>{action(item.action,item.params)}"
                                >
                                    <template v-slot:icon>
                                        <XIcon
                                            :theme="item.iconTheme"
                                            :content="item.icon"
                                            size="super"
                                        />
                                    </template>
                                    <template v-slot:subLabel>
                                        <div class="sub-label tc-danger">
                                            {{ item.exportStr }}
                                        </div>
                                    </template>
                                </Cell>
                            </CellGroup>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';
import payMixin from './payMixin';

export default {
    name: 'PayPopup',
    mixins: [payMixin, popupMixin],
    data() {
        return {};
    },
    methods: {
        async open(type = this.type) {
            await this.$nextTick();
            if (this.orderIdsChanged) await this.toPay();

            const { payMethods } = this;
            if (payMethods.length === 1) {
                const [methods] = payMethods;
                return this.action(methods.action, methods.params);
            }

            return this.gotoType(type);
        },
    },
};
</script>

<style lang="scss">
</style>
