<!-- Created by henian.xu on 2019/3/28. -->

<template>
    <div
        class="pulley"
        @touchstart="onTouchStart"
        @touchend="onTouchEnd"
        @mousedown="onTouchStart"
    >
        <div class="mask" />
        <div
            ref="indicator"
            class="indicator" />
        <div
            class="content"
            :style="contentStyle">
            <template v-if="options.length">
                <div
                    class="item"
                    :style="itemStyle"
                    v-for="item in options"
                    :key="item.id">
                    {{ item.name }}
                </div>
            </template>
            <div
                v-else
                class="item"
                :style="itemStyle">
                无数据
            </div>
        </div>
        <!--<div class="console">
            <slot />
        </div>-->
    </div>
</template>

<script>
import Animate from 'utils/Animate';

const $body = document.body;
export default {
    name: 'Pulley',
    data() {
        return {
            isMounted: false,
            animate: new Animate(this.onAniStep, this.onAniCompleted),
            finger: {
                startY: 0,
                lastY: 0,
                startTime: 0,
                lastTime: 0,
                moveY: 0,
                transformY: 0,
                isDown: false,
            },
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        data: {
            type: Array,
            default: () => [],
        },
        prop: { type: Object, default: () => ({ id: 'id', name: 'name' }) },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    computed: {
        prop_() {
            return {
                id: 'id',
                name: 'name',
                ...this.prop,
            };
        },
        options() {
            const { data, prop_: prop } = this;
            return data.reduce((pre, curr) => {
                pre.push({
                    id: typeof curr === 'string' || typeof curr === 'number' ? curr : curr[prop.id],
                    name: typeof curr === 'string' || typeof curr === 'number' ? curr : curr[prop.name],
                    original: curr,
                });
                return pre;
            }, []);
        },
        optionsMap() {
            return this.options.reduce((pre, curr, index) => {
                const p = pre;
                p[curr.id] = {
                    ...curr,
                    __index: index,
                };
                return p;
            }, {});
        },
        itemHeight() {
            const { $refs, isMounted } = this;
            if (!isMounted) return 0;
            const { indicator } = $refs;
            const computedStyle = window.getComputedStyle(indicator);
            return parseFloat(`${computedStyle.height}`);
        },
        maxTransFormY() {
            return (1 - this.options.length) * this.itemHeight;
        },
        itemStyle() {
            const { itemHeight } = this;
            if (!itemHeight) return {};
            return {
                height: `${itemHeight}px`,
            };
        },
        contentStyle() {
            const transform = `translate3d(0, ${this.finger.transformY}px, 0)`;
            return {
                transform,
                webkitTransform: transform,
            };
        },
        isDisabled() {
            const { readonly, disabled, data } = this;
            return readonly || disabled || !data || !data.length;
        },
        isOnValue() {
            return `${this.options.length}${this.value}`;
        },
    },
    watch: {
        isOnValue: {
            handler() {
                this.onValue();
            },
            immediate: true,
        },
    },
    methods: {
        onValue(val = this.value) {
            const { optionsMap, finger, itemHeight, isMounted } = this;
            if (!isMounted || !itemHeight) {
                this.$nextTick(() => this.onValue(val));
                return;
            }
            let value = 0;
            if (!val) {
                value = 0;
            } else {
                const { __index } = optionsMap[val] || { __index: 0 };
                value = __index || 0;
            }
            finger.transformY = itemHeight * -value;
        },
        getToucheData($event) {
            const { touches, timeStamp } = $event;
            const touche = touches ? touches[0] : $event;
            return {
                timeStamp: timeStamp || new Date().getTime(),
                pageY: touche.pageY,
            };
        },
        limitRange(val) {
            if (val > 0) return 0;
            const { maxTransFormY } = this;
            return val < maxTransFormY ? maxTransFormY : val;
        },
        limitItem(val) {
            const { itemHeight } = this;
            // 符合 item 的剩余偏移量
            const remainder = val % itemHeight;
            return val - (remainder < itemHeight / -2 ? itemHeight + remainder : remainder);
        },
        onTouchStart($event) {
            $event.stopPropagation();
            $event.preventDefault();
            const { isDisabled, finger, getToucheData, animate, maxTransFormY } = this;
            if (isDisabled) return;
            if (finger.transformY < 0 && finger.transformY > maxTransFormY) $event.preventDefault();
            animate.stop();
            const { pageY, timeStamp } = getToucheData($event);
            // -----------------------------------------------
            finger.startTime = timeStamp;
            finger.startY = pageY;
            finger.moveY = pageY;
            finger.isDown = true;
            if ($event.type === 'mousedown') {
                $body.addEventListener('mousemove', this.onTouchMove, false);
                $body.addEventListener('mouseup', this.onTouchEnd, false);
            } else {
                $body.addEventListener('touchmove', this.onTouchMove, false);
                $body.addEventListener('touchend', this.onTouchEnd, false);
            }
        },
        onTouchMove($event) {
            // $event.stopPropagation();
            // $event.preventDefault();
            const { isDisabled, finger, getToucheData, limitRange /* , maxTransFormY */ } = this;
            if (isDisabled || !finger.isDown) return;
            const { pageY, timeStamp } = getToucheData($event);
            // -----------------------------------------------
            finger.lastTime = timeStamp;
            finger.lastY = pageY;
            finger.transformY = limitRange(finger.transformY + pageY - finger.moveY);
            finger.moveY = finger.lastY;
            // if (finger.transformY < 0 && finger.transformY > maxTransFormY) $event.preventDefault();
        },
        onTouchEnd($event) {
            const { isDisabled, finger, limitRange, limitItem, animate } = this;
            finger.isDown = false;
            $body.removeEventListener('mousemove', this.onTouchMove);
            $body.removeEventListener('mouseup', this.onTouchEnd);
            $body.removeEventListener('touchmove', this.onTouchMove);
            $body.removeEventListener('touchend', this.onTouchEnd);
            $event.stopPropagation();
            $event.preventDefault();
            if (isDisabled) return;
            // -----------------------------------------------
            const offsetY = finger.lastY - finger.startY;
            const offsetTime = finger.lastTime - finger.startTime;
            let duration = 500;
            let { transformY } = finger;
            if (offsetTime <= 200) {
                const acceleration = Math.abs(offsetY / offsetTime);
                transformY = finger.transformY + offsetY * acceleration;
                duration = (300 + offsetTime) * acceleration;
            }
            animate.start(finger.transformY, limitRange(limitItem(transformY)), duration);
        },
        getItem(id) {
            return this.optionsMap[id].original;
        },

        // 动画回调
        onAniStep(val) {
            this.finger.transformY = val;
        },
        onAniCompleted(val) {
            const { options, itemHeight } = this;
            const index = +Math.abs(val / itemHeight).toFixed(0);
            const value = options[index].id;
            if (value !== this.value) this.$emit('input', value);
        },
    },

    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
//$itemHeight: 30px;
$itemHeight: $formItemHeight;
.pulley {
    flex: 1 1 1%;
    position: relative;
    min-height: 400px;
    overflow: hidden;
    > .console {
        position: relative;
        z-index: 1000;
    }
    > .mask {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6)),
            linear-gradient(to top, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.6));
        background-repeat: no-repeat;
        background-position: top, bottom;
        background-size: 100% calc(50% - #{$itemHeight/2});
    }
    > .indicator {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        height: $itemHeight;
        border: solid $color-border;
        border-width: 1px 0;
        background-color: $gray1;
    }
    > .content {
        position: absolute;
        z-index: 100;
        top: calc(50% - #{$itemHeight/2});
        left: 0;
        right: 0;
        > .item {
            text-align: center;
            line-height: $itemHeight;
            //@include text-line(1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>
