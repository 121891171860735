<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        class="linker"
        v-bind="linkerProps">
        <slot />
    </div>
</template>

<script>
import linkerMixin from '../mixins/linkerMixin';

export default {
    name: 'Linker',
    mixins: [linkerMixin],
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
    },
};
</script>

<style lang="scss">
.linker {
    display: inline-block;
}
</style>
