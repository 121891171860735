/**
 * Created by henian.xu on 2021/4/27.
 *
 */

import { get } from 'api/request';

export function enterpriseStatementList({ currentPage, pageSize }) {
    return get(this.url, {
        currentPage,
        pageSize,
    });
}
