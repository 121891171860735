<!-- Created by henian.xu on 2020/1/27. -->

<template>
    <div 
        class="float-box"
        ref="self"
        :style="styles"
        @touchstart="onTouchStart"
        @touchmove.stop.prevent="onTouchMove"
        @touchend="onTouchEnd"
    >
        <slot></slot>
    </div>
</template>

<script>
import { Device } from 'utils';

const defaultFinger = JSON.stringify({
    offset: {
        x: 0,
        y: 0,
        timeStamp: 0,
    },
    move: {
        x: 0,
        y: 0,
    },
});
export default {
    name: 'FloatBox',
    data() {
        return {
            isMounted: false,
            finger: JSON.parse(defaultFinger),
            selfRect: {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                timeStamp: 0,
            },
        };
    },
    computed: {
        $$self() {
            if (!this.isMounted) return null;
            return this.$refs.self;
        },
        styles() {
            const { move } = this.finger;
            if (!move.timeStamp) return null;
            return {
                left: `${move.x}px`,
                top: `${move.y}px`,
                right: 'auto',
                bottom: 'auto',
            };
        },
    },
    methods: {
        refreshSelfRect() {
            const { $$self } = this;
            const timeStamp = new Date().getTime();
            if (!$$self) return;
            this.selfRect = {
                x: $$self.offsetLeft,
                y: $$self.offsetTop,
                width: $$self.clientWidth,
                height: $$self.clientHeight,
                timeStamp,
            };
        },
        getToucheData($event) {
            const { touches, timeStamp } = $event;
            const touche = touches ? touches[0] : $event;
            return {
                timeStamp: timeStamp || new Date().getTime(),
                pageX: touche.pageX,
                pageY: touche.pageY,
            };
        },
        onTouchStart($event) {
            this.refreshSelfRect();
            const { getToucheData, finger, selfRect } = this;
            const { pageX, pageY, timeStamp } = getToucheData($event);
            finger.offset = {
                x: pageX - selfRect.x,
                y: pageY - selfRect.y,
                timeStamp,
            };
        },
        onTouchMove($event) {
            const { getToucheData, finger, selfRect } = this;
            const { pageX, pageY, timeStamp } = getToucheData($event);
            if (!finger.offset.timeStamp) return;
            const x = pageX - finger.offset.x;
            const y = pageY - finger.offset.y;
            const { width, height } = Device;
            const maxLeft = width - selfRect.width;
            const maxTop = height - selfRect.height;
            // console.log(x, y, maxLeft, maxTop);
            finger.move = {
                x: Math.max(0, Math.min(x, maxLeft)),
                y: Math.max(0, Math.min(y, maxTop)),
                timeStamp,
            };
        },
        onTouchEnd() {
            const { move } = this.finger;
            this.finger = {
                ...JSON.parse(defaultFinger),
                move,
            };
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.float-box {
    position: fixed;
    z-index: $z-index-6;
    right: $padding;
    bottom: $padding + $navHeight;
}
</style>
