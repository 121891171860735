<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <div class="order-goods-item">
        <div class="header">
            <div class="label">{{data.goodsName}}</div>
        </div>
        <div class="inner">
            <XImage
                :src="data.specImageUrl"
                width="30%"
                :aspect-ratio="1"
            />
            <div class="content">
                <div class="school">
                    <XIcon
                        content="f09a"
                        theme="main"
                    />
                    <span>{{data.schoolOrgName}}</span>
                </div>
                <div class="sub-label">{{data.certCatNamePath}}</div>
                <div class="ctrl">
                    <div class="sku">{{specDesc}}</div>
                    <div class="price">{{data.finalPrice}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderGoodsItem',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        specDesc() {
            return (this.data.specDesc || '').split(':').pop();
        },
    },
};
</script>

<style lang="scss">
.order-goods-item {
    background-color: $color-component-bgc;
    overflow: hidden;
    padding: 0 $padding;
    > .header {
        margin: $margin 0;
        > .label {
            font-weight: bold;
        }
    }
    > .inner {
        margin: $margin 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        > .x-image {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
        > .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;

            > .sub-label {
                font-size: $little * 100%;
                color: $color-text-minor;
            }

            > .ctrl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .sku {
                    min-width: 5em;
                    padding: $padding-small $padding;
                    background-color: $color-main;
                    border-radius: 100vw;
                    color: #fff;
                    line-height: 1;
                    text-align: center;
                    word-break: break-all;
                    white-space: pre-line;
                }
                > .price {
                    flex: 0 0 auto;
                }
            }
        }
    }
}
</style>
