<!-- Created by henian.xu on 2020/4/8. -->

<template>
    <div :class="classes">
        <div
            :class="['out-show',{noop:!model}]"
            @click="onSwitch"
        >
            <div class="inner">{{modelPathName||placeholder}}</div>
            <div
                v-if="modelPathName && !disabled_ && !readonly_"
                class="clear"
                @click.stop="onClear">
                <XIcon
                    content="f01a"
                    theme="g6"
                    size="big" />
            </div>
            <XIcon
                content="f012"
                size="small"
                theme="g6"
            />
        </div>

        <!--popup-->
        <transition name="show">
            <div
                ref="popup"
                class="popup down region-picker-popup"
                v-show="popupShow"
                @click.self="close"
            >
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="header">
                            <XButton
                                label="取消"
                                theme="second"
                                link
                                @click="close"
                            />
                            <XLabel
                                :label="label||'选择区域'"
                                :sub-label="subLabel||pickerModelPathName"
                            />
                            <XButton
                                label="确定"
                                theme="success"
                                link
                                @click="onConfirm"
                            />
                        </div>
                        <div class="body">
                            <!--<div>pickerModel:{{pickerModel}}</div>
                            <div>{{model_}}</div>
                            <div>{{modelPathName}}</div>-->
                            <Picker
                                delete-remaining
                                :data="pickerData"
                                v-model="pickerModel"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { labelMixin, popupMixin } from 'packages/mixins';
import { isArray, debounce } from 'utils/index';
import { state, getChild, loadAllArea } from './store';

export default {
    name: 'RegionPicker',
    mixins: [popupMixin, labelMixin],
    data() {
        return {
            pickerData: [],
            pickerModel: null,
            model_: null,
            modelChange_: debounce(this.modelChange),
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        pId: {
            type: [String, Number],
            default: '',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        modelPathName() {
            const { model_ } = this;
            if (!model_ || !model_.length) return '';
            const { itemIdMap } = state;
            return model_.map(id => itemIdMap[id].name).join('>');
        },
        pickerModelPathName() {
            const { pickerModel } = this;
            if (!pickerModel || !pickerModel.length) return '';
            const { itemIdMap } = state;
            return pickerModel.map(id => (itemIdMap[id] || {}).name || '').join('>');
        },
        classes() {
            const { disabled_, readonly_ } = this;
            return ['region-picker', { disabled: disabled_ || readonly_ }];
        },
    },
    watch: {
        model: {
            handler() {
                this.modelChange_();
            },
            immediate: true,
        },
        pickerModel: {
            async handler(val) {
                if (isArray(val)) {
                    const value = [this.pId, ...val];
                    this.pickerData = await value.reduce((pre, cur, index) => {
                        return pre.then(res =>
                            getChild({ pId: cur }).then(data => {
                                if (data && data.length) res.splice(index, 1, data);
                                return res;
                            }),
                        );
                    }, Promise.resolve([]));
                }
            },
            immediate: true,
        },
    },
    methods: {
        async modelChange() {
            const { model } = this;
            if (!model) {
                this.pickerModel = [];
            } else {
                // noop
                this.pickerModel = await loadAllArea({
                    pId: this.pId,
                    id: model,
                });
                this.model_ = [...this.pickerModel];
            }
        },
        onConfirm() {
            const { pickerModel } = this;
            this.model_ = pickerModel;
            this.model = [...pickerModel].pop() || '';
            this.close();
        },
        onClear() {
            this.model_ = null;
            this.model = '';
        },
    },
};
</script>

<style lang="scss">
.region-picker {
    flex: 1;
    > .out-show {
        line-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .inner {
            flex: 1;
        }

        > .clear {
            margin: 0 $margin-small;
        }
        &.noop {
            color: $gray6;
        }
    }
}
.popup.region-picker-popup {
    > .inner {
        > .body {
            padding: 0;
            > .picker {
                > .pulley {
                    flex: 0 0 (100%/3);
                    width: (100%/3);
                }
            }
        }
    }
}
</style>
