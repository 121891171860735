/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { post } from 'api/request';

export function sendVerificationCode({ countryCodeId, account, jCaptcha }) {
    return post(this.url, {
        countryCodeId,
        sendMobile: account,
        jCaptcha,
    });
}
