import Vue from 'vue';
import Vuex from 'vuex';

import { gettersPlugin } from 'store/plugins';
import PortalInfo from './PortalInfo';
import User from './User';
import ServantCat from './ServantCat';
import Dictionary from './Dictionary';
import DeliveryAddress from './DeliveryAddress';
import meiQia from './meiQia';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [gettersPlugin],
    modules: {
        // Dictionary,
        PortalInfo,
        User,
        ServantCat,
        Dictionary,
        DeliveryAddress,
        meiQia,
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {},
});
