/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get, post } from 'api/request';

export function list({ keywords, currentPage, pageSize }) {
    return get(this.url, { keywords, currentPage, pageSize });
}
export function detail(id) {
    return get(this.url, { id });
}
export function Delete(ids) {
    return post(this.url, { ids });
}
export function save({ id, isDefault, consignee, mobile, regionId, address }) {
    return post(this.url, {
        id,
        isDefault,
        consignee,
        phone: mobile,
        regionId,
        address,
    });
}
export function setDefault(id) {
    return post(this.url, { id });
}
