<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        :class="classes"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <XImage
            :aspect-ratio="1"
            :src="data.mainImageUrl"
            fit="cover"
            cut="little"
        />
        <div class="content">
            <div class="header">
                <div class="goods-name">{{data.goodsName}}</div>
            </div>
            <div class="footer">
                <div class="price">{{data.price}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'GoodsItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        type: {
            type: [Number, String],
            default: 1,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        classes() {
            const type = +this.type;
            return [
                'goods-item',
                { 'img-top': type === 1 },
                { 'img-right': type === 2 },
                { 'img-bottom': type === 3 },
                { 'img-left': type === 4 },
                // add
            ];
        },
        imageProp() {
            const type = +this.type;
            const res = {};
            if (type === 2 || type === 4) {
                res.width = '100%';
            }
            return res;
        },
    },
};
</script>

<style lang="scss">
.goods-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    background-color: $color-component-bgc;
    height: 100%;
    @include boxShadow();

    > .content {
        flex: 1;
        padding: $padding;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        > .header {
            > .goods-name {
                @include text-line(2);
            }
        }
        > .footer {
            text-align: right;
        }
    }

    &.img-right {
        flex-direction: row;
        padding: $padding;
        > .x-image {
            width: 30%;
        }
        > .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            padding: 0;
            margin-left: $margin;
            > .footer {
                text-align: left;
            }
        }
    }
}
</style>
