/**
 * Created by henian.xu on 2019/3/8.
 *
 */

export default {
    computed: {
        rawBackUrl() {
            return this.$route.query.backUrl || '';
        },
        backUrl() {
            return decodeURIComponent(this.rawBackUrl);
        },
    },
    beforeRouteEnter(to, from, next) {
        const { backUrl } = to.query;
        if (!backUrl) {
            next({
                ...to,
                replace: true,
                query: {
                    ...to.query,
                    backUrl: encodeURIComponent(from.fullPath),
                },
            });
            return;
        }
        next();
    },
};
