/**
 * Created by henian.xu on 2020/4/27.
 *
 */
import {
    GlobalVar,
    hasOwn,
    isObject,
    Valid,
    isArray,
    isDef,
    isString,
    // add
} from 'utils';

export const typeComponentMap = {
    hidden: 'input',
    text: 'XInput',
    textarea: 'XInput',
    number: 'XInput',
    password: 'XInput',
    file: 'Upload',
    date: 'DatePicker',
    datetime: 'DatePicker',
    time: 'DatePicker',
    checkbox: 'Checkbox',
    radio: 'Checkbox',
    select: 'XSelect',
    'select-multiple': 'XSelect',

    region: 'RegionPicker',
    studyHistory: 'EditStudyHistory',
    workHistory: 'EditWorkHistory',
    signature: 'EditSignature',
};
export function getComponentName(type) {
    return typeComponentMap[type];
}

export function buildProps(field) {
    const { properties, type } = field;
    if (!properties) return {};
    const res = properties.uiProps || {};
    if (/hidden|text|textarea|number|password|date|datetime|time|checkbox|radio/.test(type)) {
        res.type = type;
    }
    return [
        // 通用
        'placeholder',
        'attachments',
        'tips',
        'required',
        'readonly',
        'disabled',
        // textarea
        'minRows',
        'maxRows',
        // checkbox|radio|select|select-multiple
        { data: 'options' },
        'extraOptions',
        'enableOther',
        { props: 'optionsProps' },
        // checkbox|select-multiple
        { bitwise: 'bitwiseValue' },
        // date|datetime|time
        { format: 'dateFormat' },
        // file
        'action',
        'accept',
        'demoImage',
        'multiple',
        'maxFiles',
        'checkType',
        // workHistory|studyHistory
        'maxCount',
        // studyHistory
        'degreeDictionary',
    ].reduce((pre, cur) => {
        let key = cur;
        let targetKey = cur;
        if (isObject(cur)) [[key, targetKey]] = Object.entries(cur);
        const val = properties[targetKey];
        // 修正值
        /* if (/\[DICTIONARY\]/.test(val)) {
            const [, dic] = val.split(':');
            console.log(dic);
        } */
        if (hasOwn(properties, targetKey)) pre[key] = val;
        return pre;
    }, res);
}

export function buildValue(field, valueData = {}) {
    const { properties, type, name } = field;
    let value = valueData[name];

    if (!isDef(value)) {
        value = properties.value || properties.defaultValue;
    }
    if (/file|studyHistory|workHistory/.test(type)) {
        if (type === 'file' && isString(value)) {
            const splitStr = GlobalVar.fileLibraryPath;
            value = value.split(',').reduce((pre, cur) => {
                if (cur) {
                    pre.push({
                        address: cur,
                        name: cur.replace(splitStr, ''),
                    });
                }
                return pre;
            }, []);
        }
        if (!isArray(value) && !value) value = [];
    }
    return isDef(value) ? value : '';
}

export function buildValidate(field) {
    const rules = [];
    const { label, properties, type: fieldType } = field;
    const { required, validates } = properties;
    if (fieldType === 'hidden') return null;
    // if (required) rules.push({ required: true, message: `${label}不能为空` });
    if (required) {
        rules.push({
            validator: Valid.strictRequired,
            key: required === true ? 'id' : required,
            message: `${label}不能为空`,
        });
    }
    if (validates) {
        validates.forEach(item => {
            const { type, method, message } = item;
            const validFn = Valid[type];
            if (validFn) {
                rules.push({
                    validator: validFn,
                    regExpStr: method,
                    message,
                });
            } else if (type === 'RegExp' && method) {
                rules.push({
                    regexp: method,
                    message,
                });
            }
        });
    }
    return rules.length ? rules : null;
}
