/**
 * Created by henian.xu on 2019/10/28.
 *
 */

import { throttle } from 'utils/index';
import statusMap from './status';

export default {
    data() {
        return {
            statusMap,
            observer: null,
            status: statusMap.READY, // 0:就绪;1:加载中;2:无数据;3:已完成;
            count: 0, // 触发加载的数据 相当于页数
            onScrollHandler: throttle(this.observerHandler),
            isActivated: false, // 只用于监听滚动时 activated 钩子是否触发 onScrollHandler 方法
        };
    },
    props: {
        // 是否自动加载剩余分页
        // 可传入 semi:String 为手动触发加载
        auto: {
            type: [Boolean, String],
            default: true,
        },
        distance: {
            type: Number,
            default: 100,
        },
        noShowComplete: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        scrollParent() {
            return this.getRootElement();
        },
        isSemi() {
            return this.auto === 'semi';
        },
    },
    watch: {
        disabled(val) {
            if (val) {
                this.destroyObserver();
            } else {
                this.createObserver();
            }
        },
    },
    methods: {
        getRootElement(el = this.$el) {
            let res;
            if (el && el.tagName === 'BODY') {
                // 保留
            } else if (['scroll', 'auto'].includes(getComputedStyle(el).overflowY)) {
                res = el;
            } else if (el.hasAttribute('infinite-wrapper') || el.hasAttribute('data-infinite-wrapper')) {
                // TODO infinite-wrapper、data-infinite-wrapper 换个好名称
                res = el;
            }
            return res || this.getRootElement(el.parentNode);
        },
        /**
         * 获取离可视区距离
         * @returns {Number} distance
         */
        getCurrentDistance() {
            if (!document.documentElement.contains(this.$el)) {
                // 当组件元素不在 documentElement 上时
                // 返回值应大于 this.distance 以确保 this.observerHandler 方法不触发 load 事件。
                return this.distance + 100;
            }
            const infiniteElmOffsetTopFromBottom = this.$el.getBoundingClientRect().top;
            const scrollElmOffsetTopFromBottom =
                this.scrollParent === window ? window.innerHeight : this.scrollParent.getBoundingClientRect().bottom;

            return infiniteElmOffsetTopFromBottom - scrollElmOffsetTopFromBottom;
        },
        observerHandler(entries) {
            if (this.status || this.disabled) return;
            if (entries && entries.constructor === Event) {
                const currentDistance = this.getCurrentDistance();
                if (currentDistance > this.distance) return;
                // console.log(currentDistance, entries);
            } else {
                const { isIntersecting } = entries[0] || {};
                if (!isIntersecting) return;
                // console.log(entries);
            }
            this.status = statusMap.LOADING;
            this.load().then((status = statusMap.READY) => {
                this.status = Object.values(statusMap).includes(status) ? status : statusMap.READY;
                this.model += 1;
                if (!this.isSemi) {
                    this.$nextTick(() => {
                        this.onScrollHandler(new Event('scroll'));
                    });
                }
            });
        },
        async load() {
            return new Promise(resolve => {
                this.$emit('load', { next: resolve, statusMap });
            });
        },
        createObserver() {
            if (!this.auto || this.auto === 'semi' || this.disabled) return;
            this.model = 0;
            const { scrollParent } = this;
            if (window.IntersectionObserver) {
                if (this.observer === null) {
                    this.observer = new IntersectionObserver(this.observerHandler, {
                        root: scrollParent,
                        rootMargin: `${this.distance}px`,
                        threshold: [0],
                    });
                }
                this.observer.observe(this.$el);
            } else {
                // 触发第一次加载
                this.onScrollHandler(new Event('scroll'));
                scrollParent.addEventListener('scroll', this.onScrollHandler);
            }
        },
        destroyObserver() {
            if (this.observer) {
                this.observer.unobserve(this.$el);
                this.observer.disconnect();
                this.observer = null;
            }
            this.status = statusMap.READY;
            this.model = 0;
            this.scrollParent.removeEventListener('scroll', this.onScrollHandler);
        },
        onSemi() {
            this.onScrollHandler(new Event('scroll'));
        },
    },
    mounted() {
        this.createObserver();
    },
    activated() {
        if (this.isActivated) {
            // 为了解决监听滚动时重新激活页面触发 onScrollHandler 方法
            this.onScrollHandler(new Event('scroll'));
        }
        if (!window.IntersectionObserver) this.isActivated = true;
    },
    beforeDestroy() {
        this.destroyObserver();
    },
};
