<!-- Created by henian.xu on 2019/3/1. -->

<template>
    <div :class="['tab',{average}]">
        <TabNav :panels="panels" />
        <slot />
    </div>
</template>

<script>
import TabNav from './TabNav';

export default {
    name: 'Tab',
    components: { TabNav },
    provide() {
        return { Tab: this };
    },
    data() {
        return {
            panels: [],
            currentIndex_: 0, // 当未绑定 model 时才有用
        };
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        button: {
            type: Boolean,
            default: false,
        },
        average: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentIndex: {
            get() {
                const { value, currentIndex_ } = this;
                return value === null ? currentIndex_ : value;
            },
            set(val) {
                if (this.value === null) {
                    this.currentIndex_ = val;
                } else {
                    this.$emit('input', val);
                }
                this.$emit('change', this.panels[val], val);
            },
        },
    },
    methods: {
        calcPaneInstances(isForceUpdate = false) {
            if (this.$slots.default) {
                const paneSlots = this.$slots.default.filter(
                    vnode =>
                        vnode.tag && vnode.componentOptions && vnode.componentOptions.Ctor.options.name === 'TabPanel',
                );
                // update indeed
                const panes = paneSlots.map(({ componentInstance }) => componentInstance);
                const panesChanged = !(
                    panes.length === this.panels.length && panes.every((pane, index) => pane === this.panels[index])
                );
                if (isForceUpdate || panesChanged) {
                    this.panels = panes;
                }
            } else if (this.panels.length !== 0) {
                this.panels = [];
            }
        },
    },
    mounted() {
        this.calcPaneInstances();
    },

    updated() {
        this.calcPaneInstances();
    },
};
</script>

<style lang="scss">
.tab {
    &.average {
        flex: 1;
    }
}
</style>
