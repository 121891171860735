<!-- Created by henian.xu on 2019/2/28. -->

<!--<template>
    <div :class="classes">
        <slot name="label">
            <div class="label">
                <slot>{{ label }}</slot>
            </div>
        </slot>
        <slot name="subLabel">
            <div
                v-if="subLabel"
                class="sub-label"
            >
                {{ subLabel }}
            </div>
        </slot>
    </div>
</template>-->

<script>
import themeMixin from '../mixins/themeMixin';

export default {
    name: 'XLabel',
    mixins: [themeMixin],
    data() {
        return {};
    },
    props: {
        label: {
            type: [String, Number],
            default: '',
        },
        subLabel: {
            type: [String, Number],
            default: '',
        },
        scopedSlots: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        classes() {
            const { theme, size } = this;
            return ['x-label', theme, size];
        },
    },
    render() {
        const { classes, label, scopedSlots, $scopedSlots } = this;
        const slot = {
            ...scopedSlots,
            ...$scopedSlots,
        };
        const subLabel_ = () => {
            const { subLabel } = this;
            if (slot.subLabel) return slot.subLabel();
            if (`${subLabel}`) {
                return <div class="sub-label">{subLabel}</div>;
            }
            return '';
        };
        const label_ = () => {
            if (slot.label) return slot.label();
            const res = slot.default ? slot.default() : label;
            return `${res}` ? <div class="label">{res}</div> : '';
        };
        return (
            <div class={classes}>
                {subLabel_()}
                {label_()}
            </div>
        );
    },
};
</script>

<style lang="scss">
.x-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    > .label {
        order: 0;
    }
    > .sub-label {
        order: 1;
        font-size: $little * 100%;
        // color: $gray6;
        &,
        ~ .label {
            //line-height: 1.2;
        }
    }

    /* 主题颜色 */
    @each $key, $value in $colorList {
        &.#{$key} {
            > .label {
                color: $value;
            }
            > .sub-label {
                color: mix($value, #fff, 50%);
            }
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key} {
            > .label {
                font-size: $value * 100%;
            }
            > .sub-label {
                font-size: $value * 100% * $little;
            }
        }
    }
}
</style>
