/**
 * Created by henian.xu on 2019/3/8.
 *
 */

import Vue from 'vue';
import Moment from 'dayjs';

import { filterNumber, isString, GlobalVar, isObject, isArray } from 'utils';

/* eslint-disable no-param-reassign */
Vue.filter('moment', (value, format = 'YYYY-MM-DD') => {
    // YYYY-MM-DD HH:mm:ss
    if (!value) return '';
    if (+value) {
        value = +value;
    } else {
        return value;
    }
    /* let res = '';
    const now = Moment().startOf('day');
    const moment = Moment(value);
    const diff = now.diff(moment.clone().startOf('day'), 'days');
    if (diff > 0 && diff < 3) {
        // format = format.replace('YYYY-MM-DD', '');
        format = format.replace(/YYYY(.*)MM(.*)DD/, '').trim();
        res = format ? moment.format(format) : '';
        if (diff === 1) {
            res = `昨天${res}`;
        } else if (diff === 2) {
            res = `前天${res}`;
        }
    } else {
        res = moment.format(format);
    } */
    return Moment(value).format(format);
});

Vue.filter('substringStr', value => {
    const maxLength = 20;
    if (value.length > maxLength) {
        return `${value.substring(0, maxLength)}...`;
    }
    return value;
});

Vue.filter('number', (value, length = 2, strict = true) => filterNumber(value, length, strict));

Vue.filter('price', (value, length = 0, strict = false) => {
    const amountFormat = GlobalVar.moduleConfig ? `${GlobalVar.moduleConfig.amountFormat}` : '';
    const signStrict = amountFormat.charAt(0) !== '+';
    const arr = amountFormat.split('.');
    const len = arr.length < 2 ? 0 : arr[1].length;
    return filterNumber(value, length || len, strict || signStrict);
});

Vue.filter('htmlDecode', value => {
    if (value === '' || value === undefined || value === null) return '';
    const node = document.createElement('div');
    node.innerHTML = value;
    return node.innerText || '';
});

Vue.filter('money', (value = '') => {
    value += '';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
});

/**
 * imgCropping
 * mini: 100
 * small: 200
 * little: 375
 * normal: 750
 * big: 900
 * more: 1200
 * super: 1920
 */
Vue.filter('imgCropping', (value, params) => {
    value += '';
    const { moduleConfig: { imgCropping } = {} } = GlobalVar;
    if (!value || !imgCropping || params === 'none') return value;
    const imgCroppingList = isArray(imgCropping) ? imgCropping : [imgCropping];
    const available = imgCroppingList.some(val => isString(val) && value.indexOf(val) === 0);
    // console.log(available, params);
    if (!available) return value;
    if (params && isString(params)) return `${value}_${params}`;
    if (params && isObject(params)) {
        // doc: https://help.aliyun.com/document_detail/44688.html?spm=a2c4g.11186623.6.1372.11313168wqiX8s
        const keyMap = {
            mode: 'm', // lfit、mfit、fill、pad、fixed，默认为 lfit。
            width: 'w',
            height: 'h',
            long: 'l',
            short: 's',
            limit: 'limit',
            color: 'color',
        };
        const query = Object.entries(params)
            .reduce(
                (pre, [key, val]) => {
                    const k = keyMap[key];
                    if (k) pre.push(`${k}_${val}`);
                    return pre;
                },
                ['image/resize'],
            )
            .join(',');
        const fn = '?x-oss-process=';
        return `${value}${fn}${query}`;
    }
    return `${value}_normal`;
});
