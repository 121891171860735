/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get } from 'api/request';

export function getChild({ pId, lv, maxLv, queryData }, config = {}) {
    return get(
        this.url,
        { pId, lv, maxLv, queryData },
        {
            isRepeat: true,
            ...config,
        },
    );
}
export function loadAllArea({ pId, id, queryData }, config) {
    return get(
        this.url,
        { pId, id, queryData },
        {
            isRepeat: true,
            ...config,
        },
    );
}
