/**
 * Created by henian.xu on 2019/10/28.
 *
 */

export default {
    READY: 0, // 就绪
    LOADING: 1, // 加载中
    NOTHING: 2, // 无数据
    COMPLETE: 3, // 已完成
};
