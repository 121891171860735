/**
 * Created by henian.xu on 2019/2/28.
 * commonMixin.js
 */

export default {
    // props: {},
    computed: {
        // 有些参数(prop)是在其它混合器或组件中
        pointer() {
            const { to, $listeners, link } = this;
            return link || !!to || !!$listeners.click;
        },
    },
};
