<!-- Created by henian.xu on 2020/10/22. -->

<template>
    <div
        :class="classes"
        @click="onSwitch"
    >
        <div :class="['out-show',{noop:!model || !model.length}]">
            <div class="inner">
                <div class="label">{{model || placeholder}}</div>
                <div
                    v-if="model && !disabled_ && !readonly_"
                    class="clear"
                    @click.stop="onClear">
                    <XIcon
                        content="f01a"
                        theme="g6"
                        size="big" />
                </div>
                <XIcon
                    content="f012"
                    theme="g6"
                    size="small" />
            </div>
        </div>

        <!--popup-->
        <transition :name="aniName">
            <Page
                ref="popup"
                class="popup"
                v-show="popupShow"
            >
                <PageBar class="pa-l pa-r">
                    <XInput
                        placeholder="请输入关键字"
                        v-model="keywords"
                        @input="onQueryInput"></XInput>
                </PageBar>
                <Container>
                    <CellGroup border>
                        <template v-if="listData&&listData.length">
                            <Cell
                                v-for="item in listData"
                                :key="item.id"
                                :label="item.enterpriseName"
                                @click="onItem(item.enterpriseName)"
                            ></Cell>
                        </template>
                        <Cell
                            v-else
                            :label="keywords"
                            extra="使用输入值"
                            @click="onItem(keywords)"
                        ></Cell>
                    </CellGroup>

                    <Pagination
                        ref="infinite"
                        :disabled="paginationDisabled"
                        @load="onload"
                    />
                </Container>
                <!--<PageBar position="bottom">
                    <XButton
                        class="item"
                        label="清空"
                        theme="second"
                        @click="()=>{onClear()}"
                    ></XButton>
                </PageBar>-->
            </Page>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';
import { paginationMixin } from 'mixins/index';
import { debounce } from 'utils/Common';

export default {
    name: 'CompanyPicker',
    mixins: [popupMixin, paginationMixin],
    data() {
        return {
            keywords: '',
        };
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        classes() {
            const { disabled_, readonly_ } = this;
            return ['company-picker', { disabled: disabled_ || readonly_ }];
        },
    },
    methods: {
        onQueryInput: debounce(function handler(val) {
            // this.keywords = val;
            console.log(val);
            this.refreshList();
        }),
        async getListData(params) {
            const { keywords } = this;
            const res = await this.$api.Member.Ce.CertExamApply.companySelect({
                keywords,
                ...params,
            });
            this.listData.push(...res.data);
            return res;
        },
        onItem(val) {
            this.model = val;
            this.close();
        },
        onClear() {
            this.model = '';
        },
    },
};
</script>

<style lang="scss">
.company-picker {
    flex: 1;
    > .out-show {
        > .inner {
            line-height: $formItemHeight;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .label {
                flex: 1;
            }
            > .clear {
                margin: 0 $margin-small;
            }
        }
        &.noop {
            > .inner {
                > .label {
                    color: $gray6;
                }
            }
        }
    }
}
</style>
