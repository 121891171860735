/**
 * Created by henian.xu on 2020/1/14.
 *
 */
import { assert } from 'utils/index';
import { getKeyByRouter, addNavComponent, removeNavComponent } from './runtime';

export default {
    name: 'navigation',
    abstract: true, // 这是一个抽象组件
    data() {
        return {
            cache: {},
        };
    },
    render() {
        const {
            $scopedSlots: { default: $default },
        } = this;
        if (!$default) return null;
        const VNodeList = $default();
        if (!VNodeList || !VNodeList.length) return null;
        assert(VNodeList.length === 1, 'navigation 组件应该只有单个子节点', 'navigation');
        const vnode = VNodeList[0];
        // 防止vue-router重用组件
        if (!vnode.key) {
            vnode.key = vnode.isComment ? 'comment' : vnode.tag;
        }
        const key = getKeyByRouter();
        if (vnode.key.indexOf(key) === -1) {
            vnode.key = `__NAVIGATION_${key}_${vnode.key}`;
        }
        if (this.cache[key]) {
            const cacheVnode = this.cache[key];
            if (vnode.key === cacheVnode.key) {
                // 还原 vnode
                vnode.componentInstance = cacheVnode.componentInstance;
            } else {
                // 替换缓存
                cacheVnode.componentInstance.$destroy();
                this.cache[key] = vnode;
            }
        } else {
            // 缓存新的 vnode
            this.cache[key] = vnode;
        }
        vnode.data.keepAlive = true;
        return vnode;
    },
    created() {
        addNavComponent(this);
    },
    destroyed() {
        removeNavComponent(this);
    },
};
