/**
 * Created by henian.xu on 2020/4/10.
 *
 */

import { get } from 'api/request';

export function courseList({ certCatId, currentPage, pageSize }) {
    return get(this.url, {
        certCatId,
        currentPage,
        pageSize,
    });
}
export function courseDetail({ id }) {
    return get(this.url, { id });
}
