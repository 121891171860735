<!-- Created by henian.xu on 2018/9/13. -->

<template>
    <List
        class="goods"
        :column="+type===1?2:1"
        :padding="+type===1?'all':false"
        :border="+type===2"
    >
        <GoodsItem
            v-for="(item) in goodsList"
            :key="item.id"
            :data="item"
            :type="+type"
            :to="`/serve/detail/${item.id}`"
        />
    </List>
</template>

<script>
export default {
    name: 'Goods',
    data() {
        return {
            goodsList: [],
        };
    },
    props: {
        // 单列:1;双列:2;
        type: {
            type: [Number, String],
            default: 1,
        },
        ids: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        ids: {
            async handler(val) {
                if (!val || !val.length) {
                    return;
                }
                const res = await this.$api.Member.Gd.Goods.goodsList({
                    ids: val.join(','),
                });
                this.goodsList = res.data;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
</style>
