<!-- Created by henian.xu on 2019/3/5. -->

<template>
    <div :class="classes">
        <textarea
            v-if="isTextarea"
            ref="textarea"
            :style="textareaStyle"
            v-on="listeners"
            v-bind="__attrProps"
            :disabled="disabled_"
            v-model="model"
        />
        <input
            v-else
            ref="input"
            :type="type"
            v-on="listeners"
            v-bind="__attrProps"
            :disabled="disabled_"
            v-model="model" />
        <XIcon
            v-if="isClear && model && !disabled_ && !readonly_"
            class="clear"
            theme="g5"
            content="f01a"
            @click="onClear" />
    </div>
</template>

<script>
import calcTextareaHeight from './calcTextareaHeight';
import formMixin from '../mixins/formMixin';

export default {
    name: 'XInput',
    mixins: [formMixin],
    data() {
        return {
            isMounted: false,
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        type: {
            validator(value) {
                return /(text|number|password|textarea|url|email|search|date)/.test(value);
            },
            default: 'text',
        },
        minRows: {
            type: Number,
            default: 1,
        },
        maxRows: {
            type: Number,
            default: null,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        isTextarea() {
            return this.type === 'textarea';
        },
        classes() {
            const { isTextarea, readonly, disabled_: disabled } = this;
            return [isTextarea ? 'x-textarea' : 'x-input', { readonly }, { disabled }];
        },
        textareaStyle() {
            const { $refs, minRows, maxRows, value, isMounted } = this;
            const { textarea } = $refs;
            return !textarea || !isMounted ? {} : calcTextareaHeight(textarea, minRows, maxRows, value);
        },
        listeners() {
            const obj = {
                ...this.$listeners,
            };
            delete obj.input;
            return obj;
        },
    },
    methods: {
        onClear() {
            this.model = '';
        },
        focus() {
            this.$nextTick(() => {
                if (this.isTextarea) {
                    this.$refs.textarea.focus();
                } else {
                    this.$refs.input.focus();
                }
            });
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.x-textarea,
.x-input {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //border: 1px solid $color-border;
    > input,
    > textarea {
        flex: 1 1 1%;
        /*width: 1%;*/ // textarea 时这个会导致初始化为隐藏时第一次计算高度不正确
        line-height: $formItemHeight;
        //padding: 0 $padding-small;
        outline: none;
        resize: none;
        background-color: transparent;
        appearance: none;
        text-align: inherit;
    }
    > input {
        width: 1%;
    }

    > textarea {
        line-height: $line-height;
    }
    > .clear {
        height: $formItemHeight;
        width: $formItemHeight;
        opacity: 0.5;
    }
}
.x-input {
    > .clear {
        flex: 0 0 auto;
    }
}
.x-textarea {
    position: relative;
    padding: (($formItemHeight - $body-font-size * $line-height) / 2) 0;
    > .clear {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>
