<!-- Created by henian.xu on 2019/12/5. -->

<template>
    <div :class="classes">
        <div class="inner">
            <XIcon
                icon
                content="f002"
                :theme="iconTheme"
            />
            <template v-if="hasFold">
                <div
                    class="label"
                    @click="onFold">
                    {{ label }}
                </div>
            </template>
            <template v-else>
                <form
                    class="input"
                    action=""
                    @submit.stop.prevent="()=>{}"
                >
                    <input
                        ref="input"
                        type="search"
                        autocomplete="off"
                        placeholder="搜索"
                        :readonly="readonly"
                        @blur="onInputBlur"
                        @keydown.enter="onEnter"
                        v-model="model"
                    />
                </form>
            </template>
        </div>
        <XButton
            v-if="searchShow && !hasFold"
            class="search-btn"
            label="搜索"
            theme="main"
            @click="onEnter"
        ></XButton>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SearchBox',
    data() {
        return {
            isFold: true,
            value_: '',
        };
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        radius: {
            type: [Boolean, String],
            default: '',
        },
        label: {
            type: String,
            default: '搜索',
        },
        border: {
            type: Boolean,
            default: false,
        },
        fold: {
            type: Boolean,
            default: false,
        },
        iconTheme: {
            type: String,
            default: 'g6',
        },
        padding: {
            type: Boolean,
            default: false,
        },
        searchShow: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                const { value, value_ } = this;
                if (value === null) return value_;
                return value;
            },
            set(val) {
                const { value } = this;
                if (value === null) {
                    this.value_ = val;
                }
                this.$emit('input', val);
            },
        },
        classes() {
            const { radius, border, padding, searchShow, hasFold } = this;
            return [
                'search-box',
                { border },
                radius,
                { radius },
                { padding },
                { fold: hasFold },
                { 'search-btn': searchShow && !hasFold },
                // add
            ];
        },
        hasFold() {
            return this.isFold && this.fold;
        },
    },
    methods: {
        setFold(value) {
            this.isFold = value;
            this.$emit('foldChange', value);
        },
        onFold() {
            if (this.readonly) return;
            this.setFold(!this.isFold);
            if (!this.isFold) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        },
        onInputBlur() {
            if (!this.fold) return;
            this.setFold(true);
        },
        onEnter() {
            this.$emit('query', this.model);
        },
    },
    async mounted() {
        const { autofocus } = this;
        if (!autofocus) return;
        await this.$nextTick();
        this.$refs.input.focus();
    },
};
</script>

<style lang="scss">
.search-box {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    > .inner {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: $padding-small $padding;
        background-color: #fff;

        > .x-icon {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
        > .input {
            flex: 1 1 1%;
            > input {
                //background-color: #f00;
                padding: 0 $padding-small;
                width: 100%;
                outline: none;
                color: $color-text;
                min-height: $formItemHeight;
                &::-webkit-search-cancel-button {
                    display: none;
                }
            }
        }
        > .label {
            line-height: $formItemHeight;
        }
    }
    > .search-btn {
        flex: 0 0 auto;
        //margin-left: $margin;
    }

    &.fold {
        flex: 0 0 auto;
        > .inner {
            display: inline-flex;
            justify-content: center;
        }
    }
    &.border {
        > .inner {
            border: 1px solid $color-border;
        }
    }
    &.radius {
        border-radius: $padding-small;
        > .inner,
        > .input > input {
            border-radius: $padding-small;
        }
        > .search-btn {
            border-top-right-radius: $padding-small;
            border-bottom-right-radius: $padding-small;
        }

        &.all {
            border-radius: 100vw;
            > .inner,
            > .input > input {
                border-radius: 100vw;
            }
            > .search-btn {
                border-top-right-radius: 100vw;
                border-bottom-right-radius: 100vw;
            }
        }
        &.search-btn {
            > .inner,
            > .input > input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    &.padding {
        padding: $padding;
        > .inner {
            padding: 0 $padding;
        }
    }
}
</style>
