<!-- Created by henian.xu on 2019/3/7. -->

<script>
import { Dom } from 'utils';
import formMixin from './formMixin';

export default {
    name: 'Popup',
    mixins: [formMixin],
    inject: {
        Page: { default: () => null },
    },
    data() {
        return {
            inPage: false,
            lazy: false,
            aniName: '',
            queryName: `popup${this.id}`,
            popupShow_: [], // 仅当不用路由时(useRouter:false)用
            setPopupShow__: false,
            doneResolve: null,
            isMountedPopup: false,
        };
    },
    props: {
        type: {
            type: [String, Boolean],
            default: true,
        },
        placeholder: {
            type: String,
            default: '请选择...',
        },
        useRouter: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        $$popupWrap() {
            const { inPage, Page, $root } = this;
            const target = inPage ? Page || $root : $root;
            return Dom.getPopupWrap(target.$el);
        },
        $$popups: {
            get() {
                let popups = this.$refs.popup;
                if (!popups) return [];
                if (!Array.isArray(popups)) {
                    popups = [popups];
                }
                return popups.map(node => node.$el || node);
            },
            cache: false,
        },
        popupShow: {
            get() {
                const { useRouter, popupShow_ } = this;
                if (useRouter) {
                    return this.$route.query[this.queryName];
                }
                return [...popupShow_].pop();
            },
            set(val) {
                const { useRouter } = this;
                if (!val) {
                    if (useRouter) {
                        this.$router.go(-1);
                    } else {
                        this.popupShow_.pop();
                    }
                    return;
                }
                this.setPopupShow__ = true;
                this.aniName = 'left';
                if (useRouter) {
                    this.$router.push({
                        ...this.$route,
                        query: {
                            ...this.$route.query,
                            [this.queryName]: val,
                        },
                    });
                } else {
                    this.popupShow_.push(val);
                }
            },
        },
    },
    watch: {
        $route() {
            if (!this.setPopupShow__) {
                this.aniName = 'right';
            }
            this.setPopupShow__ = false;
        },
        popupShow(val) {
            this.$emit(val ? 'open' : 'close', val);
            if (!this.doneResolve) return;
            this.doneResolve(val);
            this.doneResolve = null;
        },
    },
    methods: {
        mount() {
            const { $$popupWrap, $$popups, lazy } = this;
            if (lazy || !$$popups.length) return;
            $$popups.forEach(popup => {
                $$popupWrap.appendChild(popup);
            });
            this.isMountedPopup = true;
        },
        unmount() {
            const { isMountedPopup, $$popupWrap, $$popups } = this;
            if (!isMountedPopup || !$$popups.length) return;
            $$popups.forEach(popup => {
                $$popupWrap.removeChild(popup);
            });
            this.isMountedPopup = false;
        },
        setPopupShow(type) {
            if (typeof type === 'number') {
                this.$router.go(type);
            } else {
                this.popupShow = type;
            }
        },
        gotoType(type) {
            const { disabled_, readonly, isMountedPopup } = this;
            if (disabled_ || readonly) return Promise.resolve();
            const promise = new Promise(resolve => {
                this.doneResolve = resolve;
            });
            if ((!isMountedPopup && +type > 0) || Number.isNaN(+type)) {
                this.lazy = false;
                this.$nextTick(() => {
                    this.mount();
                    this.setPopupShow(type);
                });
            } else {
                this.setPopupShow(type);
            }
            return promise;
        },
        onSwitch() {
            return this.gotoType(!this.popupShow);
        },
        close() {
            if (!this.popupShow) return Promise.resolve();
            return this.gotoType(false);
        },
        open(type = this.type) {
            return this.gotoType(type);
        },
    },
    mounted() {
        this.mount();
    },
    beforeDestroy() {
        this.unmount();
    },
};
</script>

<style lang="scss">
.popup-wrap {
    // .popup-wrap 只留了做弹框挂载用

    > .page,
    > .popup {
        position: absolute;
        z-index: $z-index-7;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .popup {
        &:not(.page) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(35, 24, 21, 0.8);
            overflow: hidden;
        }

        > .inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: #fff;
            max-height: 90%;
            > .header {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                min-height: $navHeight;
                border-bottom: 1px solid $color-border;

                > .x-label {
                    flex: 1 1 1%;
                    text-align: center;
                }
            }
            > .body {
                flex: 1 1 auto;
                padding: $padding;
                overflow-x: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }
            > .footer {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                min-height: $navHeight;
                > *,
                > .x-button {
                    flex: 1 1 1%;
                }
            }
        }

        &.up {
            justify-content: flex-start;
            align-items: stretch;
        }
        &.down {
            justify-content: flex-end;
            align-items: stretch;
        }
    }
}
</style>
