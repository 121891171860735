/**
 * Created by henian.xu on 2020/1/5.
 *
 */

import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters('User', ['userInfo']),
        ...mapGetters('Dictionary', {
            shipMethodDic: 'shipMethod',
            courseTypeDic: 'courseType',
            courseTypeDicMap: 'courseTypeMap',
            cmsArticleTypeDic: 'cmsArticleType',
            questionTypeDic: 'questionType',
        }),
    },
    methods: {
        ...mapActions('User', {
            userInfoUpdated: 'updated',
        }),
    },
};
