/**
 * Created by henian.xu on 2019/3/7.
 *
 */

// eslint-disable-next-line import/prefer-default-export
export function getPopupWrap(target) {
    const { childNodes } = target;
    let popup;
    const lng = childNodes.length - 1;
    for (let i = lng; i >= 0; i -= 1) {
        const node = childNodes[i];
        if (node.nodeType === 1 && /\bpopup-wrap\b/.test(node.className)) {
            popup = node;
            break;
        }
    }
    if (!popup) {
        popup = document.createElement('div');
        popup.classList.add('popup-wrap');
        target.appendChild(popup);
    }
    return popup;
}
