/**
 * Created by henian.xu on 2020/4/2.
 *
 */

import { Device } from 'utils';
import { toDataURL } from 'utils/QRCode';

const weixinObj = {
    icon: 'f080',
    iconTheme: 'success',
    label: '微信支付',
};
const alipayObj = {
    icon: 'f085',
    iconTheme: 'primary',
    label: '支付宝支付',
};
export default {
    data() {
        return {
            orderIdsChanged: false,
            payMaterial: {},
        };
    },
    props: {
        orderIds: {
            type: [String, Number],
            required: true,
        },
        isLazy: {
            type: Boolean,
            default: false,
        },
        // 1:订单;2:充值卡;3:分销高;
        module: {
            type: Number,
            required: true,
        },
    },
    computed: {
        // 支付方式 1248类型
        paymentType() {
            return this.payMaterial.paymentType;
        },
        orderType() {
            return this.payMaterial.orderType;
        },
        // 是否线上支付
        isOnline() {
            return this.payMaterial.isOnline;
        },
        // 是否线下支付
        isOffline() {
            return this.payMaterial.isOffline;
        },
        // 支付金额
        paymentAmount() {
            return this.payMaterial.paymentAmount;
        },
        // 手续费
        paymentPoundageMap() {
            return this.payMaterial.paymentPoundageMap;
        },
        // 支付方式
        payMethods() {
            const { payMaterial, isOnline, /* isOffline, */ paymentType, paymentPoundageMap } = this;
            const res = [];
            let i = 0;
            let type = 2 ** i;
            while (isOnline && type <= paymentType) {
                /* eslint-disable no-bitwise */
                const exportStr = paymentPoundageMap[type] ? paymentPoundageMap[type].exportStr : '';
                switch (type) {
                    // 微信支付
                    case 2 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'weixinPay',
                            exportStr,
                        });
                        break;
                    }
                    // 通联支付
                    case 8 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'allinPay',
                            exportStr,
                        });
                        break;
                    }
                    // alphaPay
                    case 16 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'alphaPayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'alphaPayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // alipay
                    case 32 & paymentType: {
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'alipay',
                            exportStr,
                        });
                        break;
                    }
                    // 中投科信 umb
                    case 128 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'umbPayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'umbPayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // hantePay
                    case 256 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'hantePayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'hantePayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // citconPay
                    case 512 & paymentType: {
                        res.push({
                            ...weixinObj,
                            paymentType: type,
                            action: 'citconPayWeixin',
                            exportStr,
                        });
                        res.push({
                            ...alipayObj,
                            paymentType: type,
                            action: 'citconPayAlipay',
                            exportStr,
                        });
                        break;
                    }
                    // stripe
                    case 64 & paymentType: {
                        res.push({
                            label: '信用卡快捷支付',
                            paymentType: type,
                            iconTheme: 'second',
                            // icon: visaIcon,
                            action: 'stripePay',
                            exportStr,
                        });
                        break;
                    }
                    // 零钱支付
                    case 1024 & paymentType: {
                        res.push({
                            icon: 'f03c',
                            iconTheme: payMaterial.isPurseBalance ? 'primary' : '',
                            label: `零钱(剩余: ${payMaterial.purseBalance})${
                                payMaterial.isPurseBalance ? '' : '余额不足'
                            }`,
                            extra: payMaterial.isPurseBalance ? '' : '点击充值',
                            action: 'pocketMoneyPay',
                            exportStr,
                        });
                        break;
                    }
                    default: {
                        break;
                    }
                }
                i += 1;
                type = 2 ** i;
            }

            return res;
        },
    },
    watch: {
        orderIds: {
            handler(val, oldVal) {
                if (!val || +val === +oldVal) return;
                if (this.isLazy) {
                    this.orderIdsChanged = true;
                } else {
                    this.toPay();
                }
            },
        },
    },
    methods: {
        async toPay() {
            const { module, orderIds } = this;
            const res = await this.$api.Member.Ex.Pay.toPay({ module, orderIds });
            this.orderIdsChanged = false;
            this.payMaterial = res.data;
            return res;
        },
        // 所有支付方法的入口
        async action(action, params) {
            console.log(action, params);
            const payment = await this[action](params);
            await this.close();
            this.gotoResult(payment);
        },
        async gotoResult(payment) {
            console.log('gotoResult', payment);
            if (!payment) return null;
            const { module, paymentSn } = payment;
            return this.$router.replace(`/pay/result/${module}/${paymentSn}`);
        },
        // ---- 各支付方法 ----//
        // 创建扫码支付
        async payByScanCode(apiMethod, tipsName = '微信') {
            const { module, orderIds } = this;
            const { data } = await this.$api.Member.Ex.Pay[apiMethod]({ module, orderIds });
            const { QRCodeUrl, paymentAmount, payment, paymentId } = data;
            const imgUrl = await toDataURL(QRCodeUrl);
            await this.$messageBox.confirm(
                `<div class="ta-c">
                    <div>请用${tipsName}扫一扫支付订单<br>支付成功后可点击下方【已支付】<br>按钮完成本次支付</div>
                </div>
                <img src="${imgUrl}" width="100%"/>
                <div class="ta-c">订单金额：<span class="price">${paymentAmount}</span></div>
                `,
                `${tipsName}支付`,
                {
                    isClickOtherClose: false,
                    confirmBtnText: '已支付',
                    validCallback: this.validPayByScanCode({ ...payment, paymentId }),
                },
            );
            return payment;
        },
        // 校验支付
        validPayByScanCode(payment) {
            return async action => {
                if (action !== 'confirm') return true;
                const { module, paymentId } = payment;
                const { success, msg } = await this.$api.Member.Ex.Pay.isPay({
                    module,
                    paymentId,
                });
                if (success) return true;
                return Promise.reject(new Error(`<div class="tc-danger">${msg}</div>`));
            };
        },
        // 微信支付
        async chooseWXPay(apiMethod) {
            const { module, orderIds } = this;
            const { data } = await this.$api.Member.Ex.Pay[apiMethod]({ module, orderIds });
            const { payInfo, payment } = data;
            try {
                await this.$wxSDK.chooseWXPay(payInfo);
            } catch (e) {
                console.log(e);
                if (e) {
                    this.$messageBox.alert(e.message || JSON.stringify(e));
                }
                return null;
            }
            return payment;
        },
        // 原生微信支付
        async weixinPay() {
            if (Device.weixin) {
                return this.chooseWXPay('payforWeixin');
            }
            return this.payByScanCode('payforWeixinByScanCode');
        },
        // 零钱支付
        async pocketMoneyPay() {
            const { payMaterial, module, orderIds } = this;
            // 余额不足去充值
            if (!payMaterial.isPurseBalance) {
                this.$router.replace(`/pocketMoney/prepaid`);
                return Promise.reject(new Error('余额不足'));
            }
            const { data } = await this.$api.Member.Ex.Pay.payforBalance({
                module,
                orderIds,
            });
            return data.payment;
        },
    },
    created() {
        this.lazy = this.isLazy;
    },
};
