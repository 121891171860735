<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <div class="topic">
        <div class="header">
            <Badge>{{questionTypeName}}</Badge><!--{{data.__index}} - {{data.id}}-->
            <span class="label">
                <template v-if="index">{{index}}.</template>
                {{data.title}}
            </span>
        </div>
        <div
            class="ma-a"
            v-if="attachmentList && attachmentList.length"
        >
            <ImageList
                :data="attachmentList"
                auto-width/>
        </div>
        <div class="body">
            <div
                v-for="item in optionList"
                :key="item.id"
                class="item"
            >
                <ExamCheckbox
                    :type="formType"
                    :value="item.option"
                    :disabled="isAnalysis"
                    :is-analysis="isAnalysis"
                    :icon-text="item.text"
                    v-model="model"
                    :answer-model="answerModel"
                >
                    {{item.content}}
                    <div
                        v-if="item.image && item.image.length"
                        class="imgs">
                        <XImage
                            v-for="image in item.image"
                            :key="image.name"
                            block
                            :src="image.address"></XImage>
                    </div>
                </ExamCheckbox>
            </div>
        </div>
        <div
            v-if="isAnalysis"
            class="footer"
        >
            <div class="result">
                <div
                    v-if="!hideAnswerResult"
                    class="user-answer"
                >
                    答题结果:
                    <XIcon
                        :content="isCorrect?'f017':'f018'"
                        :theme="isCorrect?'success':'danger'"
                        size="big"
                    ></XIcon>
                </div>
                <div class="standard-answer">正确答案: {{data.questionAnswerStr}}</div>
            </div>
            <div class="analysis">
                <XLabel>答案解析:</XLabel>
                <div class="inner">{{data.answerAnalysis||'无'}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isArray } from 'utils/index';

export default {
    name: 'Topic',
    data() {
        return {};
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        index: {
            type: [String, Number],
            default: '1',
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        isAnalysis: {
            type: Boolean,
            default: false,
        },
        hideAnswerResult: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('Dictionary', {
            questionTypeDicMap: 'questionTypeMap',
        }),
        model: {
            get() {
                const { formType, value, optionList } = this;
                if (formType !== 'checkbox') return value;
                return optionList.reduce((pre, cur) => {
                    const { option } = cur;

                    if (option & value) pre.push(option);
                    return pre;
                }, []);
            },
            set(val) {
                const { formType } = this;
                let value = val;
                if (formType === 'checkbox' && isArray(val)) {
                    value = val.reduce((pre, cur) => pre | cur, 0);
                }
                this.$emit('input', value);
            },
        },
        answerModel() {
            const {
                formType,
                optionList,
                isAnalysis,
                data: { questionAnswer },
            } = this;
            if (!isAnalysis || !questionAnswer) return null;
            if (formType !== 'checkbox') return questionAnswer;
            return optionList.reduce((pre, cur) => {
                const { option } = cur;

                if (option & questionAnswer) pre.push(option);
                return pre;
            }, []);
        },
        questionType() {
            return this.data.questionType;
        },
        attachmentList() {
            return this.data.attachmentList;
            // return ['https://oss.minduedu.com/upload/fileLibrary/2022/02/11/p8eEJzEnyD.jpg_normal'];
        },
        questionTypeName() {
            const { questionType, questionTypeDicMap } = this;
            return (questionTypeDicMap[questionType] || {}).name || '';
        },
        formType() {
            const { questionType } = this;
            if (questionType === 1) return 'radio';
            if (questionType === 2) return 'radio';
            if (questionType === 3) return 'checkbox';
            if (questionType === 4) return 'radio';
            return '';
        },
        optionList() {
            return this.data.optionList || [];
        },
        isCorrect() {
            const { data, value } = this;
            return data.selectOption === data.questionAnswer || value === data.questionAnswer;
            // data.selectOption === data.questionAnswer
        },
    },
};
</script>

<style lang="scss">
.topic {
    font-size: 36px;
    line-height: 1.5;
    background-color: $color-component-bgc;
    overflow: hidden;
    > .header,
    > .body,
    > .footer {
        margin: $padding;
    }

    > .header {
        > .label {
            font-weight: bold;
            margin-top: $margin;
            font-size: 110%;
        }
    }
    > .body {
        > .item {
            //margin: $margin 0;
            + .item {
                margin-top: -1px;
                border-top: 1px solid $color-border;
            }
        }
    }
    > .footer {
        > .result {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        > .analysis {
            margin-top: $margin;
        }
    }
}
</style>
