/**
 * Created by henian.xu on 2019/8/2.
 * PortalInfo
 */

import { Api } from 'api';

let querying = false;
export default {
    namespaced: true,
    state: {
        rawData: null,
    },
    getters: {
        rawData(state) {
            const { rawData } = state;
            if (rawData === null) this.dispatch('updated');
            return rawData || {};
        },
        customerImage(state, getters) {
            const { rawData } = getters;
            const { customerImage = [] } = rawData;
            const [customer = {}] = customerImage;
            return customer.image || '';
        },
        instructionsImage(state, getters) {
            const { rawData } = getters;
            const { instructionsImage = [] } = rawData;
            const [customer = {}] = instructionsImage;
            return customer.image || '';
        },
        subsidyInstructionsImage(state, getters) {
            const { rawData } = getters;
            const { subsidyInstructionsImage = [] } = rawData;
            const [customer = {}] = subsidyInstructionsImage;
            return customer.image || '';
        },
        signatureInstructions(state, getters) {
            const { rawData } = getters;
            const { signatureInstructions = [] } = rawData;
            const [customer = {}] = signatureInstructions;
            return customer.image || '';
        },
        deferredReviewInstructions(state, getters) {
            const { rawData } = getters;
            const { deferredReviewInstructions = [] } = rawData;
            const [customer = {}] = deferredReviewInstructions;
            return customer.image || '';
        },
        batchSignatureInstructions(state, getters) {
            const { rawData } = getters;
            const { batchSignatureInstructions = [] } = rawData;
            const [customer = {}] = batchSignatureInstructions;
            return customer.image || '';
        },
        depositCardOfICBC(state, getters) {
            const { rawData } = getters;
            const { depositCardOfICBC = [] } = rawData;
            const [customer = {}] = depositCardOfICBC;
            return customer.image || '';
        },
    },
    mutations: {
        // 设置原始数据
        _setRawData(state, data) {
            state.rawData = data;
        },
    },
    actions: {
        // 更新原始数据
        async updated({ commit }) {
            commit('_setRawData', {});
            if (querying) return Promise.resolve();
            querying = true;
            const res = await Api.Comm.Pt.PortalInfoConfig.getPortalInfoConfig().finally(() => {
                querying = false;
            });
            commit('_setRawData', res.data || {});
            return res;
        },
    },
};
