/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get } from 'api/request';

export function sign(url) {
    return get(
        this.url,
        {
            url,
        },
        {
            isHandleError: true,
        },
    );
}
