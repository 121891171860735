/**
 * Created by henian.xu on 2019/10/25.
 *
 */
export default {
    props: {
        theme: {
            type: String,
            default: null,
        },
        size: {
            type: [String, Number],
            default: null,
        },
    },
};
