<!-- Created by henian.xu on 2020/4/9. -->

<template>
    <div class="catalog-picker">
        <Tab v-model="model">
            <TabPanel
                v-for="item in catList"
                :key="item.id"
                :label="item.name"
            />
        </Tab>
    </div>
</template>

<script>
export default {
    name: 'CatalogPicker',
    data() {
        return {};
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        model: {
            get() {
                const { value, catListMap } = this;
                const cat = catListMap[value];
                if (!cat) return 0;
                return cat.__index;
            },
            set(val) {
                const { catList } = this;
                const cat = catList[val];
                if (!cat) return;
                this.$emit('input', cat.id);
            },
        },
        dataListMap() {
            return this.data.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        catList() {
            const data = JSON.parse(JSON.stringify(this.data));
            return this.$utils.Convert.listToTree(data);
        },
        catListMap() {
            return this.catList.reduce((pre, cur, __index) => {
                pre[cur.id] = {
                    ...cur,
                    __index,
                };
                return pre;
            }, {});
        },
    },
    watch: {
        // 初始默认选中
        catList: {
            handler(val) {
                if (!val || !val.length) return;
                const { value, catListMap } = this;
                if (catListMap[value]) return;
                this.model = 0;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.catalog-picker {
    flex: 1;
    min-height: $navHeight;
}
</style>
