/**
 * Created by henian.xu on 2019/10/22.
 * 所有插件入口
 */
/* eslint-disable no-param-reassign */
import './filter';
import Meta from 'vue-meta';
import Moment from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DefaultImg from 'assets/images/default.jpg';
import LogoImg from 'assets/images/logo.png';
import DefaultAvatar from 'assets/images/defaultAvatar.png';
import loadingIcon from 'assets/images/loading.gif';
import router from 'router';
import store from 'store';
import * as Utils from 'utils';
import ApiInstall from 'api';
import vueAwesomeSwiper from 'vue-awesome-swiper';
import VueLazyload from 'vue-lazyload';
import Nprogress from './hookNprogress';
import JweixinInstall from './jweixin';
import navigation from './navigation';
import installSwiper from './installSwiper';

Moment.extend(duration);
Moment.extend(customParseFormat);

export default Vue => {
    window.$bus = new Vue();
    Vue.prototype.$bus = window.$bus;
    Vue.prototype.$moment = Moment;
    Vue.prototype.$defaultImg = DefaultImg;
    Vue.prototype.$defaultAvatar = DefaultAvatar;
    Vue.prototype.$logoImg = LogoImg;
    Vue.prototype.$loadingIcon = loadingIcon;
    Vue.prototype.$utils = Utils;
    Vue.prototype.$nprogress = Nprogress;
    Vue.prototype.$globalVar = Utils.GlobalVar;

    Vue.use(Meta, {
        keyName: 'head', // the component option name that vue-meta looks for meta info on.
        // attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
        // ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
        // tagIDKeyName: 'vmid', // the property name that vue-meta uses to determine whether to overwrite or append a tag
    });
    Vue.use(navigation, { router, store });
    Vue.use(ApiInstall);
    Vue.use(JweixinInstall);
    Vue.use(installSwiper);
    Vue.use(vueAwesomeSwiper);
    Vue.use(VueLazyload, {
        preLoad: 1.3,
        error: DefaultImg,
        loading: loadingIcon,
        attempt: 1,
    });
};
