<!-- Created by henian.xu on 2019/3/7. -->

<template>
    <div
        v-if="tiling"
        :class="['x-select',{tiling},tiling]"
    >
        <div class="inner">
            <div
                :class="['item', { active: isSelected(item.id) }]"
                :style="tilingItemStyles"
                v-for="(item, index) in options"
                :key="item.id || index"
                @click="onItem(item)"
            >
                <div class="inner">
                    {{ item.name }}
                </div>
                <!--<sapn
                    class="extra"
                    slot="extra"
                    v-show="isSelected(item.id)"
                ><i class="f-icon tc-green">&#xf017;</i></sapn>-->
            </div>
        </div>
    </div>
    <div
        v-else
        class="x-select"
    >
        <div
            :class="['out-show',{noop:!outShow}]"
            @click="onSwitch"
        >
            <div class="label">{{ outShow || placeholder }}</div>
            <XIcon
                v-if="!disabled_"
                content="f012"
                theme="g5"
                size="small" />
        </div>
        <template v-if="isOtherSelected">
            <XInput
                class="bor-a pa-ls pa-rs"
                :placeholder="`请输入${outShow}`"
                v-model="otherModel"
            ></XInput>
        </template>

        <!-- popup -->
        <transition
            v-if="!lazy"
            name="show"
        >
            <div
                ref="popup"
                v-show="popupShow"
                class="popup popup-select down"
                @click.self="close"
            >
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div
                            v-if="label || subLabel"
                            class="header"
                        >
                            <XLabel
                                :label="label"
                                :sub-label="subLabel"
                            />
                        </div>
                        <div class="body">
                            <CellGroup border>
                                <Cell
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.name"
                                    :sub-label="item.subName"
                                    @click="onItem(item)"
                                >
                                    <template v-slot:extra>
                                        <div class="extra">
                                            <XIcon
                                                v-if="isSelected(item.id)"
                                                content="f017"
                                                theme="success"
                                            />
                                        </div>
                                    </template>
                                </Cell>
                            </CellGroup>
                        </div>
                        <!--<div class="footer">
                            <XButton label="label" />
                            <XButton label="label" />
                        </div>-->
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from '../mixins';

/* eslint-disable no-bitwise */

export default {
    name: 'XSelect',
    mixins: [popupMixin],
    data() {
        return {
            lazy: true,
            dictionaryData: null,
            otherModel_: '',
            setOtherModel_: this.$utils.debounce(this.setOtherModel, 500),
        };
    },
    props: {
        isFullValue: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Number, String, Array, Object],
            default: 0,
            required: true,
        },
        label: {
            type: [String, Number],
            default: '',
        },
        subLabel: {
            type: [String, Number],
            default: '',
        },
        data: {
            type: [Array, String],
            default: () => [],
        },
        // 为 true 时 isFullValue 必需为 true
        enableOther: {
            type: [Number, String],
            default: 0, // 999999
        },
        // enableOther:true 时忽略
        extraOptions: {
            type: Array,
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        bitwise: {
            type: Boolean,
            default: false,
        },
        tiling: {
            type: [Boolean, String],
            default: false,
        },
        tilingColumn: {
            type: Number,
            default: 0,
        },
        props: {
            type: Object,
            default: () => ({ id: 'id', name: 'name', subName: 'subName' }),
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                const { multiple, bitwise, value, isFullValue, props_: props } = this;
                if (!multiple) {
                    this.$emit('input', val);
                }
                // isFullValue:true 不存在位异或
                else if (!bitwise || isFullValue) {
                    const value_ = [...value];
                    let index = -1;
                    value_.some((item, i) => {
                        let b = false;
                        if (!isFullValue) {
                            b = `${item}` === `${val}`;
                        } else if (item && val) {
                            b = `${item[props.id]}` === `${val[props.id]}`;
                        }
                        if (b) index = i;
                        return b;
                    });
                    if (index === -1) {
                        value_.push(val);
                    } else {
                        value_.splice(index, 1);
                    }
                    this.$emit('input', value_);
                } else if (+value & +val) {
                    this.$emit('input', +value ^ +val);
                } else {
                    this.$emit('input', +value | +val);
                }
            },
        },
        modelValue() {
            const { model, isFullValue, props_: props } = this;
            let modelValue = model;
            if (isFullValue) {
                if (this.$utils.isArray(model)) {
                    modelValue = model.map(item => item[props.id]);
                } else if (model) {
                    modelValue = model[props.id];
                }
            }
            return modelValue;
        },
        otherModel: {
            get() {
                return this.otherModel_;
            },
            set(val) {
                this.otherModel_ = val;
                this.setOtherModel_();
            },
        },
        props_() {
            const { props } = this;
            return {
                id: 'id',
                name: 'name',
                subName: 'subName',
                ...props,
            };
        },
        dictionary() {
            const { data } = this;
            if (!this.$utils.isString(data)) return '';
            if (!/\[DICTIONARY\]/.test(data)) return '';
            const [, dic] = data.split(':');
            return dic;
        },
        data_() {
            const { data, dictionary, dictionaryData } = this;
            if (dictionary) return dictionaryData || [];
            return data;
        },
        options() {
            const { data_: data, extraOptions, props_: props } = this;
            const blank = [];
            if (this.$utils.isArray(extraOptions)) blank.push(...extraOptions);
            return blank.concat(data).reduce((pre, curr) => {
                pre.push({
                    id: curr[props.id],
                    name: curr[props.name],
                    subName: curr[props.subName],
                    original: curr,
                });
                return pre;
            }, []);
        },
        dataMap() {
            return this.options.reduce((p, c) => {
                p[c.id] = c;
                return p;
            }, {});
        },
        isOtherSelected() {
            const { enableOther, modelValue: model } = this;
            if (!enableOther) return false;
            if (this.$utils.isArray(model)) {
                return model.some(item => `${item}` === `${enableOther}`);
            }
            return `${model}` === `${enableOther}`;
        },
        outShow() {
            const { multiple, bitwise, modelValue: model, dataMap, data_: data, isFullValue } = this;
            if (!data.length || !model || (Array.isArray(model) && !model.length)) return '';
            if (!multiple) return (dataMap[model] || {}).name;
            // isFullValue:true 不存在位异或
            if (!bitwise || isFullValue) return model.map(item => dataMap[item].name).join(',');
            // TODO 这个算法太慢了应该优化
            return Object.values(dataMap)
                .reduce((p, c) => {
                    if (!(+model & c.id)) return p;
                    p.push(dataMap[c.id].name);
                    return p;
                }, [])
                .join(',');
        },
        tilingItemStyles() {
            const { tilingColumn } = this;
            if (!tilingColumn) return {};
            return {
                width: `${100 / tilingColumn}%`,
            };
        },
    },
    watch: {
        popupShow: {
            handler(val) {
                if (!val) return;
                this.getDictionaryData();
            },
        },
        model: {
            handler(val) {
                if (!val || (this.$utils.isArray(val) && !val.length)) return;
                this.getDictionaryData();
            },
            immediate: true,
        },
        tiling: {
            handler(val) {
                if (!val) return;
                this.getDictionaryData();
            },
            immediate: true,
        },
    },
    methods: {
        async getDictionaryData() {
            const { dictionary, dictionaryData } = this;
            if (!dictionary || dictionaryData) return;
            const { data } = await this.$api.Comm.Rs.Dic.dicEntry(dictionary);
            this.dictionaryData = data;
        },
        isSelected(id) {
            const { multiple, bitwise, modelValue: model, isFullValue } = this;
            if (!multiple) {
                return `${model}` === `${id}`;
            }
            // isFullValue:true 不存在位异或
            if (!bitwise || isFullValue) {
                return model.some(item => `${item}` === `${id}`);
            }
            return +model & id;
        },
        onItem(item) {
            const { disabled_, isFullValue, props_: props, enableOther, otherModel } = this;
            if (disabled_) return;
            if (isFullValue) {
                const res = {
                    [props.id]: item.id,
                    [props.name]: item.name,
                    [props.subName]: item.subName,
                };
                if (`${item.id}` === `${enableOther}`) {
                    res[props.name] = otherModel;
                }
                this.model = res;
            } else {
                this.model = item.id;
            }

            if (!this.multiple && !this.tiling) this.close();
        },
        setOtherModel() {
            const { isFullValue, props_: props, otherModel_, enableOther, dataMap } = this;
            let item = dataMap[enableOther];
            if (isFullValue) {
                if (!item) {
                    item = {
                        [props.id]: enableOther,
                        [props.name]: otherModel_,
                    };
                }
                this.onItem(item);
            }
        },
        onClose() {
            this.close();
        },
    },
    created() {
        const { multiple, bitwise, model } = this;
        if (!multiple) {
            if (Array.isArray(model)) throw new Error('<Selector> 单选时 model 应该为 String/Number');
        } else if (!bitwise) {
            if (!Array.isArray(model)) throw new Error('<Selector> 多选时 model 应该为数组类型');
        } else {
            if (Array.isArray(model)) throw new Error('<Selector> 按位多选时 model 应该为 String/Number');
            if (Number.isNaN(+model)) throw new Error('<Selector> 按位多选时 id 不能为非数字字符串');
        }
    },
};
</script>

<style lang="scss">
.x-select {
    flex: 1 1 1%;
    > .out-show {
        line-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1;
        }
        > .x-icon {
            flex: 0 0 auto;
        }

        &.noop {
            color: $gray6;
        }
    }

    &.tiling {
        overflow: hidden;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            /*overflow-x: auto;*/
            margin: -$padding-small;
            //margin-right: -$padding-small;

            > .item {
                padding: $padding-small;
                text-align: center;

                > .inner {
                    border: 1px solid $color-border;
                    border-radius: $padding-small;
                    padding: $padding-small;
                }

                &.active {
                    > .inner {
                        color: #fff;
                        border-color: $color-success;
                        background-color: $color-success;
                    }
                    /*&:after {
                        content: '\f017';
                        @include make-icon();
                    }*/
                }
            }
        }
    }
}
.popup.popup-select {
    > .inner {
        > .header {
            border-bottom: 1px solid $color-border;
        }
        > .body {
            padding: 0;
        }
    }
}
</style>
