<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="student-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="header">
            <XIcon
                content="f089"
                theme="main"
            />
            <XLabel :label="data.fullName"></XLabel>
            <div
                v-if="data.applyStatusName"
                class="extra">
                <XButton
                    theme="primary"
                    plain
                    radius
                    size="small"
                    lock-text
                >{{data.applyStatusName}}</XButton>
            </div>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt><div>性别</div></dt>
                    <dd>{{data.sexName}}</dd>
                </dl>
                <dl>
                    <dt><div>电话</div></dt>
                    <dd>{{data.tel}}</dd>
                </dl>
                <dl>
                    <dt><div>证件类型</div></dt>
                    <dd>{{data.idCardTypeName}}</dd>
                </dl>
                <dl>
                    <dt><div>证件号</div></dt>
                    <dd>{{data.idCardNo}}</dd>
                </dl>
                <dl>
                    <dt><div>报考</div></dt>
                    <dd>{{data.certCatNamePath}}</dd>
                </dl>
                <dl>
                    <dt><div>类型</div></dt>
                    <dd>{{data.certExamTypeName}}</dd>
                </dl>
                <template v-if="data.codeSn">
                    <dl class="fx-ai-c">
                        <dt><div>核销时间</div></dt>
                        <dd v-if="data.codeStatus ===100">
                            {{data.writeOffTime | moment('YYYY-MM-DD HH:mm:ss')}}
                        </dd>
                        <dd v-else>
                            {{data.codeStatusName}}
                            <XButton
                                v-if="data.codeStatus===10"
                                radius
                                theme="danger"
                                @click.stop.prevent="()=>{onCancelWriteOff()}"
                            >取消核销资格</XButton>
                        </dd>
                    </dl>
                    <dl>
                        <dt><div>学习码</div></dt>
                        <dd>{{data.codeSn}}</dd>
                    </dl>
                </template>
                <dl v-else-if="data.minExamApplyTime">
                    <dt><div>初次报名</div></dt>
                    <dd>{{data.minExamApplyTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                </dl>
                <dl v-if="data.userName">
                    <dt><div>账号</div></dt>
                    <dd>{{data.userName}}</dd>
                </dl>
                <dl v-if="data.testAvgScore">
                    <dt><div>模拟成绩</div></dt>
                    <dd>{{data.testAvgScore}}</dd>
                </dl>
                <dl v-if="data.learnHour">
                    <dt><div>学习时长</div></dt>
                    <dd>{{data.learnHour }}/{{data.courseHour }} {{data.completeRatio}}%</dd>
                </dl>
            </div>
            <!--<XIcon
                content="f012"
                size="small"
                theme="g5"
            />-->
        </div>
        <!--<div class="footer">
            <XButton
                label="已核销"
                link
            />
        </div>-->
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'StudentItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        async onCancelWriteOff() {
            await this.$messageBox.confirm('确定取消该学员核销资格？取消后学习码将作废无法使用', {
                cancelBtnText: '在考虑下',
            });
            const { msg } = await this.$api.Member.Mb.Enterprise.cancelCooperationMember({
                id: this.data.id,
            });
            if (msg) this.$messageBox.tips(msg);
            this.$emit('change', {
                ...this.data,
                codeStatus: 99,
                codeStatusName: '已作废',
            });
        },
    },
};
</script>

<style lang="scss">
.student-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            @include bodyDl(4em);
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
</style>
