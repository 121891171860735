/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get } from 'api/request';

export function checkDiscountCode({ uuid, discountCodeSn }) {
    return get(this.url, { uuid, discountCodeSn });
}
