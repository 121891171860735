/**
 * Created by henian.xu on 2019/10/22.
 *
 */

import { GlobalVar } from 'utils';
import Qs from 'qs';
import mergeConfig from 'axios/lib/core/mergeConfig';

export const defaultConfig = {
    url: '/',
    // 如果是开发环境设置代理接口前缀
    baseURL: '',
    method: 'GET',
    /* `transformRequest`允许在请求数据发送到服务器之前对其进行更改
    这只适用于请求方法'PUT','POST'和'PATCH'
    数组中的最后一个函数必须返回一个字符串,一个 ArrayBuffer或一个 Stream */
    transformRequest: [
        data =>
            Qs.stringify(data, {
                arrayFormat: 'repeat',
                serializeDate: d => d.getTime(),
            }),
    ],
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {},
    /* `paramsSerializer`是一个可选的函数,负责序列化`params`
    (e.g. https:/*www.npmjs.com/package/qs, http:/*api.jquery.com/jquery.param/) */
    paramsSerializer: params => {
        return Qs.stringify(params, {
            arrayFormat: 'repeat',
            serializeDate: d => `${d.getTime()}`,
        });
    },
    data: {},
    timeout: 30000,
    withCredentials: false,
    responseType: 'json',
    maxContentLength: 2000,
    validateStatus: status => {
        return status >= 200 && status < 300; // default
    },
    maxRedirects: 5,
};

const GlobalAxiosConfig = (GlobalVar.appConfig && GlobalVar.appConfig.AXIOS_DEFAULT_CONFIG) || {};

export const defaultExtendConfig = {
    isRepeat: !!GlobalAxiosConfig.isRepeat,
    isCancelBefore: GlobalAxiosConfig.isCancelBefore === undefined ? true : GlobalAxiosConfig.isCancelBefore,
    isHandleError: !!GlobalAxiosConfig.isHandleError,
    isJoinBaseUrl: GlobalAxiosConfig.isJoinBaseUrl === undefined ? true : GlobalAxiosConfig.isJoinBaseUrl,
    isSuccessTip: !!GlobalAxiosConfig.isSuccessTip,
    isAxiosResponseData:
        GlobalAxiosConfig.isAxiosResponseData === undefined ? true : GlobalAxiosConfig.isAxiosResponseData,
    isMock: !!GlobalAxiosConfig.isMock,
    isLoading: !!GlobalAxiosConfig.isLoading,
    methodsPath: '',
};

export default mergeConfig(defaultConfig, GlobalAxiosConfig);
