<!-- Created by henian.xu on 2018/5/10. -->

<template>
    <div class="upload">
        <!--<div class="item">
            <div class="inner" :style="`background-image: url('${$defaultImg}');`">
                <div class="del"><i class="f-icon">&#xf01a;</i></div>
                <div class="error"
                     @click="onRetry"
                >
                    &lt;!&ndash;<div>点击重试</div>&ndash;&gt;
                    <i class="f-icon tc-red fs-more">&#xf040;</i>
                </div>
                <div class="progress">
                    <div class="inner"></div>
                    <div class="label">50%</div>
                </div>
            </div>
        </div>-->

        <div
            class="item"
            :style="itemStyles"
            v-for="(item,index) in files"
            :key="index"
            @click="onItem(item,index)"
        >
            <div class="inner">
                <XImage
                    :height="aspectRatio?void 0:itemHeight_"
                    :aspect-ratio="aspectRatio"
                    :src="item.icon||((item.active||item.error)?item.blob:item.address||$defaultImg)"
                    :cut="{width:400}"
                    :fit="fit"
                    radius
                ></XImage>
                <div
                    v-if="!isDisabled"
                    class="del"
                    @click.stop="onDel(item,index)"
                >
                    <i class="f-icon">&#xf01a;</i>
                </div>

                <div
                    class="error"
                    v-if="item.error && item.error !== 'compressing'"
                >
                    <div
                        v-if="/(timeout|network)/.test(item.error)"
                        @click.stop="onRetry(item)"
                    >
                        点击重试
                    </div>
                    <i
                        class="f-icon tc-red fs-more"
                        v-else
                        @click.stop="onRetry(item)"
                    >&#xf041;
                    </i>
                </div>
                <div
                    class="progress"
                    v-else-if="item.active || item.error === 'compressing'"
                >
                    <div class="inner" />
                    <div class="label">
                        {{ +item.progress }}%
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="['item add',{hide:!showAddItem}]"
            :style="itemStyles"
        >
            <vupload
                :input-id="id"
                class="inner"
                :style="addInnerStyles"
                :headers="$globalVar.reqHeaders"
                ref="upload"
                name="file"
                :post-action="action_"
                v-model="Vfiles"
                @input-filter="inputFilter"
                @input-file="inputFile"
                :size="size"
                :accept="accept"
                :capture="capture"
                :data="postData"
            >
                <div class="icon-wrap">
                    <div class="inner">
                        <XIcon
                            :content="iconContent"
                            :size="iconSize"></XIcon>
                        <div
                            v-if="placeholder"
                            class="label"
                        >
                            {{ placeholder }}
                        </div>
                    </div>
                </div>
            </vupload>
        </div>

        <!--popup 编辑裁剪时才用-->
        <!-- <transition
            :name="aniName"
        >-->
        <Page
            v-if="cropper"
            ref="popup"
            v-show="popupShow"
        >
            <PageNav label="编辑图片"/>
            <Container fill>
                <div
                    class="edit-img-wrap"
                    style="height: 100%;"
                    ref="editImgWrap"
                ></div>
            </Container>
            <PageBar>
                <XButton
                    style="min-width: initial;"
                    class="stretch"
                    theme="primary"
                    icon="f04a"
                    @click="onRotate(-1)"
                ></XButton>
                <XButton
                    class="item"
                    theme="second"
                    label="不裁切上传"
                    @click="onEditRawImgSave"
                />
                <XButton
                    class="item"
                    theme="main"
                    label="保存"
                    @click="onEditImgSave"
                />
                <XButton
                    style="min-width: initial;"
                    class="stretch"
                    theme="primary"
                    icon="f049"
                    @click="onRotate(1)"
                ></XButton>
            </PageBar>
        </Page>
        <!--</transition>-->
    </div>
</template>

<script>
import formMixin from 'packages/mixins/formMixin';
import Compressorjs from 'compressorjs';
import { transformCssValue, createObjectURL, isNumber, isObject } from '@/utils';
import { vueUploadComponent, registeredChange, destroyChange } from './vueUploadComponent';
import cropperImageMixin from './cropperImageMixin';
import * as icons from './icons';

function getSuffix(filename) {
    const pos = filename.lastIndexOf('.');
    let suffix = '';
    if (pos !== -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
}
function randomString(len) {
    len = len || 32;
    const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    const maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i += 1) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
function getRandomFileName(filename) {
    const suffix = getSuffix(filename);
    return randomString(10) + suffix;
}

/* eslint-disable no-param-reassign */
export default {
    name: 'Upload',
    mixins: [cropperImageMixin, formMixin],
    components: { vupload: vueUploadComponent },
    data() {
        return {
            // id: this.$utils.getUniqueId('up'),
            Vfiles: [],
            files: [],
            isCompressing: false,
            inputEl: null,
            ossAction_: '',
        };
    },
    props: {
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        action: {
            type: String,
            default: '',
            // required: true,
        },
        ossAction: {
            type: String,
            default: '',
        },
        size: {
            type: Number,
            default: 8 * 1024 * 1024,
        },
        compress: {
            type: [Number, Object],
            default: 2 * 1024 * 1024,
        },
        accept: {
            type: String,
            default: '',
        },
        capture: {
            type: String,
            default: undefined,
        },
        extensions: {
            type: [Array, String, RegExp],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        maxFiles: {
            type: Number,
            default: 0,
        },
        columns: {
            type: Number,
            default: 1,
        },
        placeholder: {
            type: String,
            default: '',
        },
        // 确定图片如何适应容器框，同原生 object-fit
        fit: {
            type: String,
            default: 'cover',
            validator(val) {
                return /^(fill|contain|cover|none|scale-down)$/.test(val);
            },
        },
        itemHeight: {
            type: [String, Number],
            default: '100%',
        },
        demoImage: {
            type: String,
            default: '',
        },
        checkType: {
            type: String,
            default: '',
        },
        iconContent: {
            type: String,
            default: 'f013',
        },
        iconSize: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        action_() {
            const { action } = this;
            const { AXIOS_DEFAULT_CONFIG = {} } = this.$globalVar.appConfig || {};
            return `${AXIOS_DEFAULT_CONFIG.baseURL || ''}${action}`;
        },
        postData() {
            const { checkType } = this;
            const res = {};
            if (checkType) res.checkType = checkType;
            return res;
        },
        $$upload() {
            return this.$refs.upload;
        },
        filesMap() {
            const obj = {};
            this.files.forEach(item => {
                obj[item.id] = item;
            });
            return obj;
        },
        sizeName() {
            if (this.size < 1024) {
                return `${this.size} b`;
            }
            if (this.size < 1024 * 1024) {
                return `${this.size / 1024} kb`;
            }
            if (this.size < 1024 * 1024 * 1024) {
                return `${this.size / 1024 / 1024} mb`;
            }
            if (this.size < 1024 * 1024 * 1024 * 1024) {
                return `${this.size / 1024 / 1024 / 1024} gb`;
            }
            return '';
        },
        isDisabled() {
            const { disabled_, readonly_ } = this;
            return disabled_ || readonly_;
        },
        showAddItem() {
            const { maxFiles, files, isDisabled } = this;
            return !isDisabled && (!maxFiles || maxFiles > files.length);
        },
        itemStyles() {
            const { columns } = this;
            return {
                width: `${100 / columns}%`,
            };
        },
        addInnerStyles() {
            const { aspectRatio, itemHeight_, demoImage } = this;
            const res = {};
            if (demoImage) {
                res.backgroundImage = `url(${demoImage})`;
                res.backgroundColor = `transparent`;
            }
            if (aspectRatio) {
                res.paddingTop = `${aspectRatio * 100}%`;
            } else {
                res.height = itemHeight_;
            }
            return res;
        },
        itemHeight_() {
            const { itemHeight } = this;
            return transformCssValue(itemHeight);
        },
        aspectRatio() {
            const { itemHeight_ } = this;
            if (!/%$/.test(itemHeight_)) return 0;
            const val = parseFloat(itemHeight_);
            if (!val) return 0;
            return val / 100;
        },
    },
    watch: {
        value: {
            handler(val) {
                const { files } = this;
                files.forEach(item => {
                    if (!item.active) return;
                    this.$$upload.remove(item.file);
                });
                files.splice(0, files.length);
                val.forEach(item => {
                    files.push({
                        ...item,
                    });
                });
            },
            immediate: true,
        },
    },
    methods: {
        async handlerOss(newFile) {
            const { data: ossData } = await this.$http.get(this.ossAction);
            newFile.data = {
                ...newFile.data,
                key: `${ossData.dir}${getRandomFileName(newFile.name)}`,
                // key: `${ossData.dir}`,
                policy: ossData.policy,
                OSSAccessKeyId: ossData.accessid,
                success_action_status: '200', // 让服务端返回200,不然，默认会返回204
                callback: ossData.callback,
                signature: ossData.signature,
            };
            newFile.isOssHandled = true;
            newFile.postAction = ossData.host;
            this.$$upload.add(newFile);
        },
        async compressImage(newFile) {
            let options = {
                strict: true,
                checkOrientation: true,
                maxWidth: undefined,
                maxHeight: undefined,
                minWidth: 0,
                minHeight: 0,
                width: undefined,
                height: undefined,
                quality: 0.7,
                mimeType: '',
                convertSize: Infinity, // 5000000,
            };
            if (isNumber(this.compress) && this.compress > 0 && this.compress < newFile.size) {
                // noop
            } else if (isObject(this.compress)) {
                options = {
                    ...options,
                    ...this.compress,
                };
            } else {
                newFile.isCompressed = true;
                this.$$upload.add(newFile);
                return;
            }
            newFile.error = 'compressing';
            this.isCompressing = true;
            const self = this;
            // eslint-disable-next-line no-new
            new Compressorjs(newFile.file, {
                ...options,
                success(file) {
                    file.resolve = newFile.file.resolve;
                    file.reject = newFile.file.reject;
                    self.isCompressing = false;
                    newFile.isCompressed = true;

                    newFile.file = file;
                    newFile.error = '';
                    newFile.size = file.size;
                    newFile.type = file.type;
                    self.$$upload.add(newFile);
                },
                error(err) {
                    self.isCompressing = false;
                    newFile.error = err.message || 'compress';
                    self.$$upload.add(newFile);
                },
            });
        },
        inputFilter(newFile, oldFile, prevent) {
            if (this.maxFiles === 1 && this.files && this.files.length > 1) {
                const [file] = this.files;
                if (file.active) {
                    this.$$upload.update(file.file, { active: false });
                }
                this.files.splice(0, 1);
            }

            if (!newFile || typeof newFile !== 'object' || oldFile) return null;
            // 创建 blob 字段 用于图片预览
            if (!newFile.blob) {
                newFile.blob = newFile.file.blob || createObjectURL(newFile.file);
            }
            if (newFile.file && newFile.file.edited) return null;
            if (this.cropper) {
                this.editImg(newFile);
                return prevent();
            }
            if (!newFile.isCompressed && newFile.file && newFile.type.substr(0, 6) === 'image/' && this.compress) {
                this.compressImage(newFile);
                return prevent();
            }

            if (this.ossAction && !newFile.isOssHandled) {
                this.handlerOss(newFile);
                return prevent();
            }
            return null;
        },
        inputFile(newFile, oldFile) {
            // 添加文件
            if (newFile && !oldFile) {
                this.onAddFile(newFile);
            }

            // 更新文件
            if (newFile && oldFile) {
                // 开始上传
                if (newFile.active && newFile.active !== oldFile.active) {
                    this.onUploadStart(newFile);

                    // 限定最小字节;
                    console.log(this.size, newFile.size);
                    if (newFile.size >= 0 && newFile.size > this.size) {
                        newFile = this.$$upload.update(newFile, { error: 'size' });
                    }
                }

                // 上传进度
                if (newFile.progress !== oldFile.progress) {
                    this.onProgress(newFile, newFile.progress);
                }

                // 上传错误
                if (newFile.error !== oldFile.error && oldFile.error !== 'compressing') {
                    this.onError(newFile, newFile.error);
                }

                // 上传成功
                if (newFile.success !== oldFile.success) {
                    this.onSuccess(newFile, newFile.response);
                }
            }

            // 删除文件
            if (!newFile && oldFile) {
                // 自动删除 服务器上的文件
                if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/file/delete?id=' + oldFile.response.id,
                    // });
                }
            }

            // 自动上传
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$$upload.active) {
                    this.$$upload.active = true;
                }
            }
        },
        onAddFile(file) {
            // console.log('添加文件', file);
            const rawFile = file.file || file;
            file.icon = this.getIcon(rawFile.name);
            file.data = {
                ...file.data,
                fileName: rawFile.name || '',
            };
            if (!this.filesMap[file.id]) {
                this.files.push({
                    id: file.id,
                    active: file.active,
                    blob: file.blob,
                    progress: file.progress,
                    name: '',
                    address: '',
                    error: '',
                    msg: '',
                    success: '',
                    icon: file.icon,
                    file,
                });
            }
        },
        onUploadBefore() {},
        onUploadStart(file) {
            // console.log('开始上传', file.active, file);
            this.$emit('start', file);
            this.replaceFile(file);
        },
        onProgress(file) {
            // console.log('上传进度', progress, file);
            this.replaceFile(file, 'progress');
        },
        onSuccess(file, response) {
            console.log('上传成功', response, file);
            // this.files.push(response.data);
            const { success, msg } = response;
            const {
                file: { resolve, reject },
            } = file;
            if (!success) {
                file.error = msg;
                file.success = false;
                this.$emit('error', new Error(msg));
                if (reject) reject(new Error(msg));
            } else {
                this.$emit('success', response.data);
                if (resolve) resolve(response.data);
            }

            const data = {
                ...file,
                ...response.data,
                msg,
            };
            if (this.replaceFile(data)) this.replaceValue();
        },
        onError(file, error) {
            console.log('上传错误', file, error, file.error);
            if (this.replaceFile(file)) this.replaceValue();
            this.$emit('error', this.getError(file));
            const {
                file: { reject },
            } = file;
            if (reject) reject(error);
        },
        replaceValue() {
            const value = this.files.reduce((pre, curr) => {
                if (
                    (!curr.active && curr.success) ||
                    (!this.$utils.hasOwn(curr, 'active') && !this.$utils.hasOwn(curr, 'success'))
                ) {
                    pre.push({
                        name: curr.name,
                        address: curr.address,
                        error: curr.error,
                        msg: curr.msg,
                        icon: curr.icon,
                    });
                }
                return pre;
            }, []);
            this.$emit('input', value);
        },
        replaceFile(file, key) {
            const currFile = this.filesMap[file.id];
            if (!currFile) return false;
            if (key) {
                if (this.$utils.hasOwn(currFile, key)) {
                    currFile[key] = file[key];
                } else {
                    throw new Error('key 是无效的');
                }
            } else {
                Object.keys(currFile).forEach(key2 => {
                    if (key2 === 'id' || key2 === 'file') return;
                    currFile[key2] = file[key2];
                });
            }
            currFile.file = file;
            return currFile.success;
        },
        getError(file) {
            let msg = '';
            switch (file.error) {
                case 'timeout':
                    msg = `链接超时`;
                    break;
                case 'network':
                    msg = `网络链接错误`;
                    break;
                case 'size':
                    msg = `超过指定大小(${this.sizeName})`;
                    break;
                case 'extension':
                    msg = '文件类型错误';
                    break;
                case 'abort':
                    msg = '用户终止';
                    break;
                case 'server':
                case 'denied':
                    msg = '服务器错误，请联系管理员';
                    break;
                default:
                    msg = file.error;
                    break;
            }
            return new Error(msg);
        },
        onRetry(item) {
            // 内置的错误标识 size, extension, abort, server, denied, timeout, network
            // 可重试的 错误
            if (/(timeout|network)/.test(item.error)) {
                this.$$upload.add({ file: item.file.file, id: item.id });
                return;
            }
            const err = this.getError(item);
            this.$messageBox.tips(err.message);
        },
        onDel(item, index) {
            if (item.active) {
                this.$$upload.update(item.file, { active: false });
            }
            this.files.splice(index, 1);
            this.replaceValue();
        },
        clear() {
            this.files.forEach((item, index) => {
                this.onDel(item, index);
            });
        },
        getIcon(name) {
            // const reg = /\.[^\.]+$/;
            const fileExt = name.replace(/.+\./, '').toLowerCase();
            // .exec(importUrl)
            if (/jpg|jpeg|png|gif|webp/.test(fileExt)) {
                // 排除图片格式
                return '';
            }
            if (icons[fileExt]) {
                return icons[fileExt];
            }
            if (/xls|xlsx|xltx|xltm|xlsb|xlam|xlsm/.test(fileExt)) {
                return icons.xls;
            }
            return icons.other;
        },
        async addFile(file) {
            const promise = new Promise((resolve, reject) => {
                file.resolve = resolve;
                file.reject = reject;
            });
            this.$$upload.add(file);
            return promise;
        },
        selectFile() {
            return new Promise((resolve, reject) => {
                const { inputEl } = this;
                if (inputEl) {
                    inputEl.isVirtualClick = true;
                    inputEl.click();
                    inputEl.selectFilePromise = { resolve, reject };
                }
            });
        },
        onItem(item, index) {
            const { maxFiles } = this;
            if (maxFiles !== 1 || index !== 0) return;
            this.selectFile();
        },
    },
    mounted() {
        this.inputEl = this.$$upload.$el.querySelector(`#${this.id}`);
        registeredChange(this.inputEl);
    },
    beforeDestroy() {
        destroyChange(this.inputEl);
        const { files } = this;
        const len = files.length;
        for (let i = len - 1; i >= 0; i -= 1) {
            const item = files[i];
            if (!item.active) return;
            this.$$upload.remove(item.file);
        }
        files.splice(0, len);
    },
};
</script>

<style lang="scss">
.upload {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: $padding/2;
    /*background-color: #fff;*/

    > .item {
        flex: 0 0 auto;
        width: 100%;
        padding: $padding/2;

        > .inner {
            box-shadow: 0 0.05rem 0.1rem 0 rgba(0, 0, 0, 0.3);

            position: relative;
            border-radius: $padding-small;

            > .del {
                position: absolute;
                z-index: 300;
                top: -0.15rem;
                right: -0.15rem;
                color: $color-danger;
                font-size: 0.4rem;
                line-height: 0.4rem;
                width: 0.4rem;
                text-align: center;
                border-radius: 50%;
                background-color: #fff;
            }

            > .progress {
                position: absolute;
                z-index: 100;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0.1rem;
                background: rgba(0, 0, 0, 0.5);

                > .inner {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-top: -0.4rem;
                    margin-left: -0.4rem;
                    box-sizing: border-box;
                    border: solid 5px transparent;
                    border-top-color: $color-main;
                    border-left-color: $color-main;
                    border-radius: 50%;

                    animation: nprogress-spinner 400ms linear infinite;
                }

                > .label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.26rem;
                    color: #fff;
                }
            }

            > .error {
                position: absolute;
                z-index: 200;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 0.1rem;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }

        &.add {
            > .inner {
                position: relative;
                box-shadow: none;
                display: block;
                background: $gray2 center no-repeat;
                background-size: 100% 100%;

                > .icon-wrap {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: $gray6;
                    > .inner {
                        padding: $padding-big;
                        border-radius: $padding-small;
                        background-color: rgba(233, 236, 239, 0.7);
                        > .x-icon {
                            vertical-align: middle;
                            //font-size: 20px;
                        }
                        > .label {
                            margin-top: $margin-small;
                        }
                    }
                }
            }

            &.hide {
                position: absolute;
                top: -200vh;
                left: -200vw;
            }
        }
    }
}
.edit-img-wrap {
    height: 100%;
    /* Ensure the size of the image fit the container perfectly */
    > img {
        display: block;
        margin: 0 auto;

        /* This rule is very important, please don't ignore this */
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
