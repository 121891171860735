/**
 * Created by henian.xu on 2020/5/26.
 *
 */
import { get } from 'api/request';

export function verticalGroupingList({ verticalGroupingStatus, currentPage, pageSize }) {
    return get(this.url, { verticalGroupingStatus, currentPage, pageSize });
}

export function verticalGroupingDetail(verticalGroupingId) {
    return get(this.url, { verticalGroupingId });
}

export function admitCardList({ isPrintAdmissionTicket, currentPage, pageSize }) {
    return get(this.url, { isPrintAdmissionTicket, currentPage, pageSize });
}
