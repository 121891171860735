<!-- Created by henian.xu on 2019/3/6. -->

<template>
    <div :class="classes">
        <div class="input">
            <XIcon :content="icon" />
            <input
                :type="type"
                v-bind="__attrProps"
                v-model="model"
                :value="value" />
        </div>
        <label :for="id">
            <XLabel v-bind="labelProps">
                <slot/>
            </XLabel>
        </label>
    </div>
</template>

<script>
import { labelMixin, formMixin } from '../mixins';

export default {
    name: 'Checkbox',
    mixins: [labelMixin, formMixin],
    model: {
        prop: 'checked',
        // event: 'change',
    },
    data() {
        return {
            appliedToLabelScopedSlots: true,
        };
    },
    props: {
        /* eslint-disable vue/require-prop-types */
        /* eslint-disable vue/require-default-prop */
        value: {},
        checked: {},
        type: {
            validator(value) {
                return /(checkbox|radio)/.test(value);
            },
            default: 'checkbox',
        },
        reverse: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.checked;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        isCheckbox() {
            return this.type === 'checkbox';
        },
        isChecked() {
            const { model, value, isCheckbox } = this;
            if (Array.isArray(model) && isCheckbox) {
                return model.some(item => item === value);
            }
            if (isCheckbox) {
                if (typeof model !== 'boolean') {
                    // throw new Error('[Checkbox]: type="checkbox" model 类型应该为 Boolean/Array');
                }
                return model;
            }
            if (typeof model === 'object') {
                // throw new Error('[Checkbox]: type="radio" model 类型应该为 String/Number');
            }
            return model === value;
        },
        icon() {
            const { isChecked, isCheckbox } = this;
            if (isCheckbox) return isChecked ? 'f026' : 'f025';
            return isChecked ? 'f019' : 'f01b';
        },
        classes() {
            const { reverse, disabled } = this;
            return ['checkbox', { reverse }, { disabled }];
        },
    },
};
</script>

<style lang="scss">
$size: 40px !default;
.checkbox {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > .input {
        position: relative;
        width: $size;
        height: $size;
        > .x-icon {
            font-size: $size;
        }
        > input {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            appearance: none;
            outline: none;
        }
    }
    > label {
        margin-left: $margin-small;
    }
    &.reverse {
        > .input {
            order: 1;
        }
        > label {
            margin-left: 0;
            margin-right: $margin-small;
        }
    }
    &.disabled {
        opacity: 0.5;
    }
}
</style>
