/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

export function mockExamPaperInfo({ certExamApplyId }) {
    return get(this.url, {
        certExamApplyId,
    });
}
export function paperList({ certExamApplyId, currentPage, pageSize }) {
    return get(this.url, {
        certExamApplyId,
        currentPage,
        pageSize,
    });
}
export function exam({ certExamApplyId, certExamExamId, verticalGroupingId, paperType }, config) {
    return post(
        this.url,
        {
            certExamApplyId,
            certExamExamId,
            verticalGroupingId,
            paperType,
        },
        {
            ...config,
        },
    );
}
export function paperDetail({ certExamApplyPaperId }) {
    return get(this.url, {
        certExamApplyPaperId,
    });
}
export function savePaper({ certExamApplyPaperId, questionJson }) {
    return post(this.url, {
        certExamApplyPaperId,
        questionJson,
    });
}
export function completePaper({ certExamApplyPaperId, questionJson }) {
    return post(this.url, {
        certExamApplyPaperId,
        questionJson,
    });
}
// 练习详情
export function practiceExamPaperInfo({ certExamApplyId }) {
    return get(this.url, { certExamApplyId });
}

/**
 * 题目列表
 * @param certExamApplyId
 * @param testType              1顺序练习 2章节练习 3专项练习 4随机练习 5错题本 6技巧记题 7快速搜题
 * @param questionCatId
 * @param questionType
 * @param questionAnswer
 * @param keywords
 * @param rand
 * @param currentPage
 * @param pageSize
 * @returns {Promise<*>}
 */
export function questionList({
    certExamApplyId,
    testType,
    questionCatId,
    questionType,
    questionAnswer,
    keywords,
    rand,
    currentPage,
    pageSize,
}) {
    return get(this.url, {
        certExamApplyId,
        testType,
        questionCatId,
        questionType,
        questionAnswer,
        keywords,
        rand,
        currentPage,
        pageSize,
    });
}
// 添加错题本
export function addErrorBook({ certExamApplyId, questionId }) {
    return post(this.url, {
        certExamApplyId,
        questionId,
    });
}
// 删除错题本
export function delErrorBook({ certExamApplyId, questionId }) {
    return post(this.url, {
        certExamApplyId,
        questionId,
    });
}
// 保存练习进度
export function savePracticeProgress({
    certExamApplyId,
    testType,
    questionCatId,
    questionType,
    questionAnswer,
    progress,
}) {
    return post(this.url, {
        certExamApplyId,
        testType,
        questionCatId,
        questionType,
        questionAnswer,
        progress,
    });
}
// 保存练习选择答案
export function savePracticeSelect({ certExamApplyId, testType, questionId, selectOption }) {
    return post(this.url, {
        certExamApplyId,
        testType,
        questionId,
        selectOption,
    });
}

export function questionTypeList({ certExamApplyId, keywords }) {
    return get(this.url, {
        certExamApplyId,
        keywords,
    });
}

export function checkMemberExamStatus() {
    return get(this.url);
}

export function practiceHeartbeat({ certExamApplyId, practiceType }, cfg = {}) {
    return post(
        this.url,
        { certExamApplyId, practiceType },
        {
            isHandleError: true,
            ...cfg,
        },
    );
}
