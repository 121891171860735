/**
 * Created by henian.xu on 2019/10/23.
 *
 */

const isDev = process.env.NODE_ENV === 'development';

// TODO 模块命名空间
export const MODULE_NAMESPACE = '';

// 登录接口
export const LOGIN_API = '/rs/user/login';

// 路由默认配置,路由表并不从此注入
export const ROUTER_DEFAULT_CONFIG = {
    mode: 'hash', // 'hash' | 'history' | 'abstract'
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
};

// TODO vuex 默认配置
export const VUEX_DEFAULT_CONFIG = {};

// axios 默认配置
export const AXIOS_DEFAULT_CONFIG = {
    // isMock: true, // 默认保用 mock 数据
    baseURL: `${isDev ? '/devApi' : ''}/rpc`,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // token: (Local.get('userInfo') || {}).token,
    },
};
