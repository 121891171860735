/**
 * Created by henian.xu on 2019/11/17.
 *
 */

const requireComponent = require.context('./', true, /index\.(vue|js)$/);

function registerComponent(vue, component) {
    if (component.noTag) {
        // eslint-disable-next-line no-param-reassign
        vue.prototype[`$${component.names}`] = component;
    } else {
        vue.component(component.name, component);
    }
}

export default vue => {
    requireComponent.keys().forEach(filePath => {
        // 排除 mixins 文件夹
        if (/^\.\/mixins\//.test(filePath)) return;
        // 只取二级目录下的文件
        if (filePath.split('/').length !== 3) return;
        const components = requireComponent(filePath);
        Object.values(components).forEach(component => registerComponent(vue, component));
    });
};
