/**
 * Created by henian.xu on 2020/4/1.
 *
 */

import { get } from 'api/request';

export function schoolOrgList() {
    return get(this.url);
}
export function invoiceTypeList() {
    return get(this.url);
}
