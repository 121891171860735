/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

// 领证列表
export function list({ certificateReceiveStatus, currentPage, pageSize }) {
    return get(this.url, {
        certificateReceiveStatus,
        currentPage,
        pageSize,
    });
}

// 领证详情
export function detail({ id }) {
    return get(this.url, { id });
}

// 确认领证方式
export function confirmShipMethod({
    id,
    shipMethod,
    deliveryConsignee,
    deliveryPhone,
    deliveryRegionId,
    deliveryAddress,
}) {
    return post(this.url, {
        id,
        shipMethod,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
    });
}

// 到校自取确认
export function schoolReceiveCert({ id }) {
    return post(this.url, {
        id,
    });
}
