<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        :class="classes"
        v-bind="linkerProps"
        v-on="excludeInputListeners">
        <slot name="icon">
            <XIcon
                :class="[{ 'ma-rs': hasLabel }]"
                v-if="icon"
                v-bind="iconProps_" />
        </slot>
        <XLabel
            v-if="hasLabel"
            v-bind="labelProps"
        />
        <slot></slot>
    </div>
</template>

<script>
import linkerMixin from '../mixins/linkerMixin';
import labelMixin from '../mixins/labelMixin';
import iconMixin from '../mixins/iconMixin';
import listenersMixin from '../mixins/listenersMixin';
import themeMixin from '../mixins/themeMixin';
import commonMixin from '../mixins/commonMixin';

export default {
    name: 'XButton',
    mixins: [labelMixin, iconMixin, linkerMixin, listenersMixin, themeMixin, commonMixin],
    data() {
        return {
            appliedToLabelScopedSlots: true,
        };
    },
    props: {
        radius: {
            type: [Boolean, String],
            default: '',
        },
        plain: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Boolean,
            default: false,
        },
        lockText: {
            type: Boolean,
            default: false,
        },
        fill: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: '', // row;column
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { theme, size, direction, radius, plain, link, fill, pointer, readonly, disabled, lockText } = this;
            return [
                'x-button',
                theme,
                size,
                { 'lock-text': lockText },
                direction,
                radius,
                { radius },
                { plain },
                { link },
                { fill },
                { pointer },
                { readonly },
                { disabled },
            ];
        },
        iconProps_() {
            const { iconProps, subLabel, direction } = this;
            const props = { ...iconProps };
            delete props.theme;
            props.size = subLabel && direction !== 'column' ? 'big' : '';
            return props;
        },
    },
};
</script>

<style lang="scss">
$padding-vertical: $padding-small !default;
$padding-horizontal: $padding-big !default;
$font-size: $body-font-size !default;
.x-button {
    vertical-align: middle;
}

.x-button {
    position: relative;
    flex: 0 0 auto;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
    border: 1px solid $color-border;
    min-height: 55px;
    line-height: 1;

    /* 尺寸大小 */
    @each $selector, $value in $sizeList_ {
        #{$selector} {
            min-height: $formItemHeight * $value;
            padding: $padding-vertical * $value $padding-horizontal * $value;
            font-size: $font-size * $value;

            &.radius {
                border-radius: $padding-vertical / 1 * $value;

                &.all {
                    border-radius: 100vw;
                }
            }
        }
    }

    &.lock-text {
        font-size: $font-size;
    }

    /* 主题颜色 */
    @each $selector, $map in $themeList {
        #{$selector} {
            background-color: map-get($map, 'bgc');
            border-color: map-get($map, 'bgc');
            color: map-get($map, 'color');

            &,
            > .x-label > .label,
            > .x-label > .sub-label {
                color: map-get($map, 'color');
            }

            &.plain {
                background-color: transparent;
                border-color: map-get($map, 'bgc');
            }

            &.plain,
            &.link {
                &,
                > .x-label > .label,
                > .x-label > .sub-label {
                    color: map-get($map, 'bgc');
                }
            }
        }
    }

    &.plain,
    &.link {
        background-color: transparent;
    }

    &.link {
        border: none;
    }

    &.fill {
        flex: 1 1 1%;
        display: flex;
    }

    &.column {
        flex-direction: column;
        text-align: center;

        > .x-icon {
            margin-right: 0;
        }
    }

    &.disabled {
        opacity: 0.5;
        background-color: $gray4;
        border-color: $gray4;

        &,
        > .x-label > .label,
        > .x-label > .sub-label {
            color: $gray6;
        }
    }
}
</style>
