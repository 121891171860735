<!-- Created by henian.xu on 2019/11/19. -->

<!--<template>
    <div :class="classes">
        <div
            v-if="isColumn"
            class="inner"
        >
            <slot :itemStyle="itemStyle" />
        </div>
        <slot v-else />
    </div>
</template>-->

<script lang="jsx">
export default {
    name: 'List',
    data() {
        return {};
    },
    props: {
        column: {
            type: [Number,String],
            default: 1,
        },
        border: {
            type: Boolean,
            default: false,
        },
        margin: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: [Boolean, String],
            default: false,
        },
        overflow: {
            type: String,
            default: '',
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const {
                isColumn: column, border, margin, padding, full,
            } = this;
            return [
                'list',
                { column },
                { border },
                { margin },
                { padding },
                { full },
                padding,
            ];
        },
        isColumn() {
            return this.column > 1;
        },
        itemStyle() {
            const { column, isColumn ,overflow} = this;
            const res = {}
            if (!isColumn) return res;
            res.width =   `${100 / column}%`;
            if(overflow) res.overflow =   overflow;
            return res
        },
    },
    render() {
        const {
            classes, isColumn, itemStyle, $scopedSlots: slot,
        } = this;
        return <div class={classes}>{
            isColumn
                ? <div class="inner">{
                    slot.default && slot.default().map(node => node.tag?<div class="item" style={itemStyle}>{node}</div>:'')
                }</div>
                : slot.default && slot.default().map(node => node.tag?<div class="item" style={itemStyle}>{node}</div>:'')
        }</div>;
    },
};
</script>

<style lang="scss">
.list {
    > .item {
        /*overflow: hidden;*/
    }

    &.border {
        > .item {
            + .item {
                border-top: 1px solid $color-border;
            }
        }
    }
    &.margin {
        > .item {
            //margin: $margin 0;
            + .item {
                margin-top: $margin;
            }
        }
    }
    &.padding {
        > .item {
            padding: 0 $padding;
        }
        &.all {
            > .item {
                padding: $padding;
            }
        }
    }
    &.full {
        &,
        > .inner {
            > .item {
                background-color: $color-component-bgc;
            }
        }
    }

    &.column {
        overflow: hidden;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            margin: -$margin-small;

            > .item {
                flex: 0 0 auto;
                padding: $margin-small;
            }
        }

        &.padding {
            > .inner {
                padding: 0 $padding;
            }
            &.all {
                > .inner {
                    padding: $padding;
                }
            }
        }
    }
}
</style>
