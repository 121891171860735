import Vue from 'vue';
import VueRouter from 'vue-router';
import { GlobalVar } from 'utils';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: 'index',
    },
    ...[
        {
            path: '/index/:tabKey?',
            name: 'Index',
            meta: {
                title: '首页',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Index.vue'),
        },
    ],
    // 公用
    ...[
        {
            path: '/login',
            name: 'login',
            meta: {
                title: '登录',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/User/Login.vue'),
        },
        {
            path: '/register',
            name: 'register',
            meta: {
                title: '注册',
            },
            component: () => import(/* webpackChunkName: "main" */ 'pages/User/Register.vue'),
        },
        {
            path: '/forgotPassword',
            name: 'forgotPassword',
            meta: {
                title: '忘记密码',
            },
            component: () => import(/* webpackChunkName: "main" */ 'pages/User/ForgotPassword.vue'),
        },
        {
            path: '/changePassword',
            name: 'changePassword',
            meta: {
                title: '修改密码',
            },
            component: () => import(/* webpackChunkName: "main" */ 'pages/User/ChangePassword.vue'),
        },
        {
            path: '/user/info',
            name: 'userInfo',
            meta: {
                title: '个人信息',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/User/Info.vue'),
        },
        {
            path: '/user/idCardAuth',
            name: 'userIdCardAuth',
            meta: {
                title: '身份实名',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/User/IdCardAuth.vue'),
        },
    ],
    // 支付
    ...[
        {
            path: '/pay/result/:module/:paymentSn',
            name: 'payResult',
            meta: {
                title: '支付页面',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Pay/Result.vue'),
        },
    ],
    // 证件
    ...[
        {
            path: '/credentials/list',
            name: 'credentialsList',
            meta: {
                title: '我的证件',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Credentials/List.vue'),
        },
        {
            path: '/credentials/detail/:memberApplyMaterialsId', // memberApplyMaterialsId
            name: 'credentialsDetail',
            meta: {
                title: '证件详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Credentials/Detail.vue'),
        },
        {
            path: '/credentials/add',
            name: 'credentialsAdd',
            meta: {
                title: '添加证件',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Credentials/Add.vue'),
        },
    ],
    // 发票
    ...[
        {
            path: '/invoice/list',
            name: 'InvoiceList',
            meta: {
                title: '我的发票',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Invoice/List.vue'),
        },
        {
            path: '/invoice/detail/:id', // orderId
            name: 'InvoiceDetail',
            meta: {
                title: '发票详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Invoice/Detail.vue'),
        },
        {
            path: '/invoice/add/:id', // orderId
            name: 'InvoiceAdd',
            meta: {
                title: '申请开票',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Invoice/Add.vue'),
        },
    ],
    // 退款
    ...[
        {
            path: '/refund/list',
            name: 'RefundList',
            meta: {
                title: '我的服务',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Refund/List.vue'),
        },
        {
            path: '/refund/apply',
            name: 'RefundApply',
            meta: {
                title: '退款申请',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Refund/Apply.vue'),
        },
        {
            path: '/refund/detail',
            name: 'RefundDetail',
            meta: {
                title: '退款详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Refund/Detail.vue'),
        },
    ],
    // 收货地址
    ...[
        {
            path: '/deliveryAddress/list',
            name: 'DeliveryAddressList',
            meta: {
                title: '收货地址',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/DeliveryAddress/List.vue'),
        },
        {
            path: '/deliveryAddress/edit/:id?',
            name: 'DeliveryAddressEdit',
            meta: {
                title: '地址详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/DeliveryAddress/Edit.vue'),
        },
    ],
    // 企业
    ...[
        {
            path: '/enterprise',
            name: 'EnterpriseIndex',
            meta: {
                title: '企业信息',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Index.vue'),
        },
        ...[
            {
                path: '/enterprise/cooperation',
                name: 'EnterpriseCooperation',
                meta: {
                    title: '企业合作',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Cooperation/Index.vue'),
            },
            {
                path: '/enterprise/cooperation/detail/:id',
                name: 'EnterpriseCooperationDetail',
                meta: {
                    title: '企业合作详情',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Cooperation/Detail.vue'),
            },
            {
                path: '/enterprise/cooperation/add',
                name: 'EnterpriseCooperationAdd',
                meta: {
                    title: '企业合作申请',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Cooperation/Add.vue'),
            },
        ],
        ...[
            {
                path: '/enterprise/student/:id?', // cooperationId
                name: 'EnterpriseStudent',
                meta: {
                    title: '企业学员',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Student/Index.vue'),
            },
            {
                path: '/enterprise/student/detail/:id', // cooperationId
                name: 'EnterpriseStudentDetail',
                meta: {
                    title: '学员学习编班详情',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Student/Detail.vue'),
            },
            {
                path: '/enterprise/student/schedule/detail/:id', // verticalGroupingId; query certExamApplyId
                name: 'EnterpriseStudentScheduleDetail',
                meta: {
                    title: '考试信息详情',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Student/Schedule/Detail.vue'),
            },
        ],
        // 领证
        ...[
            {
                path: '/enterprise/certificateReceive/list/:certificateReceiveStatus?',
                name: 'EnterpriseCertificateReceiveList',
                meta: {
                    title: '我的领证',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/CertificateReceive/List.vue'),
            },
            {
                path: '/enterprise/certificateReceive/detail/:id', // certificateReceiveId
                name: 'EnterpriseCertificateReceiveDetail',
                meta: {
                    title: '领证详情',
                },
                component: () =>
                    import(/* webpackChunkName: "about" */ 'pages/Enterprise/CertificateReceive/Detail.vue'),
            },
        ],
        {
            path: '/enterprise/learningCode',
            name: 'EnterpriseLearningCode',
            meta: {
                title: '企业学习码',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/LearningCode/Index.vue'),
        },
        {
            path: '/enterprise/applyMaterials',
            name: 'EnterpriseApplyMaterials',
            meta: {
                title: '企业信息',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/ApplyMaterials.vue'),
        },
        {
            path: '/enterprise/supplementMaterials',
            name: 'EnterpriseApplySupplementMaterials',
            meta: {
                title: '企业信息',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/SupplementMaterials.vue'),
        },
        {
            path: '/enterprise/editMaterials/:applyMaterialsId',
            name: 'EnterpriseEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/EditMaterials.vue'),
        },
        {
            path: '/enterprise/previewMaterials/:applyMaterialsId',
            name: 'EnterprisePreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/PreviewMaterials.vue'),
        },
        {
            path: '/enterprise/invoiceInfo/',
            name: 'EnterpriseInvoiceInfo',
            meta: {
                title: '企业开票信息',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/InvoiceInfo.vue'),
        },
        {
            path: '/enterprise/monthStat/',
            name: 'EnterpriseMonthStat',
            meta: {
                title: '培训月报表',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/MonthStat.vue'),
        },
        {
            path: '/enterprise/statement',
            name: 'EnterpriseStatement',
            meta: {
                title: '培训结算单',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Enterprise/Statement.vue'),
        },
    ],
    // 订单
    ...[
        {
            path: '/order/list/:invoiceStatus?',
            name: 'OrderList',
            meta: {
                title: '订单列表',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Order/List.vue'),
        },
        {
            path: '/order/detail/:id', // orderId
            name: 'OrderDetail',
            meta: {
                title: '订单详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Order/Detail.vue'),
        },
        {
            path: '/order/create/:goodsType/:certExamType/:certCatId',
            name: 'OrderCreate',
            meta: {
                title: '自动下单',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Order/Create.vue'),
        },
        {
            path: '/order/new/:uuid',
            name: 'OrderNew',
            meta: {
                title: '订单确认',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Order/New.vue'),
        },
    ],
    // 服务
    ...[
        {
            path: '/serve/detail/:id', // goodsId
            name: 'ServeDetail',
            meta: {
                title: '报名详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Serve/Detail.vue'),
        },
    ],
    // 学习
    ...[
        // 公开课
        ...[
            {
                path: '/course/public/list',
                name: 'CoursePublicList',
                meta: {
                    title: '公开课',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Course/Public/List.vue'),
            },
            {
                path: '/course/public/detail/:id',
                name: 'CoursePublicDetail',
                meta: {
                    title: '公开课详情',
                },
                component: () => import(/* webpackChunkName: "about" */ 'pages/Course/Public/Detail.vue'),
            },
        ],
        // 学习
        {
            path: '/course/detail/:id', // courseId
            name: 'CourseDetail',
            meta: {
                title: '课程学习',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/Detail.vue'),
        },
        {
            path: '/course/score/:id', // certExamApplyId
            name: 'CourseScore',
            meta: {
                title: '学习成绩与学时',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/Score.vue'),
        },
        {
            path: '/course/applyMaterials/:id', // certExamApplyId
            name: 'CourseApplyMaterials',
            meta: {
                title: '报名材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/ApplyMaterials.vue'),
        },
        {
            path: '/course/editMaterials/:certExamApplyId/:applyMaterialsId',
            name: 'CourseEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/EditMaterials.vue'),
        },
        {
            path: '/course/previewMaterials/:certExamApplyId/:applyMaterialsId',
            name: 'CoursePreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/PreviewMaterials.vue'),
        },
        {
            path: '/course/:id', // certExamApplyId
            name: 'CourseIndex',
            meta: {
                title: '课程学习',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/Index.vue'),
        },
        {
            path: '/course/courseOutAuth/:certExamApplyId/:certExamCourseDetailId', // certExamApplyId
            name: 'CourseOutAuth',
            meta: {
                title: '课程学习人脸验证',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Course/CourseOutAuth.vue'),
        },
    ],
    // 题库
    ...[
        {
            path: '/experience',
            name: 'experienceIndex',
            meta: {
                title: '题库体验',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Experience/Index.vue'),
        },
        {
            path: '/experience/paper/:id', // paperId
            name: 'experiencePaper',
            meta: {
                title: '考试页面',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Experience/Paper.vue'),
        },
    ],
    // 资讯
    ...[
        {
            path: '/cms/list/:id?', // cmsCatId
            name: 'cmsList',
            meta: {
                title: '资讯列表',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Cms/List.vue'),
        },
        {
            path: '/cms/detail/:id', // cmsId
            name: 'cmsDetail',
            meta: {
                title: '资讯详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Cms/Detail.vue'),
        },
    ],
    // 考试
    ...[
        {
            path: '/exam/simulation/:id', // certExamApplyId
            name: 'ServeSimulation',
            meta: {
                title: '模拟考试',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/Simulation.vue'),
        },
        {
            path: '/exam/category/:id', // certExamApplyId
            name: 'examCategory',
            meta: {
                title: '分类练习',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/Category.vue'),
        },
        {
            path: '/exam/skill/:id', // certExamApplyId
            name: 'examSkill',
            meta: {
                title: '技巧记题',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/Skill.vue'),
        },
        {
            path: '/exam/searchQuestion/:id', // certExamApplyId
            name: 'examSearchQuestion',
            meta: {
                title: '快速搜题',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/SearchQuestion.vue'),
        },
        {
            path: '/exam/paper/:id', // certExamApplyPaperId
            name: 'examPaper',
            meta: {
                title: '考试页面',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/Paper.vue'),
        },
        {
            path: '/exam/result/:id', // certExamApplyPaperId
            name: 'examResult',
            meta: {
                title: '考试页面',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/Result.vue'),
        },
        {
            // testType; certExamApplyId; ?mode
            path: '/exam/practice/:type/:id',
            name: 'examPractice',
            meta: {
                title: '练习',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/Practice.vue'),
        },
        {
            path: '/exam/passProve/:applyNo',
            name: 'ExamPassProve',
            meta: {
                title: '学时证明',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/PassProve.vue'),
        },
        {
            path: '/exam/mockPractical/:certExamApplyId',
            name: 'ExamMockPractical',
            meta: {
                title: '模拟实操考试',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/MockPractical.vue'),
        },
        {
            path: '/exam/saveMockPractical/:certExamApplyId',
            name: 'ExamSaveMockPractical',
            meta: {
                title: '实操成绩录入',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Exam/SaveMockPractical.vue'),
        },
    ],
    // 编班
    ...[
        {
            path: '/schedule',
            name: 'Schedule',
            meta: {
                title: '我的考试',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Schedule/Index.vue'),
        },
        {
            path: '/schedule/Detail/:id', // verticalGroupingId 编班 id
            name: 'ScheduleDetail',
            meta: {
                title: '考试信息详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Schedule/Detail.vue'),
        },
    ],
    // 满意度调查
    ...[
        {
            path: '/survey',
            name: 'Survey',
            meta: {
                title: '满意度调查',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Survey/Index.vue'),
        },
        {
            path: '/survey/detail/:id', // surveyId
            name: 'SurveyDetail',
            meta: {
                title: '满意度调查',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Survey/Detail.vue'),
        },
    ],
    // 准考证
    ...[
        {
            path: '/admitCard',
            name: 'AdmitCard',
            meta: {
                title: '准考证',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/AdmitCard/Index.vue'),
        },
        {
            path: '/admitCard/detail/:id', // surveyId
            name: 'AdmitCardDetail',
            meta: {
                title: '准考证',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/AdmitCard/Detail.vue'),
        },
    ],
    // 系统
    ...[
        {
            path: '/system/feedback',
            name: 'systemFeedback',
            meta: {
                title: '意见反馈',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/System/Feedback.vue'),
        },
        {
            path: '/system/notice',
            name: 'systemNotice',
            meta: {
                title: '通知',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/System/Notice.vue'),
        },
        {
            path: '/system/notice/detail/:id',
            name: 'systemNoticeDetail',
            meta: {
                title: '通知详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/System/NoticeDetail.vue'),
        },
    ],
    // 补贴申请
    ...[
        {
            path: '/subsidyApply/list',
            name: 'SubsidyApplyList',
            meta: {
                title: '补贴申请',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/SubsidyApply/List.vue'),
        },
        {
            path: '/subsidyApply/applyMaterials/:id', // subsidyApplyId
            name: 'SubsidyApplyApplyMaterials',
            meta: {
                title: '报名材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/SubsidyApply/ApplyMaterials.vue'),
        },
        {
            path: '/subsidyApply/editMaterials/:subsidyApplyId/:applyMaterialsId',
            name: 'SubsidyApplyEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/SubsidyApply/EditMaterials.vue'),
        },
        {
            path: '/subsidyApply/previewMaterials/:subsidyApplyId/:applyMaterialsId',
            name: 'SubsidyApplyPreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/SubsidyApply/PreviewMaterials.vue'),
        },
    ],
    // 补证申请
    ...[
        {
            path: '/supplementApply/list',
            name: 'SupplementApplyList',
            meta: {
                title: '补证申请',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/SupplementApply/List.vue'),
        },
        {
            path: '/supplementApply/applyMaterials/:id', // supplementApplyId
            name: 'SupplementApplyMaterials',
            meta: {
                title: '补证申请材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/SupplementApply/ApplyMaterials.vue'),
        },
    ],
    // 延期复审
    ...[
        {
            path: '/deferredReview/list',
            name: 'DeferredReviewList',
            meta: {
                title: '延期复审',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/DeferredReview/List.vue'),
        },
        {
            path: '/deferredReview/applyMaterials/:id', // deferredReviewApplyId
            name: 'DeferredReviewApplyMaterials',
            meta: {
                title: '报名材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/DeferredReview/ApplyMaterials.vue'),
        },
        {
            path: '/deferredReview/editMaterials/:deferredReviewApplyId/:applyMaterialsId',
            name: 'DeferredReviewApplyEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/DeferredReview/EditMaterials.vue'),
        },
        {
            path: '/deferredReview/previewMaterials/:deferredReviewApplyId/:applyMaterialsId',
            name: 'DeferredReviewApplyPreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/DeferredReview/PreviewMaterials.vue'),
        },
    ],
    // 线下培训
    ...[
        {
            path: '/onlyTraining/list',
            name: 'OnlyTrainingList',
            meta: {
                title: '线下培训',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/OnlyTraining/List.vue'),
        },
        {
            path: '/onlyTraining/applyMaterials/:id', // onlyTrainingApplyId
            name: 'OnlyTrainingApplyMaterials',
            meta: {
                title: '报名材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/OnlyTraining/ApplyMaterials.vue'),
        },
        {
            path: '/onlyTraining/editMaterials/:onlyTrainingApplyId/:applyMaterialsId',
            name: 'OnlyTrainingApplyEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/OnlyTraining/EditMaterials.vue'),
        },
        {
            path: '/onlyTraining/previewMaterials/:onlyTrainingApplyId/:applyMaterialsId',
            name: 'OnlyTrainingApplyPreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/OnlyTraining/PreviewMaterials.vue'),
        },
    ],
    // 继续教育
    ...[
        {
            path: '/continuingEducation/list',
            name: 'ContinuingEducationList',
            meta: {
                title: '继续教育',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/ContinuingEducation/List.vue'),
        },
        {
            path: '/continuingEducation/applyMaterials/:id', // continuingEducationApplyId
            name: 'ContinuingEducationApplyMaterials',
            meta: {
                title: '报名材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/ContinuingEducation/ApplyMaterials.vue'),
        },
        {
            path: '/continuingEducation/editMaterials/:continuingEducationApplyId/:applyMaterialsId',
            name: 'ContinuingEducationApplyEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/ContinuingEducation/EditMaterials.vue'),
        },
        {
            path: '/continuingEducation/previewMaterials/:continuingEducationApplyId/:applyMaterialsId',
            name: 'ContinuingEducationApplyPreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/ContinuingEducation/PreviewMaterials.vue'),
        },
    ],
    // 材料
    ...[
        {
            path: '/materials/previewMaterials/:materialsId',
            name: 'MaterialsPreviewMaterials',
            meta: {
                title: '材料预览',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Materials/PreviewMaterials.vue'),
        },
        {
            path: '/materials/editMaterials/:materialsId',
            name: 'MaterialsEditMaterials',
            meta: {
                title: '编辑材料',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Materials/EditMaterials.vue'),
        },
    ],
    // 领证
    ...[
        {
            path: '/certificateReceive/list/:certificateReceiveStatus?',
            name: 'CertificateReceiveList',
            meta: {
                title: '我的领证',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/CertificateReceive/List.vue'),
        },
        {
            path: '/certificateReceive/detail/:id', // certificateReceiveId
            name: 'CertificateReceiveDetail',
            meta: {
                title: '领证详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/CertificateReceive/Detail.vue'),
        },
    ],
    // 测试
    ...[
        {
            path: '/test',
            name: 'test',
            meta: {
                title: '测试',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/Test'),
        },
    ],
    // 群二维码
    ...[
        {
            path: '/groupQRCode/learn/:id', // certExamApplyId
            name: 'GroupQRCodeLearn',
            meta: {
                title: '学习群二维码',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/GroupQRCode/Learn.vue'),
        },
        {
            path: '/groupQRCode/schedule/:id', // verticalGroupingId 编班 id
            name: 'GroupQRCodeSchedule',
            meta: {
                title: '班级群二维码',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/GroupQRCode/Schedule.vue'),
        },
    ],
    // 远程教学
    ...[
        {
            path: '/trainingLive/list',
            name: 'TrainingLiveList',
            meta: {
                title: '远程教学',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/TrainingLive/List.vue'),
        },
        {
            path: '/trainingLive/detail/:id', // trainingLiveId
            name: 'TrainingLiveDetail',
            meta: {
                title: '远程教学详情',
            },
            component: () => import(/* webpackChunkName: "about" */ 'pages/TrainingLive/Detail.vue'),
        },
    ],
];

const router = new VueRouter({
    ...GlobalVar.appConfig.ROUTER_DEFAULT_CONFIG,
    routes,
});

async function initAppData() {
    if (initAppData.isCalled) return;
    initAppData.isCalled = true;
    console.log('初始路由数据完成');
}

router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
        return;
    }
    initAppData(to)
        .then(() => {
            // noop
        })
        .catch(e => {
            console.error('初始路由数据失败', e);
        })
        .finally(() => {
            next();
        });
});
router.afterEach(() => {
    if (GlobalVar.routerEdReplace) {
        setTimeout(() => {
            window.location.reload();
        }, 20);
    }
});

router.onError(error => {
    const pattern = /Loading chunk (\w)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    // console.log(error, isChunkLoadFailed, targetPath);
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});

export default router;
