<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="delivery-address-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <XIcon
            content="f00a"
            theme="success"
        />
        <div class="content">
            <div class="header">
                <div class="label">
                    <div class="name">{{data.consignee}}</div>
                    <div class="mobile">{{data.phone}}</div>
                </div>
                <div
                    v-if="data.isDefault"
                    class="extra"
                >默认</div>
            </div>
            <div class="body">
                <div class="inner">
                    <span class="address">{{data.regionNamePath}} {{data.address}}</span>
                    <!--<Badge
                        size="small"
                        theme="second"
                    >公司</Badge>-->
                </div>
                <XIcon
                    v-if="!isSelect"
                    content="f012"
                    size="small"
                    theme="g5"
                />
            </div>
            <div
                v-if="!isSelect"
                class="footer"
            >
                <XButton
                    icon="f009"
                    theme="primary"
                    size="small"
                    radius
                    @click.prevent.stop="onEdit"
                />
                <XButton
                    icon="f008"
                    theme="danger"
                    size="small"
                    radius
                    @click.prevent.stop="onDelete"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'DeliveryAddressItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        isSelect: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onEdit(event) {
            this.$emit('edit', event);
        },
        onDelete(event) {
            this.$emit('delete', event);
        },
    },
};
</script>

<style lang="scss">
.delivery-address-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 $padding;
    background-color: $color-component-bgc;

    > .x-icon {
        flex: 0 0 auto;
        margin: $margin $margin-small $margin 0;
    }
    > .content {
        flex: 1;
        > .header {
            margin: $margin 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .label {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                > .name {
                    font-weight: bold;
                }
                > .mobile {
                    color: $color-text-minor;
                    margin-left: $margin-small;
                }
            }
            > .extra {
                color: $color-text-minor;
            }
        }
        > .body {
            margin: $margin 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .inner {
                flex: 1;
            }
        }
        > .footer {
            margin: $margin 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .x-button {
                + .x-button {
                    margin-left: $margin-small;
                }
            }
        }
    }
}
</style>
