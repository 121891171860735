/**
 * Created by henian.xu on 2020/4/3.
 *
 */

import { dataURLtoBlob, createObjectURL } from 'utils/index';

export async function getQRCode() {
    const { default: qrcode } = await import(/* webpackChunkName: "qrcode" */ 'qrcode');
    return qrcode;
}

export async function toDataURL(...arg) {
    const qrCode = await getQRCode();
    if (arg.length <= 1) {
        arg.push({
            margin: 2,
            scale: 30,
            errorCorrectionLevel: 'H',
        });
    }
    const url = await qrCode.toDataURL.apply(null, arg);
    return createObjectURL(dataURLtoBlob(url));
}
