/**
 * Created by henian.xu on 2020/1/15.
 *
 */
import { bus, getKeyByRouter } from './runtime';
import { getRoutes, setRoutes } from './routes';

// 前进
function forward(key, to, from) {
    const toRoute = { route: to };
    const fromRoute = { route: from };
    const routes = getRoutes();
    fromRoute.name = routes[routes.length - 1] || null;
    toRoute.name = key;
    routes.push(key);
    setRoutes(routes);
    bus.$emit('forward', toRoute, fromRoute);
}
// 退后
function back(count, to, from) {
    const toRoute = { route: to };
    const fromRoute = { route: from };
    const routes = getRoutes();
    fromRoute.name = routes[routes.length - 1];
    toRoute.name = routes[routes.length - 1 - count];
    routes.splice(routes.length - count, count);
    setRoutes(routes);
    bus.$emit('back', toRoute, fromRoute);
}
// 替换
function replace(key, to, from) {
    const toRoute = { route: to };
    const fromRoute = { route: from };
    const routes = getRoutes();
    // if from does not exist, it will be set null
    fromRoute.name = routes[routes.length - 1] || null;
    toRoute.name = key;
    routes.splice(routes.length - 1, 1, key);
    setRoutes(routes);
    bus.$emit('replace', toRoute, fromRoute);
}
// 刷新
function refresh(to, from) {
    const toRoute = { route: to };
    const fromRoute = { route: from };
    const routes = getRoutes();
    toRoute.name = routes[routes.length - 1];
    fromRoute.name = toRoute.name;
    bus.$emit('refresh', toRoute, fromRoute);
}

// 记录
export function record(to, from, replaceFlag) {
    const key = getKeyByRouter(to);
    if (replaceFlag) {
        replace(key, to, from);
    } else {
        const routes = getRoutes();
        const index = routes.lastIndexOf(key);
        const routesLen = routes.length - 1;
        if (index === -1) {
            // 前进
            forward(key, to, from);
        } else if (index === routesLen) {
            refresh(to, from);
        } else {
            back(routesLen - index, to, from);
        }
    }
}

// 重置
export function reset() {
    setRoutes([]);
    bus.$emit('reset');
}
