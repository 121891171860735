/**
 * Created by henian.xu on 2019/10/25.
 *
 */

export default {
    computed: {
        excludeInputListeners() {
            if (this.disabled) return {};
            const listeners = { ...this.$listeners };
            delete listeners.input;
            return listeners;
        },
    },
};
