/**
 * Created by henian.xu on 2020/4/1.
 *
 valueType
 1 个人 快递寄送
 2 个人 到校领取
 3 企业 快递寄送
 4 企业 到校领取
 */

import { get, post } from 'api/request';

// 材料列表
export function materialsValueList({ valueType, valueId }) {
    return get(this.url, {
        valueType,
        valueId,
    });
}

// 材料详情
export function materialsDetail({ valueType, valueId, materialsId }) {
    return get(this.url, {
        valueType,
        valueId,
        materialsId,
    });
}

// 保存材料
export function saveMaterials({ valueType, valueId, materialsId, materialsValue }) {
    return post(this.url, {
        valueType,
        valueId,
        materialsId,
        materialsValue,
    });
}
