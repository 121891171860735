/**
 * Created by henian.xu on 2020/1/15.
 *
 */

import { matches } from 'utils/index';
import { navComponentList } from './runtime';

let routes = [];

if (window.sessionStorage.VUE_NAVIGATION) {
    routes = JSON.parse(window.sessionStorage.VUE_NAVIGATION);
}
export const getRoutes = () => routes;
export const setRoutes = val => {
    routes = val;
    window.sessionStorage.VUE_NAVIGATION = JSON.stringify(routes);

    // TODO 可以添加 routes.push hook 来优化以下代码
    navComponentList.forEach(component => {
        const { cache } = component;
        Object.keys(cache).forEach(key => {
            if (!matches(val, key)) {
                const vnode = cache[key];
                if (vnode) vnode.componentInstance.$destroy();
                delete cache[key];
            }
        });
    });
};
