<!-- Created by henian.xu on 2019/10/24. -->

<template>
    <div :class="selfClasses">
        <div
            v-if="isHideItem"
            class="quantity-info"
        >
            <!--{{ options.length }}/{{ dataList.length }}-->
            共 {{ dataList.length }} 项
        </div>
        <div class="inner">
            <div
                class="item"
                v-for="(item,index) in options"
                :key="item.id"
                :is="item.href&&!item.isVideo?'Linker':'div'"
                :to="item.isVideo?null:item.href"
                :style="listItemStyle"
                @click="onListItem($event,item)"
            >
                <component
                    :is="item.isVideo?'XVideo':'XImage'"
                    :src="item.isVideo?item.href:item.src"
                    :disabled="item.isVideo?columns>1:void 0"
                    :cover="item.isVideo?item.src:void 0"
                    :alt="item.label"
                    :height="aspectRatio?void 0:itemHeight_"
                    :aspect-ratio="aspectRatio"
                    :fit="fit"
                />
                <div
                    v-if="item.label&&columns===1"
                    class="label"
                >{{item.label}}</div>
                <div
                    v-if="isDelete"
                    class="delete"
                    @click.stop.prevent="onDelete(item.src,index)"
                >
                    <XIcon
                        content="f01a"
                        size="0.4rem"
                        theme="danger"></XIcon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import XImage from 'packages/Image/index';
import { transformCssValue } from 'utils/index';

export default {
    name: 'ImageList',
    components: { XImage },
    data() {
        return {};
    },
    props: {
        data: {
            type: Array,
            default: null,
            required: true,
        },
        prop: {
            type: Object,
            default: () => ({}),
        },
        // 显示列数,仅列表时有效
        columns: {
            type: Number,
            default: 1,
        },
        // 设置列中每一项的高度,仅列表时有效
        itemHeight: {
            type: [String, Number],
            default: null,
        },
        // 确定图片如何适应容器框，同原生 object-fit
        fit: {
            type: String,
            default: 'cover',
            validator(val) {
                return /^(fill|contain|cover|none|scale-down)$/.test(val);
            },
        },
        // 最大显示数量,仅列表时有效
        maxShow: {
            type: Number,
            default: null,
        },
        padding: {
            type: Boolean,
            default: false,
        },
        // 是否预览.目前仅支持微信预览
        preview: {
            type: Boolean,
            default: false,
        },
        autoWidth: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        prop_() {
            return {
                id: 'id',
                label: 'label',
                src: 'src',
                href: 'href',
                isVideo: 'isVideo',
                ...this.prop,
            };
        },
        dataList() {
            const { data, prop_: prop } = this;
            if (!data || !data.length) return [];
            return data.reduce((pre, cur, index) => {
                const item = {};
                if (typeof cur === 'string') {
                    item.id = index;
                    item.label = '';
                    item.src = cur || this.$defaultImg;
                    item.href = undefined;
                    item.isVideo = false;
                } else {
                    item.id = cur[prop.id] || index;
                    item.label = cur[prop.label] || '';
                    item.src = cur[prop.src] || this.$defaultImg;
                    item.href = cur[prop.href] || undefined;
                    item.isVideo = !!cur[prop.isVideo];
                }
                if (item.src) pre.push(item);
                return pre;
            }, []);
        },
        options() {
            const { dataList, maxShow } = this;
            if (!maxShow) return dataList;
            return dataList.filter((item, index) => index < maxShow);
        },
        isHideItem() {
            const { dataList, options, maxShow } = this;
            return !(!maxShow || maxShow >= dataList.length || options.length > dataList.length);
        },
        selfClasses() {
            const { padding, autoWidth } = this;
            return ['image-list', { padding }, { 'auto-width': autoWidth }];
        },
        listItemStyle() {
            const { columns } = this;
            return {
                width: `${100 / columns}%`,
            };
        },
        itemHeight_() {
            const { itemHeight } = this;
            return transformCssValue(itemHeight);
        },
        aspectRatio() {
            const { itemHeight_ } = this;
            if (!/%$/.test(itemHeight_)) return 0;
            const val = parseFloat(itemHeight_);
            if (!val) return 0;
            return val / 100;
        },
        isDelete() {
            return !!this.$listeners.delete;
        },
        isListenerClickItem() {
            return this.$utils.hasOwn(this.$listeners, 'clickItem');
        },
    },
    methods: {
        onListItem($event, item) {
            this.$emit('clickItem', item);
            if (item.href) return;
            if (this.isListenerClickItem || this.preview) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            if (this.preview && this.$wxSDK) {
                this.$wxSDK.previewImage(item, this.data).catch(() => {
                    console.log('onListItem: 目前仅支持微信预览');
                    /* console.log(index, this.swiper);
                    this.swiper.slideTo(index, 1000, false);
                    this.swiperPopup.open(); */
                });
            }
        },
        onDelete(address, index) {
            this.$emit('delete', { address, index });
        },
    },
};
</script>

<style lang="scss">
.image-list {
    flex: 1;
    position: relative;

    > .quantity-info {
        position: absolute;
        z-index: $z-index-2;
        left: 0;
        top: 0;
        padding: 0 $padding 0 $padding-small;
        background-color: rgba(255, 255, 255, 0.5);
        border-bottom-right-radius: $padding;
        border: solid $color-border;
        border-width: 0 1px 1px 0;
        font-size: $little * 100%;
        line-height: 1.5;
        color: $gray6;
    }

    > .inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        > .item {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > .x-image,
            > .x-video {
                display: block;
                width: 100%;
            }

            > .label {
                align-self: stretch;
                padding: $padding-small $padding;
            }

            > .delete {
                position: absolute;
                z-index: $z-index-2;
                top: 0;
                right: 0;
                margin-top: -0.2rem;
                margin-right: -0.2rem;
            }
        }
    }

    &.padding {
        > .inner {
            margin: -$padding-small;
            > .item {
                padding: $padding-small;
                > .delete {
                    top: $padding-small;
                    right: $padding-small;
                }
            }
        }
    }

    &.auto-width {
        > .inner {
            > .item {
                > .x-image,
                > .x-video {
                    width: initial;
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
