<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div class="query-bar">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <div class="label">{{label}}</div>
            <div :class="['selected',{noop:true}]">{{value||placeholder}}</div>
            <XIcon
                class="icon"
                content="f012"
                size="small"
                theme="g5"
            />
        </div>

        <!--popup-->
        <transition name="show">
            <div
                ref="popup"
                class="popup down popup-query-bar"
                v-show="popupShow"
                @click.self="onSwitch"
            >
                <div class="inner">
                    <div class="header">
                        <XButton
                            icon="f000"
                            theme="danger"
                            link
                        />
                        <XLabel :label="label"></XLabel>
                        <XButton
                            icon="f01a"
                            theme="danger"
                            link
                            @click="onSwitch"
                        />
                    </div>
                    <div class="body">
                        <slot></slot>
                    </div>
                    <div class="footer">
                        <XButton
                            label="取消"
                            theme="info"
                            @click="onCancel"
                        />
                        <XButton
                            label="重置"
                            theme="second"
                            @click="onReset"
                        />
                        <XButton
                            label="确认"
                            theme="success"
                            @click="onConfirm"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';

export default {
    name: 'QueryBar',
    mixins: [popupMixin],
    data() {
        return {};
    },
    props: {
        value: {
            type: String,
            default: '请选择',
        },
        label: {
            type: String,
            default: '',
        },
    },
    methods: {
        onCancel() {
            this.$emit('cancel');
            this.close();
        },
        onReset() {
            this.$emit('reset');
        },
        onConfirm() {
            this.$emit('confirm');
            this.close();
        },
    },
};
</script>

<style lang="scss">
.query-bar {
    background-color: $color-component-bgc;
    padding: 0 $padding;
    //@include boxShadow();
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: $navHeight;
        > .label {
            flex: 1;
        }
        > .selected,
        > .icon {
            flex: 0 0 auto;
        }

        > .selected {
            &.noop {
                color: $gray6;
            }
        }
        > .icon {
            margin-left: $margin-small;
        }
    }
}
</style>
