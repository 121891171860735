/**
 * Created by henian.xu on 2020/7/24.
 *
 */
import Nprogress from 'nprogress';

import loadingIcon from 'assets/images/loading.gif';

const oldStart = Nprogress.start;
const oldDone = Nprogress.done;
const oldRender = Nprogress.render;
Nprogress.timeId = 0;

Nprogress.render = function render(...args) {
    const isRendered = this.isRendered();
    const progress = oldRender.call(this, ...args);
    if (!isRendered) {
        this.progressEl = progress;
        progress.classList.add('hide');
    }
    return progress;
};

Nprogress.start = function start() {
    const {
        settings: { delay = 0 },
    } = this;
    clearTimeout(Nprogress.timeId);
    Nprogress.timeId = setTimeout(() => {
        this.progressEl.classList.remove('hide');
        Nprogress.timeId = 0;
    }, delay);
    return oldStart.call(this);
};

Nprogress.done = function done(...args) {
    if (Nprogress.timeId) clearTimeout(Nprogress.timeId);
    return oldDone.call(this, ...args);
};

Nprogress.configure({
    minimum: 0.5, // 通过 minimum 来修改最小百分比。
    // template: `<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"><div class="heart"></div><div class="money01"></div><div class="money02"></div><div class="shadow"></div></div></div>`, // 您可以通过 template 修改标记结构。为了保证进度条正常工作，需要一个包含 role='bar' 属性的元素。
    template: `<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"><img class="gif" src="${loadingIcon}"></div></div>`, // 您可以通过 template 修改标记结构。为了保证进度条正常工作，需要一个包含 role='bar' 属性的元素。
    ease: 'ease', // 通过 ease(一个 CSS 中的 easing 值) 调整动画设置和速度 speed （毫秒ms）。
    speed: 500,
    trickle: true, // 想关闭进度条步进？设置 trickle 为 false。
    trickleRate: 0.2, // 您可以调整 trickleRate (每次步进增长多少) 和 trickleSpeed (步进间隔，单位毫秒ms).
    trickleSpeed: 800,
    showSpinner: true, // 想禁用进度环？设置 showSpinner 为 false。
    delay: 1000,
});

export default Nprogress;
// this.$nprogress.start();
// this.$nprogress.done();
