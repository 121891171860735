<!-- Created by henian.xu on 2019/3/1. -->

<template>
    <div
        v-show="active"
        class="tab-panel">
        <!--
        这些插槽 将会转到 TabNav 组件中去
        <slot name="icon" />
        <slot name="label" />
        <slot name="subLabel" />
        <slot name="badge" />
        -->
        <slot />
    </div>
</template>

<script>
import { labelMixin } from '../mixins';

export default {
    name: 'TabPanel',
    mixins: [labelMixin],
    inject: ['Tab'],
    data() {
        return {
            index: -1,
        };
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        badge: {
            type: String,
            default: null,
        },
        badgeTheme: {
            type: String,
            default: 'danger',
        },
        sign: {
            type: [String, Number],
            default: undefined,
        },
    },
    computed: {
        active() {
            return this.Tab.currentIndex === this.index;
        },
    },
    updated() {
        this.$nextTick(() => {
            this.Tab.calcPaneInstances(true);
        });
    },
};
</script>

<style lang="scss">
.tab-panel {
}
</style>
