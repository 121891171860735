/**
 * Created by henian.xu on 2019/8/2.
 *
 */

import GlobalVar from 'globalVar';
import { Api } from 'api';

let querying = false;
export default {
    namespaced: true,
    state: {
        rawData: GlobalVar.userInfo,
    },
    getters: {
        userInfo(state) {
            const { rawData } = state;
            if (rawData === null) this.dispatch('updated');
            return rawData || {};
        },
    },
    mutations: {
        // 设置原始数据
        _setRawData(state, data) {
            let res = {};
            if (!state.rawData || !data) {
                res = data;
            } else {
                Object.keys(data).forEach(key => {
                    res[key] = data[key];
                });
            }
            state.rawData = res;
            GlobalVar.userInfo = state.rawData;
        },
        updateRawData(state, data) {
            const { rawData } = state;
            Object.keys(rawData).forEach(key => {
                const item = data[key];
                rawData[key] = !Number.isNaN(+item) ? item : item || rawData[key];
            });
        },
    },
    actions: {
        // 更新原始数据
        async updated({ commit }) {
            commit('_setRawData', {});
            if (querying) return Promise.resolve();
            querying = true;
            const res = await Api.Member.Mb.Member.memberDetail().finally(() => {
                querying = false;
            });
            commit('_setRawData', res.data);
            return res;
        },
    },
};
