/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get } from 'api/request';

// 导出批次详情
export function exportCertificateReceiveBatchDetailList(certificateReceiveBatchId) {
    return get(this.url, { certificateReceiveBatchId });
}
