<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="apply-item"
        @click="onSelf"
    >
        <div class="header">
            <XIcon
                content="f08a"
                theme="main"
            />
            <XLabel>{{data.certExamTypeName}}</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <div class="cart-cat">{{data.certCatNamePath}}</div>
                <dl>
                    <dt><div>报名</div>:</dt>
                    <dd><Badge>{{data.applyStatusName}}</Badge> <XButton
                        v-if="data.applyStatus === 61"
                        radius
                        size="small"
                        lock-text
                        theme="main"
                        @click.stop.prevent="()=>{onPay()}"
                    >立即缴费</XButton></dd>
                </dl>
                <dl>
                    <dt><div>学　　校</div>:</dt>
                    <dd>{{data.schoolOrgName}}</dd>
                </dl>
                <dl>
                    <dt><div>报名时间</div>:</dt>
                    <dd>{{data.examApplyTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                </dl>
                <dl>
                    <dt><div>学习群</div>:</dt>
                    <dd>
                        <span @click.stop.prevent="()=>{}">

                            <Linker
                                class="tc-primary"
                                :to="`/groupQRCode/learn/${data.id}`"
                            >点我加入学习群</Linker>
                        </span>
                    </dd>
                </dl>
                <div
                    class="dl-wrap"
                    v-if="!isLearning&&data.firstGroupingId">
                    <dl
                        v-for="item in data.subjectList"
                        :key="item.id">
                        <dt><div>{{ item.appraisalSubjectName }}</div>:</dt>
                        <dd>{{item.examResult}}</dd>
                    </dl>
                </div>
            </div>
            <XIcon
                v-if="(!isLearning&&data.firstGroupingId) && userInfo.servantId !== 2"
                content="f012"
                theme="g5"
                size="small" />
        </div>
        <div
            v-if="isLearning"
            class="footer"
        >
            <XButton
                label="报名材料"
                link
                :to="`/course/applyMaterials/${data.id}`"
            >
                <Badge
                    v-if="data.isWarnTips"
                    label=" "
                />
            </XButton>
            <XButton
                label="课程学习"
                link
                @click="()=>{onCourse()}"
            />
            <XButton
                label="试题练习"
                link
                @click="()=>{onPractice()}"
            />
        </div>
        <!-- dialog -->
        <Dialog
            v-if="initPracticeDialog"
            use-router
            ref="practiceDialog"
        >
            <CellGroup border>
                <Cell
                    icon="f08b"
                    icon-theme="success"
                    label="模拟考试"
                    link
                    :to="`/exam/simulation/${data.id}`"
                />
                <Cell
                    label="顺序练习"
                    icon-theme="primary"
                    icon="f08c"
                    :to="`/exam/practice/1/${data.id}`"
                />
                <Cell
                    label="分类练习"
                    icon-theme="danger"
                    icon="f08b"
                    :to="`/exam/category/${data.id}`"
                />
                <Cell
                    label="随机答题"
                    icon-theme="second"
                    icon="f08d"
                    :to="`/exam/practice/4/${data.id}`"
                />
                <Cell
                    label="错题本"
                    icon-theme="warning"
                    icon="f08e"
                    :to="`/exam/practice/5/${data.id}`"
                />
                <Cell
                    label="技巧记题"
                    icon-theme="success"
                    icon="f09d"
                    :to="`/exam/skill/${data.id}`"
                />
                <Cell
                    label="快速搜题"
                    icon-theme="primary"
                    icon="f09e"
                    :to="`/exam/searchQuestion/${data.id}`"
                />
                <Cell
                    v-if="hasMockPracticalTest"
                    label="模拟实操考试"
                    icon-theme="primary"
                    icon="f08b"
                    :to="`/exam/mockPractical/${data.id}`"
                />
            </CellGroup>
        </Dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ApplyItem',
    data() {
        return {
            initPracticeDialog: false,
        };
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        ...mapGetters('Dictionary', ['practiceType']),
        isLearning() {
            const { applyStatus } = this.data;
            return ![70, 97, 98, 99, 100].includes(applyStatus);
            // return applyStatus !== 70 && applyStatus !== 100 && applyStatus !== 98 && applyStatus !== 99;
        },
        hasMockPracticalTest() {
            return !!this.data.practicalTemplateId;
        },
    },
    methods: {
        valid() {
            const { data } = this;
            const { applyStatus } = data || {};
            if ([10, 20, 40, 98].includes(applyStatus)) {
                this.$messageBox.alert('报名材料审核通过后才能进行练习与学习');
                return false;
            }
            return true;
        },
        async onPractice() {
            if (!this.valid()) return;
            const { paperTemplateType, paperTemplateUrl } = this.data;
            if (paperTemplateType === 2) {
                window.location.href = paperTemplateUrl;
                return;
            }
            const { data } = await this.$api.Member.Ce.CertExamPaper.checkMemberExamStatus();
            if (data) {
                this.$messageBox.alert('考试答卷期间, 禁止访问!');
                return;
            }
            if (this.initPracticeDialog) {
                this.$refs.practiceDialog.open();
            } else {
                this.initPracticeDialog = true;
                await this.$nextTick();
                this.$refs.practiceDialog.open();
            }
        },
        onCourse() {
            if (!this.valid()) return;
            const { certExamCourseType, certExamCourseUrl } = this.data;
            if (certExamCourseType === 2) {
                window.location.href = certExamCourseUrl;
                return;
            }
            this.$router.push(`/course/${this.data.id}`);
        },
        onBuild() {
            this.$messageBox.alert('功能开发中，请线下联系老师提交材料。');
        },
        onSelf() {
            const { data, userInfo, isLearning } = this;
            console.log(isLearning);
            if (!isLearning && data.firstGroupingId && userInfo.servantId !== 2) {
                this.$router.push(`/course/score/${data.id}`);
            }
        },
        async onPay() {
            const { data } = this;
            const { data: resultData, code, success, msg } = await this.$api.Member.Ex.Order.buyNowConfirm(
                {
                    applyId: data.id,
                    orderType: 2,
                },
                {
                    isHandleError: true,
                },
            );
            if (!success) {
                if (code === 1200) {
                    await this.$messageBox.confirm(msg, '温馨提示', {
                        confirmBtnText: '立即实名',
                    });
                    this.$router.push(`/user/idCardAuth`);
                } else {
                    this.$messageBox.alert(msg);
                }
                return;
            }
            this.$router.replace(`/order/new/${resultData.uuid}`);
            // if (msg) this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
.apply-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();
    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1 1 1%;
            > .cart-cat {
                margin-bottom: $margin-small;
            }
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    @include clearfix;
                    > div {
                        width: 5em;
                        text-align: justify;
                        overflow: hidden;
                        margin-bottom: -($body-font-size * $line-height);
                        float: left;
                        &:after {
                            display: inline-block;
                            content: '';
                            padding-left: 100%;
                        }
                    }
                }
                > dd {
                    flex: 1;
                }
            }
            > .dl-wrap {
                margin-top: $margin-small;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                > dl {
                    flex: 0 0 50%;
                    + dl {
                        margin-top: 0;
                    }

                    &:nth-child(n + 3) {
                        margin-top: $margin-small;
                    }
                }
            }
        }
        > .x-icon {
            flex: 0 0 auto;
        }
    }
    > .footer {
        padding: $padding 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $color-border;
        > .x-button {
            flex: 1;
            color: $color-main;

            + .x-button {
                border-left: 1px solid $color-border;
            }

            > .badge {
                position: absolute;
                top: $padding-small;
                right: $padding;
            }
        }
    }
}
</style>
