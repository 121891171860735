<!-- Created by henian.xu on 2019/3/4. -->

<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'XForm',
    provide() {
        return { Form: this };
    },
    data() {
        return {
            fields: [],
        };
    },
    props: {
        model: {
            type: [Object, Array],
            default: () => ({}),
        },
        rules: {
            type: Object,
            default: () => ({}),
        },
        direction: {
            type: String,
            default: '', // row;column
        },
        align: {
            type: String,
            default: '', // left;right
        },
        border: {
            type: Boolean,
            default: false,
        },
        showFeedback: {
            type: Boolean,
            default: false,
        },
        labelWidth: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        scrollIntoError: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { border } = this;
            return ['form', { border }];
        },
    },
    methods: {
        addField(field) {
            if (!field || !field._isVue) return;
            this.fields.push(field);
        },
        removeField(field) {
            if (!field || !field._isVue) return;
            const index = this.fields.indexOf(field);
            if (index !== -1) this.fields.splice(index, 1);
        },
        validate() {
            if (!this.model) {
                throw new Error('[Form] 如果要使用验证功能必须传入 model 属性');
            }
            const { fields } = this;
            let callback;
            let valid = true;
            let len = fields.length;
            const invalidFields = [];
            const promise = new Promise((resolve, reject) => {
                callback = () => {
                    len -= 1;
                    if (len < 1) {
                        if (valid) {
                            resolve(valid);
                        } else {
                            if (this.scrollIntoError) {
                                const [field] = invalidFields;
                                const fieldEl = (field && field.$el) || field;
                                if (fieldEl) {
                                    fieldEl.scrollIntoView(true);
                                }
                            }
                            reject(valid);
                        }
                    }
                };
            });
            if (!len) callback();

            fields.forEach(field => {
                field
                    .validate()
                    .then(callback)
                    .catch(() => {
                        valid = false;
                        invalidFields.push(field);
                        callback();
                    });
            });

            return promise;
        },
        clearValidate() {
            this.fields.forEach(field => {
                field.clearValidate();
            });
        },
        resetFields() {
            this.fields.forEach(field => {
                field.resetField();
            });
        },
    },
};
</script>

<style lang="scss">
.form {
    .form-item {
        + .form-item {
            margin-top: $margin;
        }
    }
    &.border {
        .form-item {
            + .form-item {
                margin-top: 0;
                border-top: 1px solid $color-border;
            }
        }
    }
}
</style>
