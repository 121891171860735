<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <i
        :class="classes"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <svg
            v-if="svg"
            :style="styles"
            class="svg-inner"
        >
            <slot>
                <use
                    :xlink:href="iconName"
                    class="active"
                />
            </slot>
        </svg>
        <span
            v-else-if="isFontIcon"
            :style="styles"
            class="inner"
        >
            <slot>{{ content_ }}</slot>
        </span>
        <XImage
            v-else-if="content_"
            :src="content_"
            :style="styles"
            :radius="radius?radius:false"
        />
        <slot name="badge">
            <Badge
                v-if="badge"
                :label="badge"
                :size="badgeSize"
            />
        </slot>
    </i>
</template>

<script>
// TODO Icon 图标组件是否应该具备超链接与点击功能, 目前具备。
import { transformCssValue, Decode } from 'utils';
import { linkerMixin, listenersMixin, themeMixin, commonMixin } from '../mixins';

// eslint-disable-next-line no-unused-expressions
import(/* webpackChunkName: "svgs" */ './loadSvg');

export default {
    name: 'XIcon',
    mixins: [themeMixin, linkerMixin, listenersMixin, commonMixin],
    data() {
        return {};
    },
    props: {
        content: {
            type: String,
            default: '',
        },
        badge: {
            type: [String, Number],
            default: '',
        },
        badgeSize: {
            type: String,
            default: 'small',
        },
        svg: {
            type: Boolean,
            default: false,
        },
        fill: {
            type: Boolean,
            default: false,
        },
        radius: {
            type: [Boolean, String],
            default: '',
        },
    },
    computed: {
        classes() {
            const { theme, size, size_, pointer, svg, fill, radius } = this;
            return [
                'x-icon',
                'f-icon',
                theme,
                size_ ? '' : size,
                { svg },
                { fill },
                radius,
                { radius },
                { pointer },
                // add
            ];
        },
        styles() {
            const { size_ } = this;
            if (!size_) return {};
            return { fontSize: size_ };
        },
        size_() {
            return transformCssValue(this.size);
        },
        isFontIcon() {
            return /^f[\d,a-f]{3}$/.test(this.content);
            /* let { content } = this;
            const rex = /^&#x/;
            if (/^f[\d,a-f]{3}$/.test(content)) content = `&#x${content};`;
            return content && rex.test(content); */
        },
        content_() {
            const { content } = this;
            const { svg } = this;
            if (svg) return '';
            if (this.isFontIcon) {
                return Decode.uniDecode(`\\u${content}`);
            }
            return content;
        },
        iconName() {
            return `#icon-${this.content}`;
        },
    },
};
</script>

<style lang="scss">
.x-icon {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    vertical-align: middle;

    &.svg {
        display: inline-block;
        > .svg-inner {
            display: block;
            width: 1em;
            height: 1em;
            //vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
        }
    }
    > .x-image {
        width: 1em;
        height: 1em;
    }

    &.radius {
        border-radius: $padding-small;
        &.all {
            border-radius: 100vw;
        }
    }
    &.fill {
        padding: $padding;
    }

    /* 主题颜色 */
    @each $selector, $map in $themeList {
        #{$selector} {
            > .inner,
            > .svg-inner {
                color: map-get($map, 'bgc');
            }
            &.fill {
                background-color: map-get($map, 'bgc');
                > .inner,
                > .svg-inner {
                    color: map-get($map, 'color');
                }
            }
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key} {
            > .inner,
            > .svg-inner,
            > .x-image {
                font-size: $value * 100%;
            }
            &.fill {
                padding: $padding * $value;
            }
        }
    }

    > .badge {
        position: absolute;
        top: 0;
        left: calc(100% - 0.5em);
        transform: translateY(-50%);
    }
}
</style>
