/**
 * Created by henian.xu on 2020/4/2.
 *
 */

import { get } from 'api/request';

export function list({ currentPage, pageSize }) {
    return get(this.url, { currentPage, pageSize });
}
export function detail({ id, trainingLiveId }) {
    return get(this.url, { id: id || trainingLiveId });
}
