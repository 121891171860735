<!-- Created by henian.xu on 2020/7/24. -->

<template>
    <Dialog
        ref="dialog"
        label="认证个人信息"
        :show-close-btn="showCloseBtn"
        @close="onDialogClose"
    >
        <div
            class="ta-c pa-a"
            v-if="uploadUrl"
        >
            <Upload
                ref="upload"
                :action="uploadUrl"
                oss-action="/comm/rs/attachment/signOSSUpload"
                :max-files="1"
                :compress="{maxWidth:1900,maxHeight:1900}"
                :item-height="'120%'"
                accept="image/*"
                capture="camera"
                @start="onUploadStart"
                @error="onUploadError"
                @success="onUploadSuccess"
            ></Upload>
            <div
                v-if="errorMessage"
                class="tc-danger"
            >{{errorMessage}}</div>
        </div>
        <!--<template #footer>
            <div class="footer">
                <XButton theme="success">确认</XButton>
            </div>
        </template>-->
    </Dialog>
</template>

<script>
import Url from 'url';
import Qs from 'qs';

export default {
    name: 'RecognitionDialog',
    data() {
        return {
            promise: null,
            errorMessage: '',
        };
    },
    props: {
        params: {
            type: Object,
            default: () => ({}),
        },
        showCloseBtn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        uploadUrl() {
            const { params } = this;
            if (!params.certExamExamId) return '';
            const url = {
                pathname: '/member/ce/certExamPaper/recognition',
                search: Qs.stringify(params),
            };
            return Url.format(url);
        },
    },
    methods: {
        async validate() {
            const promise = new Promise((resolve, reject) => {
                this.promise = {
                    resolve,
                    reject,
                };
            });
            this.$refs.dialog.open();
            return promise;
        },
        onUploadStart() {
            this.errorMessage = '';
        },
        onUploadError(error) {
            this.errorMessage = error.message;
        },
        async onUploadSuccess(data) {
            console.log('onUploadStart', data);
            try {
                await this.$http.post('/member/ce/certExamPaper/recognition', {
                    ...this.params,
                    recognitionImage: data.name,
                });
            } catch (e) {
                // console.error(e);
                return;
            }
            if (this.promise) {
                this.promise.resolve();
                this.promise = null;
            }
            this.$refs.upload.clear();
            this.$refs.dialog.close();
        },
        onDialogClose() {
            if (this.promise) {
                this.promise.reject();
                this.promise = null;
            }
        },
    },
    beforeDestroy() {
        if (this.promise) {
            this.promise.reject({ destroy: true });
            this.promise = null;
        }
    },
};
</script>

<style lang="scss">
</style>
