<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'CellGroup',
    data() {
        return {};
    },
    props: {
        border: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { border } = this;
            return ['cell-group', { border }];
        },
    },
};
</script>

<style lang="scss">
.cell-group {
    .cell {
        + .cell {
            margin-top: $margin;
        }
    }
    &.border {
        .cell {
            + .cell {
                margin-top: 0;
                border-top: 1px solid $color-border;
            }
        }
    }
}
</style>
