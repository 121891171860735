/**
 * Created by henian.xu on 2018/12/19.
 * app 入口配置
 */

import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import 'utils/flex';
import 'utils/inobounce';
import 'assets/style/app.scss';
import { GlobalVar } from 'utils';

// vconsole 移动端调试插件
if (GlobalVar.useVConsole || /__use_vconsole__/.test(window.location.search)) {
    import(/* webpackChunkName: "vconsole" */ 'vconsole').then(({ default: VConsole }) => {
        GlobalVar.vConsole = new VConsole({
            defaultPlugins: ['system', 'network', 'element', 'storage'], // 可以在此设定要默认加载的面板
            maxLogNumber: 1000,
            // disableLogScrolling: true,
            /* onReady() {
                console.log('vConsole is ready.');
            },
            onClearLog() {
                console.log('on clearLog');
            }, */
        });
    });
}
