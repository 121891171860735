/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

export function applyDetail(id) {
    return get(this.url, { id });
}

export function applyList({ currentPage, pageSize }) {
    return get(this.url, {
        currentPage,
        pageSize,
    });
}

export function applyMaterialsDetail({ deferredReviewApplyId, applyMaterialsId }) {
    return get(this.url, {
        deferredReviewApplyId,
        applyMaterialsId,
    });
}

/**
 * 保存材料
 * @param deferredReviewApplyId
 * @param applyMaterialsId
 * @param applyMaterialsValue       json 串
 * @returns {Promise<*>}
 */
export function saveApplyMaterials({ deferredReviewApplyId, applyMaterialsId, applyMaterialsValue }) {
    return post(this.url, {
        deferredReviewApplyId,
        applyMaterialsId,
        applyMaterialsValue,
    });
}
export function submitAudit({
    companyName,
    workRegionId,
    deferredReviewApplyId,
    memberMsg,
    subsidyApplyType,
    signature,
    subsidyBankCard,
    bankAccount,
    openingBank,
}) {
    return post(this.url, {
        companyName,
        workRegionId,
        deferredReviewApplyId,
        memberMsg,
        subsidyApplyType,
        signature,
        subsidyBankCard,
        bankAccount,
        openingBank,
    });
}
