<!-- Created by henian.xu on 2020/4/28. -->

<template>
    <div
        class="edit-work-history"
        @click="onSwitch"
    >
        <div :class="['out-show',{noop:!model || !model.length}]">
            <div
                v-if="model && model.length"
                class="inner">
                <div
                    v-for="(item,index) in model"
                    :key="index"
                    class="item">
                    <div class="header">
                        <div class="education">{{item.startDate}} ~
                            <template v-if="item.isSoFar">至今</template>
                            <template v-else>{{item.endDate}}</template>
                        </div>
                    </div>
                    <div class="body">所在单位: {{item.companyName}}</div>
                    <div class="body">从事职业: {{item.profession}}</div>
                </div>
            </div>
            <div
                v-else
                class="ctrl">
                <div class="label">{{placeholder}}</div>
                <XIcon
                    content="f012"
                    theme="g5"
                    size="small" />
            </div>
        </div>

        <!--popup-->
        <transition :name="aniName">
            <Page
                ref="popup"
                class="popup"
                v-show="popupShow"
            >
                <PageNav label="编辑工作经历"></PageNav>
                <Container>
                    <XForm
                        ref="form"
                        :model="formModel"
                        :rules="formRules"
                        border
                        align="right"
                    >
                        <div
                            v-for="(item,index) in formModel"
                            :key="index"
                            class="edit-study-history-block"
                        >
                            <FormItem
                                label="开始日期:"
                                :prop="`${index}.startDate`"
                                :rules="formRules.required"
                            >
                                <DatePicker
                                    format="YYYY年MM月DD日"
                                    v-model="item.startDate"/>
                            </FormItem>
                            <FormItem
                                label="结束日期:"
                                :prop="`${index}.endDate`"
                            >
                                <DatePicker
                                    format="YYYY年MM月DD日"
                                    :is-so-far.sync="item.isSoFar"
                                    :value="item.isSoFar?-1:item.endDate"
                                    @input="(e)=>item.endDate = e"
                                />
                                <!--                                v-model="item.endDate"-->
                            </FormItem>
                            <FormItem
                                label="工作岗位:"
                                :prop="`${index}.profession`"
                                :rules="formRules.required"
                            >
                                <XInput v-model="item.profession"/>
                            </FormItem>
                            <FormItem
                                label="工作单位:"
                                :prop="`${index}.companyName`"
                                :rules="formRules.required"
                            >
                                <XInput v-model="item.companyName"/>
                            </FormItem>
                            <FormItem
                                label="证明人姓名:"
                                :prop="`${index}.witness`"
                                :rules="formRules.required"
                            >
                                <XInput v-model="item.witness"/>
                            </FormItem>
                            <FormItem
                                label="证明人电话:"
                                :prop="`${index}.witnessTel`"
                                :rules="formRules.required"
                            >
                                <XInput v-model="item.witnessTel"/>
                            </FormItem>
                            <div class="ctrl">
                                <XButton
                                    theme="danger"
                                    size="small"
                                    lock-text
                                    @click="()=>{onDel(item,index)}"
                                >
                                    <XIcon
                                        class="ma-rs"
                                        content="f01a"
                                    />删除
                                </XButton>
                            </div>
                        </div>
                    </XForm>
                    <div
                        v-if="!maxCount||(maxCount && formModel.length<maxCount)"
                        class="pa-a">
                        <XButton
                            label="添加经历"
                            theme="primary"
                            size="big"
                            lock-text
                            fill
                            radius="all"
                            @click="onAdd"
                        ></XButton>
                    </div>
                    <div
                        v-else-if="maxCount"
                        class="pa-a ta-c tc-minor">最多添加 {{maxCount}} 条</div>
                </Container>
                <PageBar position="bottom">
                    <XButton
                        class="item"
                        label="取消"
                        theme="second"
                        @click="()=>{onCancel()}"
                    ></XButton>
                    <XButton
                        class="item"
                        label="确认"
                        theme="main"
                        @click="()=>{onConfirm()}"
                    ></XButton>
                </PageBar>
            </Page>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
    name: 'EditWorkHistory',
    mixins: [popupMixin],
    data() {
        return {
            formModel: [],
            formRules: {
                required: {
                    required: true,
                    message: '不能为空',
                },
            },
        };
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: '添加经历',
        },
        maxCount: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapGetters('Dictionary', ['educationList', 'educationMap']),
        model: {
            get() {
                /* [
                    {
                        startDate: '', // 开始时间
                        endDate: '', // 结束时间
                        profession: '', // 职业
                        companyName: '', // 所在单位
                        witness: '', // 证明人姓名
                        witnessTel: '', // 证明人电话
                    },
                ]; */
                return this.value;
            },
            set(val) {
                const value = JSON.parse(JSON.stringify(val)).sort((a, b) => {
                    const av = dayjs((a.startDate || '').replace(/[年月日]/g, '-')).valueOf();
                    const bv = dayjs((b.startDate || '').replace(/[年月日]/g, '-')).valueOf();
                    return bv - av;
                });
                this.$emit('input', value);
            },
        },
    },
    watch: {
        popupShow: {
            handler(val, oldVal) {
                if (!val || oldVal) return;
                console.log(val);
                this.formModel = JSON.parse(JSON.stringify(this.model));
            },
            immediate: true,
        },
    },
    methods: {
        onAdd() {
            this.formModel.push({
                startDate: '', // 开始时间
                endDate: '', // 结束时间
                isSoFar: 1, // 值为:1;0
                profession: '', // 职业
                companyName: '', // 所在单位
                witness: '', // 证明人姓名
                witnessTel: '', // 证明人电话
            });
        },
        onDel(item, index) {
            this.formModel.splice(index, 1);
        },
        onCancel() {
            this.close();
        },
        async onConfirm() {
            await this.$refs.form.validate();
            this.model = this.formModel;
            return this.close();
        },
    },
};
</script>

<style lang="scss">
.edit-work-history {
    flex: 1;
    > .out-show {
        > .inner {
            > .item {
                border: 1px solid mix(#fff, $color-primary, 50);
                border-radius: $padding-small;
                padding: $padding-small;
                background-color: mix(#fff, $color-primary, 80);
                color: mix(#000, $color-primary, 50);

                + .item {
                    margin-top: $margin-small;
                }

                > .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    > .education {
                        font-weight: bold;
                    }
                }
                > .body {
                    font-size: $little * 100%;
                    margin-top: $margin-small;
                }
            }
        }
        > .ctrl {
            line-height: $formItemHeight;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .label {
                flex: 1;
            }
        }

        &.noop {
            color: $gray6;
        }
    }
}

.edit-study-history-block {
    position: relative;
    padding: 0 $padding;
    margin: $padding 0;
    > .ctrl {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        > .x-button {
            border-bottom-left-radius: $padding;
            border-bottom-right-radius: $padding;
        }
    }
}
</style>
