/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

export function applyDetail(id) {
    return get(this.url, { id });
}

export function applyList({ supplementApplyStatus, currentPage, pageSize }) {
    return get(this.url, {
        supplementApplyStatus,
        currentPage,
        pageSize,
    });
}

export function submitAudit({ supplementApplyId, memberMsg, signature }) {
    return post(this.url, {
        supplementApplyId,
        memberMsg,
        signature,
    });
}
