<!-- Created by henian.xu on 2017/10/2. -->

<template>
    <div :class="['ad-cube',{'border':border},{'reverse':reverse}]">
        <div
            :is="item.href?'Linker':'div'"
            :to="item.href"
            :class="['item',
                     {'big': data.length!==1 && data.length%2 && !(index%3)},
                     {'one': data.length === 1}]"
            v-for="(item,index) in this.data"
            :key="item.id || index"
        >
            <!--<div
                class="inner"
                :style="`background-image: url(${$options.filters.imgCropping(item.src?item.src:$defaultImg)});`"
            />-->
            <div
                class="inner"
                v-lazy:background-image="$options.filters.imgCropping(item.src?item.src:$defaultImg)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AdCube',
    data() {
        return {};
    },
    props: {
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
        noZoom: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.ad-cube {
    @include clearfix;
    background-color: #fff;
    margin-bottom: 0;

    > .item {
        float: left;
        display: inline-block;
        width: 50%;

        > .inner {
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 0;
            padding-top: 50%;
        }

        &.big {
            > .inner {
                padding-top: 100%;
            }
        }
        &.one {
            width: 100%;
            > .inner {
                padding-top: 100%;
            }
        }
    }
    &.border {
        margin-left: -1px;
        margin-right: -1px;
        > .item {
            border: 1px solid $color-border;
        }
    }
    &.reverse {
        > .item {
            float: right;
        }
    }
}
</style>
