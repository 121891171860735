<!-- Created by henian.xu on 2019/10/29. -->

<template>
    <transition-group name="footer">
        <PageBar
            v-if="type === 1"
            :key="1"
            class="footer-main">
            <Linker
                class="item"
                to="/index"
            >
                <XIcon content="f006" />
                <div class="label">
                    首页
                </div>
            </Linker>
            <!--<Linker
                class="item"
                to="/interactive">
                <XIcon content="f0f1" />
                <div class="label">
                    互动
                </div>
            </Linker>
            <Linker
                class="item"
                to="/meeting">
                <XIcon content="f0f2" />
                <div class="label">
                    面授课
                </div>
            </Linker>
            <Linker
                class="item"
                to="/forum">
                <XIcon content="f0f3" />
                <div class="label">
                    论坛
                </div>
            </Linker>
            <Linker
                class="item"
                to="/task">
                <XIcon content="f0f4" />
                <div class="label">
                    任务
                </div>
            </Linker>-->
            <Linker
                class="item"
                to="/index/user">
                <XIcon content="f003" />
                <div class="label">
                    我的
                </div>
            </Linker>
        </PageBar>
    </transition-group>
</template>

<script>
export default {
    name: 'FooterMain',
    data() {
        return {};
    },
    computed: {
        type() {
            const { meta } = this.$route;
            // if (!this.$utils.hasOwn(meta, 'footerShow')) return 1;
            return +meta.footerShow || 0;
        },
    },
};
</script>

<style lang="scss">
.footer-main {
    background-color: #fff;
    border-top: 1px solid $color-border;
    > .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $gray6;
        > .x-icon {
            font-size: 40px;
        }
        > .label {
            font-size: 16px;
            margin-top: $margin-small;
        }
        &.active {
            color: $gray8;
        }
    }
}
</style>
