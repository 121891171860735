<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <div class="address-picker">
        <div
            class="out-show"
            @click="()=>{onChoose()}"
        >
            <DeliveryAddressItem
                v-if="model.consignee"
                :data="model"
                is-select
            />
            <div
                v-else
                class="noop"
            >
                <XIcon
                    content="f015"
                    theme="primary"
                    size="super"
                />
                <div class="label">{{placeholder}}</div>
            </div>
        </div>
        <XIcon
            v-if="!disabled"
            content="f012"
            theme="g5"
            size="small"
        />
    </div>
</template>

<script>
import { formMixin } from 'packages/mixins';
import { choose } from 'pages/DeliveryAddress/store';

export default {
    name: 'AddressPicker',
    mixins: [formMixin],
    data() {
        return {};
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        placeholder: {
            type: String,
            default: '请选择...',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        async onChoose() {
            if (this.disabled_) return;
            this.model = await choose();
            console.log('onChoose', this.model);
        },
    },
};
</script>

<style lang="scss">
.address-picker {
    flex: 1;
    min-height: $formItemHeight;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .out-show {
        flex: 1;
        > .noop {
            color: $gray6;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .x-icon {
                margin-right: $margin-small;
            }
        }
    }
    > .x-icon {
        margin-left: $margin-small;
    }
}
</style>
