/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { post } from 'api/request';

/**
 * 买家访问记录
 * @param requestObjectType     1:商品详情;2:商品类目;3:平台首页
 * @param requestObjectId       对应 requestObjectType 的 id
 * @returns {AxiosPromise<any>}
 */
export function memberVisitLog({ requestObjectType, requestObjectId }, config = {}) {
    return post(
        this.url,
        {
            requestObjectType,
            requestObjectId,
        },
        {
            isHandleError: true,
            isRepeat: true,
            ...config,
        },
    );
}
