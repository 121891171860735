<!-- Created by henian.xu on 2020/4/9. -->

<template>
    <div class="cms-list">
        <List
            :margin="type_!==7"
            :border="type_===7"
            :column="type_===5?2:1"
            :padding="type_===5?'all':false"
        >
            <CmsItem
                v-for="item in listData"
                :key="item.id"
                :data="item"
                :type="type"
                :to="`/cms/detail/${item.id}`"
            />
        </List>
        <Pagination
            ref="infinite"
            :disabled="paginationDisabled"
            @load="onload"
        />
    </div>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'CmsList',
    mixins: [paginationMixin],
    data() {
        return {};
    },
    props: {
        catId: {
            type: [String, Number],
            default: '',
        },
        orderBy: {
            type: String,
            default: '',
        },
        type: {
            type: [Number, String],
            default: 1,
        },
        isWidget: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        type_() {
            return +this.type || 1;
        },
        changeListener() {
            const { orderBy, catId } = this;
            return `${orderBy}:${catId}`;
        },
    },
    watch: {
        changeListener: {
            handler() {
                this.refreshList();
            },
            immediate: true,
        },
    },
    methods: {
        async getListData(params) {
            const { catId: cmsCatalogId, orderBy } = this;
            const [orderByKey, orderByType] = orderBy.split(',');
            const res = await this.$api.Comm.Pt.Cms.cmsArticleList({
                orderByKey,
                orderByType,
                cmsCatalogId,
                ...params,
            });
            this.listData.push(...res.data);
            if (this.isWidget) this.isComplete(res);
            return res;
        },

        isComplete(result) {
            const { currentPage, totalRows, totalPages } = result.pagination;
            if (!totalRows || currentPage === totalPages) {
                this.paginationDisabled = true;
                this.$emit('complete');
            }
        },
    },
};
</script>

<style lang="scss">
</style>
