/**
 * Created by henian.xu on 2020/4/2.
 *
 */

import { get, post } from 'api/request';

export function refundApply({ orderId }) {
    return get(this.url, { orderId });
}

export function saveRefundApply({ orderId, refundReason }) {
    return post(this.url, { orderId, refundReason });
}

export function list({ refundApplyStatus, currentPage, pageSize }) {
    return get(this.url, { refundApplyStatus, currentPage, pageSize });
}

export function detail({ id }) {
    return get(this.url, { id });
}

export function cancelApply({ id }) {
    return post(this.url, { id });
}
