<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        :class="classes"
        :style="selfStyles"
    >
        <template v-if="backTop">
            <transition name="query-box">
                <div
                    class="back-top"
                    v-show="backTopShow"
                >
                    <span
                        class="inner"
                        @click="onBackTop"
                    >返回顶部</span>
                </div>
            </transition>
        </template>

        <div
            class="scrollbar beautify-scrollbar"
            ref="scrollbar"
            @scroll="onScroll"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
        >
            <div
                class="inner"
                :style="styles"
            >
                <div class="dropdown-refresh">
                    {{ refreshing?'刷新中...':hasRefresh?'松开刷新':'下拉刷新' }}
                </div>
                <!--<pre>{{ finger }}</pre>
                <pre>{{ scrollbar }}</pre>
                <pre>{{ isTop }}</pre>-->
                <div class="content">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Device, isBoolean, isFunction } from 'utils';
import { scrollMemory } from 'packages/mixins';

const defaultFingerData = JSON.stringify({
    isStarted: false,
    startY: null, // 第一次触发位置
    startTime: null, // 第一次触发时间
    lastY: null, // 最后一次触发位置
    lastTime: null, // 最后一次触发时间
    moveY: null, // 前后偏移值
    moveTime: null, // 前后所用时长
    prevY: null, // 最近一次偏移值
    prevTime: null, // 最近一次偏移用时
});
export default {
    name: 'Container',
    mixins: [scrollMemory],
    inject: { Page: { default: () => ({}) } },
    provide() {
        return { Container: this };
    },
    data() {
        return {
            scrollbar: {
                scrollTop: 0,
                direction: 0,
            },
            finger: JSON.parse(defaultFingerData),
            dropDownMax: 100,
            refreshing: false,
            backTopTimeId: 0,
        };
    },
    props: {
        backTop: {
            type: [Boolean, Number],
            default: false,
        },
        fill: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        $$scrollbar() {
            return this.$refs.scrollbar;
        },
        isDropDownRefresh() {
            return isFunction(this.$listeners.dropDownRefresh);
        },
        isTop() {
            return this.scrollbar.scrollTop <= 0;
        },
        marginTop() {
            const { finger, dropDownMax } = this;
            return Math.min(Math.max(finger.moveY, 0), dropDownMax);
        },
        hasRefresh() {
            return this.marginTop > this.dropDownMax * 0.7;
        },
        styles() {
            const { marginTop, refreshing } = this;
            return {
                marginTop: `${refreshing ? 50 : marginTop}px`,
                transition: refreshing ? 'margin-top 0.2s linear 0s' : undefined,
            };
        },
        backTop_() {
            const { backTop } = this;
            if (isBoolean(backTop) && backTop) return 0.1;
            return backTop;
        },
        threshold() {
            return this.backTop_ * Device.height;
        },
        backTopShow() {
            const {
                backTop_,
                threshold,
                scrollbar: { scrollTop, direction },
            } = this;
            if (!backTop_) return false;
            return direction < 0 && scrollTop >= threshold;
        },
        classes() {
            const { fill } = this;
            return [
                'container',
                { fill },
                // add
            ];
        },
        selfStyles() {
            const { surplusHeight } = this.Page;
            if (!surplusHeight) return {};
            return { height: `${Math.max(surplusHeight, 0)}px` };
        },
    },
    watch: {
        backTopShow(val) {
            if (val) {
                this.backTopTimeId = setTimeout(() => {
                    this.scrollbar.direction = 0;
                }, 3000);
            } else if (this.backTopTimeId) clearTimeout(this.backTopTimeId);
        },
    },
    methods: {
        onScroll() {
            if (!this.backTop) return;
            window.requestAnimationFrame(() => {
                const { $$scrollbar, scrollbar } = this;
                if (!$$scrollbar) return;
                scrollbar.direction = $$scrollbar.scrollTop - scrollbar.scrollTop > 0 ? 1 : -1;
                scrollbar.scrollTop = $$scrollbar.scrollTop;
            });
        },
        getToucheData($event) {
            const { touches, changedTouches, timeStamp } = $event;
            const fingers = [...touches, ...changedTouches];
            const touche = fingers.length ? fingers[0] : $event;
            return {
                timeStamp: timeStamp || new Date().getTime(),
                pageY: touche.pageY,
            };
        },
        onTouchStart($event) {
            const { getToucheData, finger, refreshing, isDropDownRefresh, isTop } = this;
            if (refreshing || !isDropDownRefresh || !isTop) return;
            finger.isStarted = true;
            const { pageY, timeStamp } = getToucheData($event);
            finger.startY = pageY;
            finger.startTime = timeStamp;
            // 为了 move 中好计算最近一次偏移为 lastTime 赋值开始时间
            finger.lastY = pageY;
            finger.lastTime = timeStamp;
        },
        onTouchMove($event) {
            const { getToucheData, finger } = this;
            if (!finger.isStarted) return;
            const { pageY, timeStamp } = getToucheData($event);
            finger.moveY = pageY - finger.startY;
            if (finger.moveY > 0) $event.preventDefault();
            finger.moveTime = timeStamp - finger.startTime;
            finger.prevY = pageY - finger.lastY;
            finger.prevTime = timeStamp - finger.lastTime;
            finger.lastY = pageY;
            finger.lastTime = timeStamp;
        },
        onTouchEnd(/* $event */) {
            const { finger, hasRefresh } = this;
            if (!finger.isStarted) return;
            finger.isStarted = false;
            // 下拉超过一半触更新
            if (!hasRefresh) {
                this.recovery();
                return;
            }
            this.triggerRefresh().finally(() => {
                this.recovery();
            });
        },
        // 复原数据
        recovery() {
            this.refreshing = false;
            this.finger = JSON.parse(defaultFingerData);
        },
        triggerRefresh() {
            return new Promise(resolve => {
                this.refreshing = true;
                this.$emit('dropDownRefresh', { done: resolve });
            });
        },
        onBackTop() {
            this.$$scrollbar.scrollTop = 0;
        },
    },
    created() {
        this.Page.addContainer(this);
    },
};
</script>

<style lang="scss">
.container {
    flex: 1;
    position: relative;
    z-index: $z-index-1;
    overflow: hidden;

    > .back-top {
        position: absolute;
        z-index: $z-index-1;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        padding-top: $padding-big;
        > .inner {
            display: inline-block;
            padding: $padding-small $padding-big;
            border-radius: 100vw;
            background-color: $color-main;
            color: #fff;
        }
    }

    > .scrollbar {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        > .inner {
            //height: 100%;
            position: relative;
            //transition: margin-top 0.2s linear 0s;
            > .dropdown-refresh {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 100%;
                height: 100vh;
                background-color: $gray3;
            }
            > .content {
                //height: 100%;
                padding-top: 1px;
                margin-top: -1px;
            }
        }
    }

    &.fill {
        > .scrollbar {
            > .inner {
                &,
                > .content {
                    height: 100%;
                }
                > .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: stretch;
                }
            }
        }
    }
}
</style>
