/**
 * Created by henian.xu on 2021/4/19.
 *
 */

import './index.scss';

const qualityHtml = `<div class="quality-components">
  <div class="current-quality" data-ref=""></div>
  <ul class="quality-list">
  </ul>
</div>`;

const qualityModal = `<div class="quality-modal">
  <span class="switchimg"></span> <span class="current-quality-tag"></span> , <span class="wait"></span>
</div>`;

function parseDom(html) {
    const ele = document.createElement('div');
    ele.innerHTML = html;
    return ele.childNodes[0];
}

function createEl(tag, options) {
    const el = document.createElement(tag);
    el._this = this;
    Object.entries(options).forEach(([key, value]) => {
        el[key] = value;
    });
    return el;
}

export class SetQualityComponent {
    constructor() {
        this._El = null;
        this._player = null;
        this.html = parseDom(qualityHtml);
        this.modalHtml = parseDom(qualityModal);
        this.Name = '清晰度';
        this.Type = 'quality';
        this.Tooltip = 'Tooltip';
        this.isEn = false;
        this._isMasterLevel = false;
        this._hasGeneratedList = false;
        this._autoSWitchDesc = '';
        this._previousSelection = null;
        this.currentQualityEle = null;
        this.qualityListEle = null;
    }

    generateList() {
        if (this._hasGeneratedList) return;
        let urls = this._player._urls;
        let currentPlayIndex = this._player._currentPlayIndex;
        const { levels } = this._player._qualityService;
        if (levels.length > 0) {
            this._isMasterLevel = true;
            currentPlayIndex = (urls = levels).length - 1;
        }
        const qualityListEle = this.html.querySelector('.quality-list');
        if (urls.length > 0) {
            urls.forEach((item, index) => {
                const desc = item.desc ? item.desc : item.bitrate;
                if (desc) {
                    const options = { key: item.Url, index, text: desc };
                    const liEl = createEl.call(this, 'li', options);
                    const spanEl = createEl.call(this, 'span', options);
                    if (index === currentPlayIndex) {
                        liEl.classList.add('current');
                        this._previousSelection = liEl;
                    }
                    spanEl.innerText = desc;
                    liEl.appendChild(spanEl);
                    qualityListEle.appendChild(liEl);
                }
            }, []);

            if (this._El) {
                const eleControlbar = this._El.querySelector('.prism-controlbar');
                eleControlbar.appendChild(this.html);
                this._El.appendChild(this.modalHtml);
            }
        }
        if (this._autoSWitchDesc) this._updateText(this._autoSWitchDesc);

        this._hasGeneratedList = true;
    }

    _updateText(e) {
        const currentEle = this._previousSelection;
        const currentSpanEle = this._previousSelection.querySelector(`span`);
        let autoText = this.isEn ? 'Auto' : '自动';
        if (currentSpanEle && currentSpanEle.innerText && currentSpanEle.innerText.indexOf(autoText) > -1) {
            autoText += e ? `(${e})` : '';
            currentSpanEle.innerText = autoText;
            if (currentEle) currentEle.text = autoText;
        }
    }

    bindEvent() {
        const { currentQualityEle, qualityListEle, _player, modalHtml } = this;
        currentQualityEle.onclick = e => this.onQuality(e);
        qualityListEle.onclick = e => this.onQualityList(e);
        _player.on('qualitychange', e => this.onQualityChange(e));

        _player.on('levelSwitch', () => {
            this.modalHtml.style.display = 'block';
            modalHtml.disabled = true;
        });
        _player.on('levelSwitched', () => {
            this.modalHtml.style.display = 'none';
            modalHtml.disabled = false;
        });
    }

    showTip(e, t) {
        this._player.trigger('info_show', { text: e, duration: t, align: 'lb' });
    }

    onQualityChange(event) {
        // const urls = this._player._urls.length > 0 ? this._player._urls : this._player._qualityService.levels;
        const data = event.paramData;
        if (data.levelSwitch) {
            const desc = data.desc ? data.desc : data.bitrate;
            this._autoSWitchDesc = desc;
            this._updateText(desc);
        } else if (this._player._currentPlayIndex > 0) {
            this._autoSWitchDesc = '';
            // const currentPlayIndex = this._player._currentPlayIndex;
            // const preDesc = urls[currentPlayIndex - 1].desc;
            // const currDesc = urls[currentPlayIndex].desc;
            // this.showTip(preDesc + event.paramData + currDesc, 1e3);
            // this._player.trigger('selectorValueChange', urls[currentPlayIndex].Url);
        }
    }

    onQuality(event) {
        const { qualityListEle, modalHtml } = this;
        if (!qualityListEle) return;
        qualityListEle.isShow = !qualityListEle.isShow;
        qualityListEle.style.display = qualityListEle.isShow ? 'block' : 'none';
        if (!qualityListEle.isShow || modalHtml.disabled) return;
        event.stopPropagation();
        if (!qualityListEle.onDocumentClickFn) {
            qualityListEle.onDocumentClickFn = e => {
                this.onQuality(e);
                document.removeEventListener('click', qualityListEle.onDocumentClickFn, false);
            };
        }
        document.addEventListener('click', qualityListEle.onDocumentClickFn, false);
    }

    onQualityList(event) {
        const { target } = event;
        const { qualityListEle, modalHtml } = this;
        if (!target || !target.key || modalHtml.disabled) return;
        const { key, index, text } = target;
        this._previousSelection.classList.remove('current');
        const currentLiEl = qualityListEle.querySelector(`li:nth-child(${index + 1})`);
        currentLiEl.classList.add('current');
        this._previousSelection = currentLiEl;

        this.modalHtml.querySelector('span.current-quality-tag').innerText = text;

        this.execute(key);
    }

    execute(url) {
        this._player._switchSourcing = true;
        if (this._isMasterLevel) {
            const { levels } = this._player._qualityService;
            const autoText = this.isEn ? 'Auto' : '自动';
            for (let index = 0; index < levels.length; index += 1) {
                if (levels[index].Url === url && levels[index].desc !== autoText) this._updateText('');
            }
            if (this._player._switchLevel) this._player._switchLevel(url);
        } else {
            const currentPlayIndex = this._player._currentPlayIndex;
            let s = -1;
            let currentTime;
            let playing;
            const urls = this._player._urls;
            for (let index = 0; index < urls.length; index += 1) {
                if (urls[index].Url === url) {
                    s = index;
                    this._player._currentPlayIndex = index;
                    // u.set(c.SelectedStreamLevel, this._player._urls[r].definition, 365);
                    break;
                }
            }
            if (currentPlayIndex !== s && s > -1) {
                currentTime = this._player.getCurrentTime();
                if (this._previousCurrentTime) {
                    if (this._player._status !== 'playing') currentTime = this._previousCurrentTime;
                } else {
                    this._previousCurrentTime = currentTime;
                }
                this._previousCurrentTime = currentTime;
                playing = this._player.autoplay || this._player._status !== 'pause';
                if (!this._player.autoplay && currentTime === 0) playing = false;
                if (this._player._switchLevel && !this._player._options.isLive) {
                    this._player._switchLevel(url);
                } else {
                    this._player._loadByUrlInner(url, currentTime, playing);
                }
            } else {
                this._player._loadByUrlInner(url, currentTime, playing);
            }
        }
        setTimeout(() => {
            this._player._switchSourcing = false;
        });
    }

    createEl(el, player) {
        this._player = player;
        this._El = el;
        const lang = player._options && player._options.language;
        this.isEn = lang && lang === 'en-us';
        this.html.querySelector('.current-quality').innerText = this.isEn ? 'Resolution' : '清晰度';
        this.modalHtml.querySelector('.switchimg').innerText = this.isEn ? 'Switching to you for' : '正在为您切换到';
        this.modalHtml.querySelector('.wait').innerText = this.isEn ? 'Please wait...' : '请稍后...';

        this.currentQualityEle = this.html.querySelector('.current-quality');
        this.qualityListEle = this.html.querySelector('.quality-list');

        // const eleControlbar = el.querySelector('.prism-controlbar');
        // eleControlbar.appendChild(this.html);
        // el.appendChild(this.modalHtml);
    }

    created() {
        this.bindEvent();
    }

    ready() {
        // 隐藏设置里面的清晰度
        const settingEle = document.querySelector('.prism-setting-item.prism-setting-quality');
        // 仅当没有开户设置面板时才启用本插件
        if (!settingEle) {
            // settingEle.classList.add('player-hidden');
            this.generateList();
        }
    }
    // loading() {}
    // play() {}
    // pause() {}
    // playing() {}
    // waiting() {}
    // timeupdate() {}
    // error() {}
    // ended() {}
    // dispose() {}
    // markerDotOver() {}
    // markerDotOut() {}
}
