<!-- Created by henian.xu on 2020/4/13. -->

<template>
    <div
        class="course-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <XVideo
            v-if="isVideo"
            :src="data.courseVideoUrl"
            :cover="data.courseImageUrl"
            disabled
        />
        <XImage
            v-else
            :src="data.courseImageUrl"
            :aspect-ratio="9/16"
            fit="cover"
            disabled
        />
        <div class="content">
            <div class="header">
                <div class="label">{{data.courseName}}</div>
            </div>
            <div class="footer">
                <div class="item">{{data.insertTime | moment('YYYY-MM-DD HH:mm:ss')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'CourseItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        courseType() {
            return +this.data.courseType;
        },
        isRichText() {
            return this.courseType === 1;
        },
        isImage() {
            return this.courseType === 2;
        },
        isVideo() {
            return this.courseType === 3;
        },
        isPdf() {
            return this.courseType === 4;
        },
    },
};
</script>

<style lang="scss">
.course-item {
    display: block;
    padding: $padding;
    background-color: $color-component-bgc;

    > .content {
        margin-top: $margin;

        > .header {
            > .label {
                @include text-line(2);
            }
        }
        > .footer {
            margin-top: $margin-small;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: $little * 100%;
            color: $color-text-minor;
        }
    }
}
</style>
