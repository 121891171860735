<!-- Created by henian.xu on 2020/4/1. -->
<template>
    <div class="campus-picker">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <XIcon
                content="f09a"
                class="ma-rs"
                theme="success"
            />
            <div :class="['selected',{noop:!model}]">{{modelStr||placeholder}}</div>
            <div class="label">切换</div>
            <XIcon
                class="icon"
                content="f012"
                size="small"
                theme="g5"
            />
        </div>

        <!--popup-->
        <transition name="show">
            <div
                ref="popup"
                class="popup down popup-query-bar"
                v-show="popupShow"
                @click.self="onSwitch"
            >
                <div class="inner">
                    <div class="header">
                        <XButton
                            icon="f000"
                            theme="danger"
                            link
                        />
                        <XLabel :label="label"></XLabel>
                        <XButton
                            icon="f01a"
                            theme="danger"
                            link
                            @click="onSwitch"
                        />
                    </div>
                    <div class="body">
                        <CellGroup border>
                            <Cell
                                v-for="(item,index) in campusList"
                                :key="item.id"
                                :label="item.name"
                                @click="onItem(item, index)"
                            >
                                <template
                                    #extra
                                    v-if="item.id === model"
                                >
                                    <XIcon
                                        content="f017"
                                        theme="success"
                                    />
                                </template>
                            </Cell>
                        </CellGroup>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';
import Vue from 'vue';

const state = Vue.observable({
    listData: null,
    listDataMap: {},
});

export default {
    name: 'CampusPicker',
    mixins: [popupMixin],
    data() {
        return {
            value_: '',
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        label: {
            type: String,
            default: '请选择',
        },
    },
    computed: {
        model: {
            get() {
                const { value, value_ } = this;
                if (value === null) return value_;
                return value;
            },
            set(val) {
                const { value } = this;
                if (value === null) {
                    this.value_ = val;
                }
                this.$emit('input', val);
            },
        },
        modelStr() {
            const { model } = this;
            const { listDataMap } = state;
            const campus = listDataMap[model];
            if (!campus) return '';
            return campus.name;
        },
        campusList() {
            const { listData } = state;
            if (listData === null) this.getListData();
            return listData || [];
        },
    },
    methods: {
        async getListData() {
            const { data } = await this.$api.Member.Sv.Servant.schoolOrgList();
            state.listData = data;
            state.listDataMap = data.reduce((pre, cur) => {
                if (cur.isSelect) this.model = cur.id;
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        onItem(item) {
            const { model } = this;
            if (item.id !== model) {
                this.model = item.id;
            }
            this.close().finally(() => {
                if (item.id === model) return;
                window.location.href = item.appUrl;
            });
        },
    },
};
</script>

<style lang="scss">
.campus-picker {
    background-color: $color-component-bgc;
    padding: 0 $padding;
    @include boxShadow();
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: $navHeight;
        > .icon {
            flex: 0 0 auto;
        }

        > .selected {
            flex: 1;
            &.noop {
                color: $gray6;
            }
        }
        > .icon {
            margin-left: $margin-small;
        }
    }
}
</style>
