/**
 * Created by henian.xu on 2020/4/9.
 *
 */

import { get } from 'api/request';

export function cmsCatalogList() {
    return get(this.url);
}

/**
 * cms 列表
 * @param ids               如果 ids 存在则以下参数无用
 * @param title
 * @param cmsCatalogId
 * @param orderByKey
 * @param orderByType
 * @param currentPage
 * @param pageSize
 * @returns {Promise<*>}
 */
export function cmsArticleList({ ids, title, cmsCatalogId, orderByKey, orderByType, currentPage, pageSize }) {
    return get(
        this.url,
        {
            ids,
            title,
            cmsCatalogId,
            orderByKey,
            orderByType,
            currentPage,
            pageSize,
        },
        {
            isRepeat: true,
        },
    );
}
export function cmsArticleDetail(id) {
    return get(this.url, { id });
}
export function cmsArticleCommentList({ cmsArticleId, currentPage, pageSize }) {
    return get(this.url, {
        cmsArticleId,
        currentPage,
        pageSize,
    });
}
export function cmsArticleCollectionList({ currentPage, pageSize }) {
    return get(this.url, {
        currentPage,
        pageSize,
    });
}
export function cmsArticleVisitLogList({ currentPage, pageSize }) {
    return get(this.url, {
        currentPage,
        pageSize,
    });
}
export function saveCmsArticleComment({ cmsArticleId, comments }) {
    return get(this.url, {
        cmsArticleId,
        comments,
    });
}
export function cmsArticleAgree({ cmsArticleId, cmsArticleCommentId }) {
    return get(this.url, {
        cmsArticleId,
        cmsArticleCommentId,
    });
}
export function cmsArticleCollection({ cmsArticleId }) {
    return get(this.url, {
        cmsArticleId,
    });
}
