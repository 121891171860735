/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get } from 'api/request';

export function getAdPageJson(adPageType) {
    return get(this.url, { adPageType });
}

export function getServantIndexJson(id) {
    return get(this.url, { id });
}

export function getActivityPageJson(id) {
    return get(this.url, { id });
}
