/**
 * Created by henian.xu on 2020/5/21.
 *
 */

import { isObject, loadImg, compressorImg, createObjectURL } from 'utils/index';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import { popupMixin } from 'packages/mixins';

export default {
    mixins: [popupMixin],
    data() {
        return {
            cropperInstance: null,
            editFile: null,
            editImage: null,
        };
    },
    props: {
        cropper: {
            type: [Boolean, Object],
            default: false,
        },
    },
    watch: {
        popupShow(val) {
            if (!val) this.destroyCropper();
        },
    },
    methods: {
        destroyCropper() {
            console.log('destroyCropper');
            if (!this.cropperInstance) return;
            const { editFile, filesMap } = this;
            if (editFile && editFile.id) {
                const filesItem = filesMap[editFile.id];
                if (filesItem) {
                    this.files.splice(filesItem.__index, 1);
                }
            }

            this.cropperInstance.destroy();
            this.cropperInstance = null;

            const { editImgWrap } = this.$refs;
            editImgWrap.removeChild(this.editImage);
            this.editImage = null;
            this.editFile = null;
        },
        onEditImgSave() {
            const { editFile } = this;
            const binStr = atob(
                this.cropperInstance
                    .getCroppedCanvas()
                    .toDataURL(editFile.type)
                    .split(',')[1],
            );
            const arr = new Uint8Array(binStr.length);
            for (let i = 0; i < binStr.length; i += 1) {
                arr[i] = binStr.charCodeAt(i);
            }
            const file = new Blob([arr], { type: editFile.type });
            file.name = editFile.name;
            file.lastModifiedDate = new Date();
            file.edited = true;
            file.resolve = editFile.resolve;
            file.reject = editFile.reject;
            this.$$upload.add(file);

            this.close();
        },
        onEditRawImgSave() {
            const { rowFile } = this.editFile;
            const { file } = rowFile;
            file.edited = true;
            this.$$upload.add(rowFile.file);
            this.close();
        },
        async editImg(rowFile) {
            const file = await compressorImg(rowFile.file, {
                maxWidth: 1024,
                maxHeight: 1024,
            });
            file.blob = createObjectURL(file);
            file.resolve = rowFile.file.resolve;
            file.reject = rowFile.file.reject;
            file.rowFile = rowFile;
            this.editFile = file;
            await this.open();
            this.editImage = await loadImg(file.blob);
            const { editImgWrap } = this.$refs;
            editImgWrap.append(this.editImage);
            await this.$nextTick();
            const { cropper } = this;
            let options = {
                // aspectRatio: 1,
                viewMode: 1,
                minContainerWidth: 300,
                minContainerHeight: '100%',
            };
            if (isObject(cropper)) {
                options = {
                    ...options,
                    ...cropper,
                };
            }
            this.cropperInstance = new Cropper(this.editImage, options);
        },
        onRotate(val) {
            this.cropperInstance.rotate(val * 90);
        },
    },
};
