/**
 * Created by henian.xu on 2019/10/22.
 *
 */
import { setApi } from 'api/request/apiProxy';
import * as Http from 'api/request';

import * as Comm from './Comm';
import * as Member from './Member';

export const Api = setApi({
    Comm,
    Member,
});

/* eslint-disable no-param-reassign */
export default vue => {
    vue.prototype.$http = Http;
    // api
    vue.prototype.$api = Api;
};
