<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        :class="classes"
        v-bind="linkerProps"
        v-on="$listeners"
        :style="styles"
    >
        <slot name="icon">
            <XIcon
                v-bind="iconProps"
                :badge="badge"
            />
        </slot>
        <XLabel v-bind="labelProps" />
    </div>
</template>

<script>
import { labelMixin, iconMixin, linkerMixin } from '../mixins';

export default {
    name: 'Grid',
    mixins: [labelMixin, iconMixin, linkerMixin],
    inject: ['GridGroup'],
    data() {
        return {
            appliedToLabelScopedSlots: true,
        };
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        iconTheme: {
            type: String,
            default: '',
        },
        iconSize: {
            type: String,
            default: 'more',
        },
        badge: {
            type: [String, Number],
            default: '',
        },
        column: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        column_() {
            return this.column || this.GridGroup.column;
        },
        classes() {
            const { pointer } = this;
            return ['grid', { pointer }];
        },
        __labelProps() {
            const { label, subLabel } = this;
            return { label, subLabel };
        },
        styles() {
            return {
                width: `${100 / this.column_}%`,
            };
        },
    },
};
</script>

<style lang="scss">
.grid {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $padding 0;
    // margin: -1px 0 0 -1px;
    > .x-label {
        text-align: center;
        margin-top: $margin-small;
    }

    @each $key, $value in $colorList {
        &.#{$key} {
            $color-text: $color-text;
            @if (lightness($value) <71) {
                $color-text: #fff;
            }
        }
    }
}
</style>
