<!-- Created by henian.xu on 2019/3/28. -->

<template>
    <div
        class="date"
    >
        <Pulley
            v-for="(item, index) in formatList"
            :key="index + 1"
            :data="optionsMap[item]"
            v-model="modelMap[item]"
            @input="onInput"
        >
            <!--{{ modelMap[item] }}-->
        </Pulley>
    </div>
</template>

<script>
import moment from 'dayjs';
import { isString } from 'utils';
import Pulley from '../Picker/Pulley.vue';

export default {
    name: 'Date',
    components: { Pulley },
    data() {
        return {
            innerCtrl__: false,
            modelMap: {
                YYYY: '',
                MM: '',
                DD: '',
                HH: '',
                mm: '',
                ss: '',
            },
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        format: { type: String, default: 'YYYY-MM-DD' },
        startDate: {
            type: [String, Number],
            default: +moment()
                .subtract(120, 'y')
                .valueOf(),
        },
        endDate: {
            type: [String, Number],
            default: +moment()
                .add(120, 'y')
                .valueOf(),
        },
    },

    computed: {
        startDate_() {
            return moment(this.startDate);
        },
        endDate_() {
            return moment(this.endDate);
        },
        formatList() {
            const list = this.format.match(/YYYY|MM|DD|HH|mm|ss/g);
            if (!list) return [];
            return list;
        },
        formatRegExp() {
            return new RegExp(this.format.replace(/YYYY|MM|DD|HH|mm|ss/g, substring => `\\d{${substring.length}}`));
        },
        optionsMap() {
            return this.formatList.reduce((p, curr) => {
                const pre = p;
                pre[curr] = this[curr] || [];
                return pre;
            }, {});
        },
        YYYY() {
            const { format, startDate_, endDate_ } = this;
            if (!/YYYY/.test(format)) return [];
            const startYear = startDate_.$y;
            const endYear = endDate_.$y;
            return Array(endYear - startYear + 1)
                .fill(1)
                .map((value, index) => ({ id: startYear + index, name: `${startYear + index}年` }));
        },
        MM() {
            const { format, startDate_, endDate_, modelMap } = this;
            if (!/MM/.test(format)) return [];
            let startMonth = 1;
            let endMonth = 12;
            if (modelMap.YYYY <= startDate_.$y) {
                startMonth = startDate_.$M + 1;
            }
            if (modelMap.YYYY >= endDate_.$y) {
                endMonth = endDate_.$M + 1;
            }
            const length = endMonth - startMonth;
            return Array(length + 1)
                .fill(1)
                .map((value, index) => ({ id: startMonth + index, name: `${startMonth + index}月` }));
        },
        DD() {
            const { format, modelMap } = this;
            if (!/DD/.test(format)) return [];
            const MM = modelMap.MM || '01';
            const YYYY = modelMap.YYYY || moment().format('YYYY');
            const month = `${MM}`.padStart(2, '0');
            const year = `${YYYY}`;

            const count = +moment(`${year}-${month}-01`)
                .endOf('month')
                .format('DD');
            return Array(count)
                .fill(1)
                .map((value, index) => ({ id: 1 + index, name: `${1 + index}日` }));
        },
        HH() {
            const { format } = this;
            if (!/HH/.test(format)) return [];
            return Array(24)
                .fill(1)
                .map((value, index) => ({ id: index, name: `${index}时`.padStart(2, '0') }));
        },
        mm() {
            const { format } = this;
            if (!/mm/.test(format)) return [];
            return Array(60)
                .fill(1)
                .map((value, index) => ({ id: index, name: `${index}分`.padStart(2, '0') }));
        },
        ss() {
            const { format } = this;
            if (!/mm/.test(format)) return [];
            return Array(60)
                .fill(1)
                .map((value, index) => ({ id: index, name: `${index}秒`.padStart(2, '0') }));
        },
        outShow() {
            const { format, modelMap } = this;
            return format.replace(/YYYY|MM|DD|HH|mm|ss/g, substring => `${modelMap[substring]}`.padStart(2, '0'));
        },
    },
    watch: {
        value: {
            handler() {
                if (this.innerCtrl__) {
                    this.innerCtrl__ = false;
                    return;
                }
                this.setModelMap();
            },
            immediate: true,
        },
        DD: {
            handler(val) {
                if (!val || !val.length) return;
                const { modelMap } = this;
                const { length } = val;
                if (length < +modelMap.DD) {
                    modelMap.DD = length;
                    this.onInput();
                }
            },
        },
    },

    methods: {
        onInput() {
            this.innerCtrl__ = true;
            this.$emit('input', this.outShow);
        },

        setModelMap() {
            const { value, modelMap, formatRegExp, startDate_, endDate_ } = this;
            if (value && !+value && typeof value === 'string' && !formatRegExp.test(value)) return;
            let val = moment();
            if (value) {
                let format;
                if (isString(value)) format = this.format;
                val = moment(value, format);
            }
            const timestamp = val.valueOf();
            if (timestamp < startDate_.valueOf()) val = startDate_;
            if (timestamp > endDate_.valueOf()) val = endDate_;

            this.formatList.reduce((p, curr) => {
                // const now = value ? moment(+value ? +value : value) : moment();
                const pre = p;
                pre[curr] = +val.format(curr);
                return pre;
            }, modelMap);
            this.onInput();
        },
    },
};
</script>

<style lang="scss">
.date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    /*background-color: #fff;*/
}
</style>
