/**
 * Created by henian.xu on 2020/9/28.
 *
 */

import vueUploadComponent from 'vue-upload-component';

const {
    components: {
        InputFile: { methods },
    },
} = vueUploadComponent;
const oldFileChange = methods.change;
methods.change = function change(event) {
    const { target } = event;
    const { selectFilePromise } = target;
    if (selectFilePromise) {
        const { resolve, reject } = selectFilePromise;
        const [file] = target.files;
        file.resolve = resolve;
        file.reject = reject;
    }
    oldFileChange.call(this, event);
};

function headerChange(event) {
    event.stopPropagation();
    const { target } = event;
    if (!target.isVirtualClick && target.selectFilePromise && target.selectFilePromise.reject) {
        target.selectFilePromise.reject();
    }
    target.isVirtualClick = false;
    target.selectFilePromise = null;
}
export function registeredChange(el) {
    el.addEventListener('click', headerChange);
}
export function destroyChange(el) {
    el.removeEventListener('click', headerChange);
}

export { vueUploadComponent };
