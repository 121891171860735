<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        :class="classes"
        v-bind="linkerProps"
        v-on="$listeners"
    >
        <div class="inner">
            <slot name="icon">
                <XIcon
                    class="icon"
                    v-if="hasIcon"
                    v-bind="iconProps" />
            </slot>
            <slot>
                <XLabel v-bind="labelProps" />
            </slot>
            <slot name="extra"><div class="extra">{{ extra }}</div></slot>
            <slot name="badge">
                <Badge
                    v-if="badge"
                    :content="icon"
                    :theme="theme"
                    :label="badge"
                    size="small"
                />
            </slot>
        </div>
        <XIcon
            v-if="isLink"
            class="link"
            content="f012"
            theme="g5"
            size="small"
        />
    </div>
</template>

<script>
import { labelMixin, iconMixin, linkerMixin } from '../mixins';

export default {
    name: 'Cell',
    mixins: [labelMixin, iconMixin, linkerMixin],
    data() {
        return {
            appliedToLabelScopedSlots: true,
        };
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        iconTheme: {
            type: String,
            default: '',
        },
        iconSize: {
            type: String,
            default: '',
        },
        badge: {
            type: String,
            default: '',
        },
        extra: {
            type: [String, Number],
            default: '',
        },
        link: {
            type: Boolean,
            default: null,
        },
        focusExtra: {
            type: Boolean,
            value: null,
        },
        direction: {
            type: String,
            default: '', // row;column
        },
    },
    computed: {
        classes() {
            const { theme, focusExtra, direction } = this;
            return ['cell', theme, { 'focus-extra': focusExtra }, direction];
        },
        isLink() {
            const { to, link } = this;
            return link !== null ? link : !!to;
        },
    },
};
</script>

<style lang="scss">
.cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: $color-component-bgc;
    min-height: $navHeight;

    > .inner {
        flex: 1;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .extra,
        > .badge,
        > .x-icon {
            flex: 0 0 auto;
            margin-left: $margin-small;
        }

        > .icon {
            margin-right: $margin-small;
        }
        > .x-label {
            max-width: 100%;
            flex: 1 1 1%;
            //min-height: $formItemHeight;
        }

        > .extra {
            color: $color-text-minor;
        }
    }
    > .link {
        margin-left: $margin-small;
    }

    &.focus-extra {
        > .inner {
            > .x-label {
                flex: 0 0 auto;
            }

            > .extra {
                flex: 1 1 1%;
                text-align: right;
            }
        }
    }

    &.column {
        > .inner {
            flex-direction: column;
            align-items: stretch;
            > .extra {
                margin-left: 0;
                margin-top: $margin-small;
                white-space: pre-wrap;
                word-break: break-all;
            }
        }
    }
}
</style>
