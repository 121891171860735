<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div class="page-nav">
        <div class="nav-btn-group">
            <slot name="leftBtn">
                <XIcon
                    theme="w"
                    size="big"
                    content="f011"
                    @click="$router.back()" />
            </slot>
        </div>
        <XLabel
            theme="w theme-all"
            size="big"
            :label="label"
            :sub-label="subLabel" />
        <div class="nav-btn-group">
            <slot name="rightBtn">
                <XIcon
                    theme="w"
                    size="big"
                    content="f000" />
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageNav',
    data() {
        return {};
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        subLabel: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss">
.page-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: $navHeight;
    background-color: $color-main;
    > .x-label {
        flex: 1 1 1%;
        align-items: center;
    }
    > .nav-btn-group {
        flex: 0 0 auto;
        > .x-icon {
            height: $navHeight;
            min-width: $navHeight;
        }
    }
}
</style>
