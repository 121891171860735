<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Collapse',
    data() {
        return {};
    },
    props: {
        border: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { border } = this;
            return ['collapse', { border }];
        },
    },
};
</script>

<style lang="scss">
.collapse {
    .collapse-item {
        + .collapse-item {
            margin-top: $margin;
        }
    }
    &.border {
        .collapse-item {
            + .collapse-item {
                margin-top: 0;
                border-top: 1px solid $color-border;
            }
        }
    }
}
</style>
