/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { Api } from 'api/index';

const editFormModelJson = JSON.stringify({
    id: '',
    isDefault: false,
    consignee: '1111',
    mobile: '',
    regionId: '',
    address: '',
});
export default {
    namespaced: true,
    state: {
        rawData: [],
        pagination: {},
        editFormModel: JSON.parse(editFormModelJson),
    },
    getters: {
        listData(state) {
            return state.rawData;
        },
    },
    mutations: {
        _setListData(state, { /* params, */ data }) {
            if (data && data.length) state.rawData.push(...data);
        },
        clearListData(state) {
            state.rawData = [];
        },
        setPagination(state, data) {
            state.pagination = data;
        },
        setEditFormModel(state, data) {
            state.editFormModel = data;
        },
    },
    actions: {
        async getListData({ commit }, query) {
            const params = {
                ...query,
            };
            const res = await Api.Member.Mb.DeliveryAddress.list(params);
            const {
                pagination: { currentPage },
                data,
            } = res;
            if (currentPage === 1) commit('clearListData');
            commit('_setListData', { params, data });
            return res;
        },
    },
};
