<!-- Created by henian.xu on 2020/4/27. -->

<template>
    <XForm
        v-if="formData"
        class="dynamic-form"
        ref="form"
        :model="formModel"
        :rules="formRules"
        v-bind="formData.uiProps"
        :disabled="disabled"
    >
        <template v-for="field in fieldsList">
            <FormItem
                v-if="field.isAvailable"
                :key="field.id"
                v-show="isShow(field)"
                :label="`${field.label}:`"
                :prop="field.name"
                v-bind="field.uiProps"
                :extra="field.tips"
            >
                <template
                    v-if="field.properties&&field.properties.tips"
                    #extra
                >
                    <span class="fs-little tc-minor">{{field.properties.tips}}</span>
                    <div
                        v-if="field.properties.attachments && field.properties.attachments.length"
                        class="attachment-list"
                    >
                        <span class="label">附件列表</span>
                        <a
                            class="item"
                            v-for="(item,index) in field.properties.attachments"
                            :key="index"
                            :href="item.url"
                            target="_blank"
                        >
                            <div class="label">{{item.name}}</div>
                            <XIcon
                                class="icon"
                                content="f04b"
                                theme="primary"
                                size="small"
                            ></XIcon>
                        </a>
                    </div>
                </template>
                <!--{{formModel[field.name]}}-->
                <Component
                    :is="field.componentName"
                    v-bind="field.componentProps"
                    v-model="formModel[field.name]"
                    is-full-value
                    @success="onUploadSuccess($event,field)"
                    @error="onUploadError($event,field)"
                ></Component>
            </FormItem>
        </template>
    </XForm>
</template>

<script>
import { debounce, getUniqueId, isString, isObject, hasOwn, isNumber, isObjEqual } from 'utils';
import { buildValidate, buildProps, buildValue, getComponentName } from './dynamicStore';

export default {
    name: 'DynamicForm',
    data() {
        return {
            formModel: {},
            formRules: {},
            buildForm_: null,
            buildControlRules_: null,
            fieldsList: [], // 只是为了记 vue 可观察
        };
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        initData() {
            const { data } = this;
            if (!data) return null;
            const { initDataJson } = data;
            if (!initDataJson) return null;
            try {
                if (isString(initDataJson)) return JSON.parse(initDataJson);
                if (isObject(initDataJson)) return initDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },
        formData() {
            const { data } = this;
            if (!data) return null;
            const { formDataJson } = data;
            if (!formDataJson) return null;
            try {
                if (isString(formDataJson)) return JSON.parse(formDataJson);
                if (isObject(formDataJson)) return formDataJson;
                return null;
            } catch (e) {
                return null;
            }
        },
        formAttrs() {
            // TODO
            return (this.formData || {}).attrs || {};
        },
        formControlRules() {
            const { formAttrs } = this;
            return formAttrs.rules || [];
        },
        initializedIsReadOnly() {
            const { formAttrs } = this;
            return !!formAttrs.initializedIsReadOnly;
        },
        fields() {
            const res = (this.formData || {}).fields || [];
            // 构建 formItem props
            return res.reduce((pre, cur) => {
                const { type } = cur;
                cur.componentName = getComponentName(type);
                cur.uiProps = cur.uiProps || {};
                cur.id = getUniqueId('form-item-');
                cur.componentProps = buildProps(cur);
                cur.isAvailable = true;
                if (!/signature/.test(type)) pre.push(cur);
                return pre;
            }, []);
        },
        fieldsNameMap() {
            return this.fields.reduce((pre, cur) => {
                pre[cur.name] = cur;
                return pre;
            }, {});
        },
        fieldsKeyMap() {
            return this.fields.reduce((pre, cur) => {
                if (cur.key) pre[cur.key] = cur;
                return pre;
            }, {});
        },
        fieldsKeyNameMap() {
            return this.fields.reduce((pre, cur) => {
                const { name, key } = cur;
                if (key) pre[key] = name;
                return pre;
            }, {});
        },
    },
    watch: {
        /* formData: {
            handler(val) {
                if (!val) console.error('formJson error');
            },
            immediate: true,
        }, */
        fields: {
            handler() {
                if (!this.buildForm_) {
                    this.buildForm_ = debounce(this.buildForm);
                }
                this.buildForm_();
            },
            immediate: true,
        },
        // 处理表单控制规则
        formModel: {
            handler() {
                if (!this.buildControlRules_) {
                    this.buildControlRules_ = debounce(this.buildControlRules);
                }
                this.buildControlRules_();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        buildForm() {
            const { fields, initData, initializedIsReadOnly } = this;
            if (!fields || !fields.length) return;
            const model = {};
            const rules = {};
            fields.forEach(item => {
                const { name } = item;
                model[name] = buildValue(item, initData);
                if (initializedIsReadOnly) {
                    item.uiProps.disabled = !!model[name];
                }
                // 构建校验
                const rules_ = buildValidate(item);
                if (rules_ && rules_.length) {
                    const rule = rules[name] || (rules[name] = []);
                    rule.push(...rules_);
                }
                this.fieldsList.push(item);
            });

            this.formModel = model;
            this.formRules = rules;
            this.$refs.form.resetFields();
        },
        buildControlRules() {
            const { formModel, formRules, fieldsNameMap, fieldsKeyMap, formControlRules, fieldsKeyNameMap } = this;
            formControlRules.forEach(rule => {
                const { conditions, relation, action, targetFields } = rule;
                const isAny = relation === 'all' || relation === 'or';
                const isConfirm = conditions.reduce((pre, condition) => {
                    const { operator, value } = condition;
                    const fieldName = fieldsKeyNameMap[condition.field] || condition.field;
                    const { properties } = fieldsNameMap[fieldName] || {};
                    const { optionsProps } = properties || {};
                    let model = formModel[fieldName];
                    let val = value;
                    if (isObject(model)) {
                        const props = {
                            id: 'id',
                            ...(optionsProps || {}),
                        };
                        model = model[props.id];
                        val = value[props.id];
                    }
                    if (/^eq|not$/.test(operator)) {
                        let isEq = `${model}` === `${val}`;
                        if (isObject(model)) isEq = isObjEqual(model, val);
                        if (operator === 'not') isEq = !isEq;
                        return isAny ? pre || isEq : pre && isEq;
                    }
                    // 条件中是否包含value中的值
                    if (/^in|notIn$/.test(operator)) {
                        if (isNumber(val)) val = [val];
                        let isContains = val.indexOf(model) !== -1;
                        if (operator === 'notIn') isContains = !isContains;
                        return isAny ? pre || isContains : pre && isContains;
                    }
                    // value 中是否包含条件中的值
                    if (/^contains|notContains$/.test(operator)) {
                        if (isNumber(model)) model = [model];
                        let isContains = model.indexOf(val) !== -1;
                        if (operator === 'notContains') isContains = !isContains;
                        return isAny ? pre || isContains : pre && isContains;
                    }
                    return pre;
                }, !isAny);

                switch (action) {
                    case 'show': {
                        targetFields.forEach(fieldName => {
                            const field = fieldsKeyMap[fieldName] || fieldsNameMap[fieldName];
                            if (field) {
                                field.isAvailable = isConfirm;
                                if (field.isAvailable) {
                                    // 重新构建验证规则
                                    const rules_ = buildValidate(field);
                                    formRules[field.name] = rules_ && rules_.length ? [...rules_] : null;
                                }
                            }
                        });
                        break;
                    }
                    case 'hide': {
                        targetFields.forEach(fieldName => {
                            const field = fieldsKeyMap[fieldName] || fieldsNameMap[fieldName];
                            if (field) field.isAvailable = !isConfirm;
                            if (field.isAvailable) {
                                // 重新构建验证规则
                                const rules_ = buildValidate(field);
                                formRules[field.name] = rules_ && rules_.length ? [...rules_] : null;
                            }
                        });
                        break;
                    }
                    case 'readonly': {
                        targetFields.forEach(fieldName => {
                            const field = fieldsKeyMap[fieldName] || fieldsNameMap[fieldName];
                            if (field) field.uiProps.disabled = isConfirm;
                        });
                        break;
                    }
                    default:
                        break;
                }
            });
        },
        async submit() {
            await this.$refs.form.validate();
            const { formModel, fieldsList } = this;
            let res = {};
            try {
                res = JSON.parse(JSON.stringify(formModel));
            } catch (e) {
                return Promise.reject(e);
            }
            // 重组表单数据
            return fieldsList.reduce((pre, field) => {
                const { name, isAvailable, componentName } = field;
                if (!isAvailable) {
                    // pre[name] = '';
                    return pre;
                }
                let val = res[name];
                if (componentName === 'Upload') {
                    val = val.map(item => item.address).join(',');
                }
                pre[name] = val;
                return pre;
            }, {});
        },
        isShow(field) {
            const { type } = field;
            return !/hidden|signature/.test(type);
        },

        onUploadSuccess(val, field) {
            const { componentName } = field;
            if (componentName !== 'Upload') return;
            const { formModel } = this;
            const { initData } = val;
            if (!initData) return;
            Object.entries(initData).forEach(([key, value]) => {
                if (!hasOwn(formModel, key)) return;
                console.log(key, value);
                formModel[key] = value;
            });
            // console.log(formModel);
        },
        onUploadError(val, field) {
            const { componentName } = field;
            if (componentName !== 'Upload') return;
            this.$messageBox.alert(val.message || val);
        },
    },
};
</script>

<style lang="scss">
.attachment-list {
    margin-top: $margin-small;
    > .label {
        font-size: $little * 100%;
        //color: $color-text-minor;
    }
    > .item {
        display: block;
        padding: $padding-small;
        border: 1px solid $color-border;
        border-radius: $padding-small;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: $margin-small;
        > .label {
            flex: 1;
        }
        > .icon {
            flex: 0 0 auto;
        }
    }
}
</style>
