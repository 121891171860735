<!-- Created by henian.xu on 2020/4/29. -->

<template>
    <div class="edit-signature">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <XImage
                v-if="model"
                :src="model"
                height="100px"
            ></XImage>
            <XButton
                theme="primary"
                radius="all"
            >{{model?'重新签名':'点击签名'}}</XButton>
        </div>

        <!--popup-->
        <transition :name="aniName">
            <Page
                ref="popup"
                :class="['popup popup-edit-signature',{rotate:!isScreenPortrait}]"
                v-show="popupShow"
            >
                <Container fill>
                    <!--<div class="console">
                        <div>{{winSize}}</div>
                        <div>{{isScreenPortrait}}</div>
                        <div>{{(canvas||{}).width}} , {{(canvas||{}).height}}</div>
                        <img
                            class="bor-a"
                            width="100"
                            :src="model"
                            alt="model"
                        >
                    </div>-->
                    <div v-show="false">{{signatureInstructions}}</div>
                    <Upload
                        v-show="false"
                        ref="upload"
                        action="/comm/rs/attachment/uploadFileLibrary"
                        v-model="uploadModel"
                    ></Upload>
                    <div class="edit-signature-canvas-wrap">
                        <div class="tips">请横屏签名</div>
                        <canvas
                            ref="canvas"
                            class="canvas"
                            @touchstart.prevent.stop="onTouchstart"
                            @touchmove.prevent.stop="onTouchmove"
                            @touchend.prevent.stop="onTouchend"
                        ></canvas>
                    </div>
                </Container>
                <PageBar position="bottom">
                    <XButton
                        class="item"
                        label="取消"
                        theme="second"
                        @click="()=>{onCancel()}"
                    ></XButton>
                    <XButton
                        class="item"
                        label="重写"
                        theme="primary"
                        @click="()=>{onResetCanvas()}"
                    ></XButton>
                    <XButton
                        class="item"
                        label="确认"
                        theme="main"
                        @click="()=>{onConfirm()}"
                    ></XButton>
                </PageBar>
            </Page>
        </transition>
    </div>
</template>

<script>
import { popupMixin } from 'packages/mixins';
import { Device, GlobalVar } from 'utils';
import { mapGetters } from 'vuex';

export default {
    name: 'EditSignature',
    mixins: [popupMixin],
    data() {
        return {
            isMounted: false,
            uploadModel: [],
            rotateCanvas: null,
        };
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '#000',
        },
        background: {
            type: String,
            default: 'rgba(0, 255, 255, 0)',
        },
        linewidth: {
            type: String,
            default: '8',
        },
        // 是否为批量签名
        isBatch: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('PortalInfo', ['signatureInstructions', 'batchSignatureInstructions']),
        model: {
            get() {
                // return 'http://192.168.1.152:4333/upload/fileLibrary/2020/04/29/ef417dac946a48a9857f275b1568013f.png';
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        canvas() {
            if (!this.isMounted) return null;
            return this.$refs.canvas;
        },
        cxt() {
            if (!this.canvas) return null;
            return this.canvas.getContext('2d');
        },
        isScreenPortrait() {
            return Device.isScreenPortrait;
        },
        winSize() {
            const { width, height } = Device;
            return { width, height };
        },
        instructions() {
            const { isBatch, batchSignatureInstructions, signatureInstructions } = this;
            return isBatch ? batchSignatureInstructions : signatureInstructions;
        },
    },
    watch: {
        cxt: {
            handler(val) {
                if (!val) return;
                this.initCtx();
            },
            immediate: true,
        },
        isScreenPortrait() {
            this.$nextTick(() => {
                this.initCtx();
            });
        },
        popupShow(val) {
            if (!val) return;
            if (this.instructions && !GlobalVar.isDev) {
                const xImage = this.$createElement('XImage', {
                    props: {
                        src: this.instructions,
                        cut: 'none',
                    },
                });
                this.$messageBox.alert(xImage, '签名规范须知');
            }
            this.$nextTick(() => {
                this.initCtx();
            });
        },
    },
    methods: {
        initCtx() {
            this.canvas.width = this.canvas.clientWidth;
            this.canvas.height = this.canvas.clientHeight;
            this.cxt.fillStyle = this.background;
            this.cxt.fillRect(0, 0, this.canvas.width, this.canvas.height);
            // console.log(this.canvas.width, this.canvas.height);
            this.cxt.strokeStyle = this.color;
            this.cxt.lineWidth = this.linewidth;
            this.cxt.lineCap = 'round';
        },
        getTouchePoint($event) {
            const { touches, changedTouches } = $event;
            // eslint-disable-next-line no-nested-ternary
            const touche = touches ? (touches.length ? touches[0] : changedTouches[0]) : $event;
            return {
                x: touche.pageX,
                y: touche.pageY,
            };
        },
        onTouchstart($event) {
            const point = this.getTouchePoint($event);
            this.cxt.beginPath();
            if (this.isScreenPortrait) {
                this.cxt.moveTo(point.x, point.y);
            } else {
                this.cxt.moveTo(this.canvas.width - point.y, point.x);
            }
        },
        onTouchmove($event) {
            const point = this.getTouchePoint($event);
            if (this.isScreenPortrait) {
                this.cxt.lineTo(point.x, point.y);
            } else {
                this.cxt.lineTo(this.canvas.width - point.y, point.x);
            }
            this.cxt.stroke();
        },
        onTouchend() {
            this.cxt.closePath();
        },
        onResetCanvas() {
            this.cxt.clearRect(0, 0, this.canvas.width, this.canvas.height);
        },
        onCancel() {
            this.close();
        },
        /* rotateText() {
            switch (num) {
                case 1:
                    oc.width = obj.height;
                    oc.height = obj.width;
                    pc.rotate((90 * Math.PI) / 180);
                    pc.drawImage(obj, 0, -obj.height);
                    break;
                case 2:
                    oc.width = obj.width;
                    oc.height = obj.height;
                    pc.rotate((180 * Math.PI) / 180);
                    pc.drawImage(obj, -obj.width, -obj.height);
                    break;
                case 3:
                    oc.width = obj.height;
                    oc.height = obj.width;
                    pc.rotate((270 * Math.PI) / 180);
                    pc.drawImage(obj, -obj.width, 0);
                    break;
                case 0:
                    oc.width = obj.width;
                    oc.height = obj.height;
                    pc.rotate((360 * Math.PI) / 180);
                    pc.drawImage(obj, 0, 0);
                    break;
            }
        }, */
        async rotateImg(src) {
            if (!this.rotateCanvas) {
                this.rotateCanvas = document.createElement('canvas');
            }
            const { rotateCanvas, canvas } = this;
            rotateCanvas.width = canvas.height;
            rotateCanvas.height = canvas.width;
            const cxt = rotateCanvas.getContext('2d');
            cxt.clearRect(0, 0, rotateCanvas.width, rotateCanvas.height);
            const img = await this.$utils.loadImg(src);
            cxt.rotate((270 * Math.PI) / 180);
            cxt.fillRect(0, 0, img.width, img.height);
            cxt.drawImage(img, -img.width, 0);
            return rotateCanvas.toDataURL();
        },
        async onConfirm() {
            const closeLoading = this.$messageBox.loading();
            const base64 = await this.rotateImg(this.canvas.toDataURL());
            const file = this.$utils.dataURLtoBlob(base64, `${this.$utils.getUniqueId('signature-')}.png`);
            // const url = this.$utils.createObjectURL(file);
            try {
                const data = await this.$refs.upload.addFile(file);
                this.model = data.address;
                this.$refs.upload.clear();
                this.close();
            } catch (e) {
                this.$messageBox.alert(e.message || '签名失败');
            }
            closeLoading();
        },
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.edit-signature {
    flex: 1;
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        > .x-image {
            margin-right: $margin-small;
        }
    }
    &-canvas-wrap {
        background-color: #fff;
        width: 100%;
        height: 100%;
        > .tips {
            position: absolute;
            z-index: $z-index-1;
            top: 50%;
            left: 10%;
            white-space: nowrap;
            transform: translate(-50%, -50%) rotate(90deg);
            font-weight: bold;
            font-size: 400%;
            letter-spacing: $padding-big;
            color: $gray2;
        }
        > .canvas {
            position: relative;
            z-index: $z-index-2;
            width: 100%;
            height: 100%;
        }
    }
}
.popup.popup-edit-signature {
    z-index: $z-index-7 + 10;
    &.rotate {
        width: 100vh;
        height: 100vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    //transform:;
    //transform-origin: 0% 0%;
}
</style>
