<!-- Created by henian.xu on 2018/2/2. -->

<template>
    <transition name="bounce">
        <div
            :class="['popup message-box', { tips: $type === 'tips' }, { loading: $type === 'loading' }]"
            v-show="show"
            @touchmove.stop="onTouchmove"
            @click.self="onPopup('cancel')"
        >
            <div
                class="inner"
                v-if="$type === 'tips' || $type === 'loading'">
                <div class="body">
                    <XImage
                        v-if="$type === 'loading'"
                        class="loading-img"
                        :src="$loadingIcon"
                    ></XImage>
                    <div
                        v-if="message"
                        class="label"
                    >{{ message }}</div>
                </div>
            </div>
            <div
                v-else
                class="inner"
            >
                <div class="header">
                    <div class="inner">
                        <slot name="header">
                            <template v-if="title">{{title}}</template>
                            <!--<XIcon
                                v-else
                                class="ma-tb"
                                content="f0de"
                                size="0.6rem"></XIcon>-->
                        </slot>
                    </div>
                </div>
                <div :class="['body', { flex: $type === 'alert' || $type === 'confirm' || $type === 'prompt' }]">
                    <div class="inner">
                        <slot>
                            <div
                                class="message"
                                v-html="message" />
                        </slot>

                        <XInput
                            v-if="showInput"
                            v-model="inputValue"
                            :placeholder="placeholder"
                            :min-rows="inputAutoSize.minRows"
                            :max-rows="inputAutoSize.maxRows"
                            :maxlength="inputMaxlength"
                        />
                        <div
                            class="error"
                            v-if="errorText"
                            v-html="errorText" />
                    </div>

                    <!--<div
                        v-if="!showCloseBtn"
                        class="ctrl">
                        <XButton
                            v-if="showCancelBtn"
                            theme="g8"
                            radius="all"
                            plain
                            @click="handleAction('cancel')"
                            @keydown.enter="handleAction('cancel')"
                        >
                            <template v-slot>
                                {{ cancelBtnText }}
                            </template>
                        </XButton>
                        <XButton
                            v-if="showConfirmBtn"
                            theme="g8"
                            radius="all"
                            :disabled="!!delayConfirm"
                            @click="handleAction('confirm')"
                            @keydown.enter="handleAction('confirm')"
                        >
                            <template v-slot>
                                {{ confirmBtnText }}{{ delayConfirm > 0 ? `(${delayConfirm})` : '' }}
                            </template>
                        </XButton>
                    </div>-->
                </div>
                <div class="footer">
                    <XButton
                        v-if="showCancelBtn"
                        theme="g4"
                        @click="handleAction('cancel')"
                        @keydown.enter="handleAction('cancel')"
                    >
                        <template v-slot>
                            {{ cancelBtnText }}
                        </template>
                    </XButton>
                    <XButton
                        v-if="showConfirmBtn"
                        :theme="delayConfirm>0?'g4':'main'"
                        @click="handleAction('confirm')"
                        @keydown.enter="handleAction('confirm')"
                    >
                        <template v-slot>
                            {{ confirmBtnText }}{{ delayConfirm > 0 ? `(${delayConfirm})` : '' }}
                        </template>
                    </XButton>
                </div>
                <div
                    v-if="showCloseBtn"
                    class="close-btn"
                    @click="handleAction('close')">
                    <XIcon content="f018" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'MessageBox',
    data() {
        return {
            show: false,
            showCancelBtn: false,
            cancelBtnText: '取消',
            showConfirmBtn: true,
            confirmBtnText: '确认',

            errorText: '',

            showInput: false,
            inputValue: '',
            inputMaxlength: null,
            placeholder: '请输入',
            inputAutoSize: { minRows: 1, maxRows: 4 },

            closeTime: 2000,
            closeTimer: 0,

            delayConfirm: 0,
            delayConfirmTimer: 0,

            isClickOtherClose: true,
            validCallback: null,

            action: '',
            showCloseBtn: false,
            callback: null,
            message: null,
            title: null,
        };
    },
    watch: {
        show(val) {
            if (this.$type === 'tips') {
                if (val) {
                    this.closeTimer = setTimeout(() => {
                        this.action = 'close';
                        this.doClose();
                    }, this.closeTime);
                } else {
                    clearTimeout(this.closeTimer);
                    this.closeTimer = 0;
                }
            } else if (this.delayConfirm > 0) {
                if (val) {
                    this.delayConfirmTimer = setInterval(() => {
                        this.delayConfirm -= 1;
                        if (this.delayConfirm <= 0) {
                            clearInterval(this.delayConfirmTimer);
                            this.delayConfirmTimer = 0;
                        }
                    }, 1000);
                } else {
                    clearInterval(this.delayConfirmTimer);
                    this.delayConfirmTimer = 0;
                }
            }
        },
    },
    methods: {
        onPopup(action) {
            if (!this.isClickOtherClose) return;
            this.handleAction(action);
        },
        validateAction(action) {
            this.errorText = '';
            return new Promise((resolve, reject) => {
                if (!this.validCallback) return resolve(true);
                // this.$nprogress.start();
                return this.validCallback(action, this.inputValue)
                    .then(val => {
                        // this.$nprogress.done();
                        return resolve(val);
                    })
                    .catch(error => {
                        // this.$nprogress.done();
                        this.errorText = error.message || '';
                        return reject(error);
                    });
            });
        },
        handleAction(action) {
            this.validateAction(action)
                .then(() => {
                    if (action === 'confirm' && this.delayConfirm > 0) return;
                    this.action = action;
                    this.doClose();
                })
                .catch(() => {
                    // 只为消除控制台报错
                });
        },
        doClose() {
            this.show = false;
            this.callback(this.action, this);
        },
        onTouchmove() {
            // 只为了阻止事件存在
        },
    },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
    // 只留了做弹框挂载用
    > .popup,
    > .page {
        position: absolute;
        z-index: $z-index-8;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > .popup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(35, 24, 21, 0.8);

        /*> .inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: #fff;

            > .header,
            > .footer {
                min-height: 100px;
                flex: 0 0 auto;
            }

            > .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                line-height: 100px;
                border-bottom: 1px solid $color-border;
                color: $color-main;
                font-weight: bold;
                font-size: 32px;

                > .label {
                    flex: 1 1 1%;
                    text-align: center;
                    //color: $color-main;
                }
                > .button {
                    padding-top: 0;
                    padding-bottom: 0;
                    flex: 0 0 100px;
                    border: none;
                }
            }

            > .footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                > .button {
                    height: 100px;
                    flex: 1 1 1%;
                }
            }
            > .body {
                flex: 1 1 1%;
                overflow-x: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                padding: $padding;
            }
        }*/
    }
}

.popup.message-box {
    > .inner {
        position: relative;
        width: 571px;
        min-height: 495px;
        max-height: 80%;
        background-color: transparent;
        border-radius: $padding-small;
        overflow: hidden;

        > .header {
            position: relative;
            min-height: $navHeight;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background-color: $color-component-bgc;

            > .inner {
                position: relative;
            }
        }
        > .body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: $color-component-bgc;

            > .inner {
                flex: 1;
                > .x-textarea,
                > .x-input {
                    flex: 0 0 auto;
                    background-color: #fff;
                    border-radius: 100vw;
                    border: 1px solid $gray8;
                    color: $color-text;
                    padding: 0 $padding-small;
                    text-align: left;
                    margin-top: $margin;
                }
                > .error {
                    margin-top: $margin;
                    color: $color-danger;
                }
            }
            > .ctrl {
                flex: 0 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding: $padding;
                > .x-button {
                    min-width: 6em;
                }
            }
        }

        > .body.flex {
            > .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                text-align: center;
                padding: $padding;
            }
        }
        > .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            position: absolute;
            left: 50%;
            bottom: -80px;

            margin-left: -25px;
            width: 50px;
            height: 50px;
            font-size: 26px;
            color: #fff;
            background-color: rgba(255, 255, 255, 0.5);
            border: 1px solid $color-border;
            border-radius: 100vw;
        }

        > .footer {
            //border-top: 1px solid $color-border;
            > .button {
                padding-top: 0;
                padding-bottom: 0;
                border: none;
            }
        }
    }

    &.tips {
        pointer-events: none; // 鼠标可以穿透
        background-color: rgba(0, 0, 0, 0);
        > .inner {
            width: auto;
            min-width: 50vw;
            max-width: 70vw;
            min-height: auto;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            > .body {
                text-align: center;
                background-color: transparent;
            }
        }
    }
    &.loading {
        //pointer-events: none; // 鼠标可以穿透
        background-color: rgba(0, 0, 0, 0);
        > .inner {
            width: auto;
            min-width: 30vw;
            max-width: 70vw;
            min-height: 30vw;
            background-color: rgba(0, 0, 0, 0.7);
            color: #fff;
            > .body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: $padding-big;
                > .loading-img {
                    width: 0.8rem;
                    margin: 0 auto;
                }
                > .label {
                    margin-top: $margin-big;
                }
                text-align: center;
                background-color: transparent;
            }
        }
    }
}
</style>
