/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get } from 'api/request';

/**
 * 商品列表
 * @param ids                   当选择/装修时用
 * @param orderByKey
 * @param orderByType
 * @param goodsName
 * @param certCatId
 * @param servantGoodsCatId
 * @param pageSize
 * @param currentPage
 * @returns {Promise<*>}
 */
export function goodsList({
    ids,
    orderByKey,
    orderByType,
    goodsName,
    certCatId,
    servantGoodsCatId,
    pageSize,
    currentPage,
}) {
    return get(
        this.url,
        {
            ids,
            orderByKey,
            orderByType,
            goodsName,
            certCatId,
            servantGoodsCatId,
            pageSize,
            currentPage,
        },
        {
            isRepeat: true,
        },
    );
}
export function goodsDetail(id) {
    return get(this.url, { id });
}

export function servantGoodsCatList() {
    return get(this.url, null, {
        isRepeat: true,
    });
}

export function searchSku({ goodsType, certCatId, certExamType }) {
    return get(this.url, {
        goodsType,
        certCatId,
        certExamType,
    });
}
