/**
 * Created by henian.xu on 2020/5/7.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';

export const state = Vue.observable({
    rawData: {},
});

export async function getMockExamPaper(certExamApplyId) {
    const { data } = await Api.Member.Ce.CertExamPaper.mockExamPaperInfo({
        certExamApplyId,
    });
    Vue.prototype.$set(state.rawData, certExamApplyId, data);
}

export default {
    computed: {
        mockExamPaperDetail() {
            const { certExamApplyId } = this;
            const detail = state.rawData[certExamApplyId];
            if (!detail) {
                getMockExamPaper(certExamApplyId);
                return {};
            }
            return detail;
        },
        certExam() {
            const { mockExamPaperDetail } = this;
            return mockExamPaperDetail.certExam || {};
        },
        paperStat() {
            const { mockExamPaperDetail } = this;
            return mockExamPaperDetail.paperStat || {};
        },
        certExamApplyPaperId() {
            const { mockExamPaperDetail } = this;
            return mockExamPaperDetail.certExamApplyPaperId;
        },
        certCatNameAndTypePath() {
            const { certCatNamePath, certExamTypeName } = this.certExam;
            if (!certCatNamePath) return '';
            return `${certCatNamePath}>${certExamTypeName}`;
        },
    },
};
