/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

export function certExamCourseList({ certExamApplyId, certExamCourseId }) {
    return get(this.url, {
        certExamApplyId,
        certExamCourseId,
    });
}
export function certExamCourseDetail({ certExamApplyId, certExamCourseDetailId }, cfg) {
    return get(
        this.url,
        {
            certExamApplyId,
            certExamCourseDetailId,
        },
        {
            ...cfg,
        },
    );
}
export function courseLearnHeartbeat({ certExamApplyId, certExamCourseDetailId }) {
    return get(
        this.url,
        {
            certExamApplyId,
            certExamCourseDetailId,
        },
        {
            isHandleError: true,
        },
    );
}
export function courseProgressConfig({ certExamApplyId, certExamCourseDetailId, progressConfig }) {
    return post(
        this.url,
        {
            certExamApplyId,
            certExamCourseDetailId,
            progressConfig,
        },
        {
            isHandleError: true,
        },
    );
}

// 认证接口
export function recognition({ file, certExamApplyId, certExamCourseDetailId }) {
    return post(this.url, {
        file,
        certExamApplyId,
        certExamCourseDetailId,
    });
}

// 获取随机题目
export function getRandomQuestion({ certExamApplyId, certExamCourseDetailId }) {
    return get(this.url, {
        certExamApplyId,
        certExamCourseDetailId,
    });
}

//  保存答题
export function questionRecognition({ logId, selectOption }) {
    return post(
        this.url,
        {
            logId,
            selectOption,
        },
        {
            isHandleError: true,
        },
    );
}
