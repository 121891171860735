<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <div class="cert-cat">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <div class="label">选择证考</div>
            <div class="extra">{{modelStr||placeholder}}</div>
            <XIcon
                content="f012"
                size="small"
                theme="g5"
            />
        </div>

        <!--popup-->
        <transition :name="aniName">
            <div
                ref="popup"
                class="popup"
                v-show="popupShow"
            >
                <Page>
                    <PageBar>
                        <XIcon
                            class="pa-a"
                            content="f011"
                            @click="close"
                        />
                        <XLabel>选择证考</XLabel>
                        <XIcon
                            class="pa-a"
                            content="f000"
                        />
                    </PageBar>
                    <Container class="pa-a">
                        <CertCatPicker
                            ref="certCatPicker"
                            :p-id="pId"
                            @last-item="onLastItem"
                            v-model="model_"
                        ></CertCatPicker>
                    </Container>
                    <PageBar
                        position="bottom"
                        class="pa-l pa-r"
                    >
                        <XButton
                            label="重置"
                            theme="second"
                            radius="all"
                            @click="onReset"
                        ></XButton>
                        <XButton
                            label="确认"
                            theme="success"
                            radius="all"
                            :disabled="disabledConfirm"
                            @click="onConfirm"
                        ></XButton>
                    </PageBar>
                </Page>
            </div>
        </transition>
    </div>
</template>

<script>
import { popupMixin, refMixin } from 'packages/mixins';

export default {
    name: 'CertCat',
    mixins: [popupMixin, refMixin],
    data() {
        return {
            model_: '',
            modelStr: '',
            isLastItem: false,
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        pId: {
            type: String,
            default: '',
        },
        chooseLastItem: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        disabledConfirm() {
            const { chooseLastItem, isLastItem } = this;
            if (!chooseLastItem) return false;
            return !isLastItem;
        },
    },
    methods: {
        async onReset() {
            const certCatPicker = await this.getRefsChildren('certCatPicker');
            certCatPicker.onReset();
        },
        async onConfirm() {
            const certCatPicker = await this.getRefsChildren('certCatPicker');
            this.modelStr = certCatPicker.getItemName();
            this.model = this.model_;
            this.close();
        },
        onLastItem(val) {
            console.log('onLastItem', val);
            this.isLastItem = !!val;
        },
    },
};
</script>

<style lang="scss">
.cert-cat {
    flex: 1;
    padding: 0 $padding;
    > .out-show {
        min-height: $navHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .label {
            flex: 0 0 auto;
        }
        > .extra {
            flex: 1;
            margin-right: $margin-small;
            text-align: right;
        }
    }
}
</style>
