/**
 * Created by henian.xu on 2020/4/11.
 *
 */

const _localStorage = window.localStorage;
const _sessionStorage = window.sessionStorage;
function getStore(local = true) {
    return local ? _localStorage : _sessionStorage;
}
function set(key, value, local = true) {
    try {
        value = JSON.stringify(value);
    } catch (e) {
        console.error(e);
    }
    getStore(local).setItem(key, value);
}
function get(key, local = true) {
    let value = getStore(local).getItem(key);
    if (value !== null) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            console.error(e);
        }
    }
    return value;
}
function remove(key, local = true) {
    getStore(local).removeItem(key);
}
const localStorage = {
    set: (key, value) => set(key, value),
    get: key => get(key),
    remove: key => remove(key),
};
const sessionStorage = {
    set: (key, value) => set(key, value, false),
    get: key => get(key, false),
    remove: key => remove(key, false),
};
export { localStorage, sessionStorage };
