/**
 * Created by henian.xu on 2020/4/8.
 *
 */

import Vue from 'vue';
import { Api } from 'api/index';
import { isDef } from 'utils/index';

export const state = Vue.observable({
    childrenCache: {},
    itemIdMap: {},
});

function buildMap(list) {
    list.reduce((pre, cur) => {
        pre[cur.id] = cur;
        return pre;
    }, state.itemIdMap);
}

export async function getChild(query = {}) {
    const { pId = '' } = query;
    const { childrenCache } = state;
    const cache = childrenCache[pId];
    if (isDef(cache)) return cache;
    const { data } = await Api.Comm.Rs.Region.getChild(query);
    buildMap(data);
    childrenCache[pId] = data;
    return data;
}

export async function loadAllArea(query = {}) {
    let { pId = '' } = query;
    const { childrenCache } = state;
    const { data } = await Api.Comm.Rs.Region.loadAllArea(query);
    const res = [];
    data.forEach(list => {
        childrenCache[pId] = list;
        buildMap(list);
        list.some(item => {
            if (item.isSelect) {
                pId = item.id;
                res.push(pId);
            }
            return item.isSelect;
        });
    });
    return res;
}
