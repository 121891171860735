<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="order-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div
            v-if="!onlyShow"
            class="header">
            <XIcon
                content="f08a"
                theme="main"
            />
            <XLabel>{{orderDetail.certExamTypeName}}
                <template v-if="orderType===2">-补考</template>
            </XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl v-if="onlyShow">
                    <dt>订 单 号:</dt>
                    <dd>{{data.orderSn}}</dd>
                </dl>
                <dl>
                    <dt>学　　校:</dt>
                    <dd>{{data.schoolOrgName}}</dd>
                </dl>
                <dl>
                    <dt>付款时间:</dt>
                    <dd>{{data.paymentTime || data.orderTime | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
                </dl>
                <dl>
                    <dt>订单实付:</dt>
                    <dd class="price">{{data.payableAmount}}</dd>
                </dl>
                <dl>
                    <dt>证　　考:</dt>
                    <dd>【{{specDesc}}】{{orderDetail.certCatPathName}}</dd>
                </dl>
            </div>
            <XIcon
                v-if="!onlyShow"
                content="f012"
                size="small"
                theme="g5"
            />
        </div>
        <div
            v-if="!onlyShow && data.orderStatus!==99"
            class="footer"
        >
            <!--            <XButton
                v-if="data.isCanRefund"
                label="申请退款"
                theme="danger"
                link
                @click.stop.prevent="()=>{onRefund()}"
            />-->
            <XButton
                v-if="data.isCanRefundApply"
                label="申请退款"
                theme="danger"
                link
                :to="`/refund/apply?orderId=${data.id}`"
            />
            <XButton
                v-if="data.isCanInvoice"
                :label="data.invoiceStatusName"
                theme="main"
                link
                @click.stop.prevent="onAddInvoice"
            />
            <XButton
                v-else-if="invoiceStatus"
                label="我的发票"
                theme="main"
                link
                :to="`/invoice/detail/${data.id}`"
            />
            <!--@click.prevent.stop="onBuild"-->
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'OrderItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        onlyShow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        orderDetailList() {
            return this.data.orderDetailList || [];
        },
        orderType() {
            return +this.data.orderType || 1;
        },
        orderDetail() {
            return this.orderDetailList[0] || {};
        },
        invoiceStatus() {
            return this.data.invoiceStatus;
        },
        specDesc() {
            return (this.orderDetail.specDesc || '').split(':').pop();
        },
    },
    methods: {
        onBuild() {
            this.$messageBox.alert('功能开发中，敬请期待。');
        },
        async onRefund() {
            await this.$messageBox.confirm('退款后，将取消对应证考报名资格。确定退款？');
            const { data } = this;
            const { msg } = await this.$api.Member.Ex.Order.orderRefundApply(data.id);
            if (msg) this.$messageBox.tips(msg);
            this.$emit('change', null);
        },
        onAddInvoice() {
            const { userInfo } = this;
            if (!userInfo.isAllowOrderInvoice) {
                this.$messageBox.alert(userInfo.orderInvoiceTips);
                return;
            }
            // this.$router.push(`/invoice/add/${data.id}`);
            this.onCreateOrderInvoice();
        },
        async onCreateOrderInvoice() {
            const { data } = await this.$api.Member.Ex.OrderInvoice.createOrderInvoice({
                orderId: this.data.id,
                invoiceType: this.$route.query.invoiceType || '',
            });
            if (!this.$route.query.invoiceType) {
                await this.$messageBox.alert(
                    '线上平台仅能开具电子普通发票，如需开具纸质发票，需前往学校进行现场开票。',
                );
            }
            if (!data) return;
            window.location.href = data;
        },
    },
};
</script>

<style lang="scss">
.order-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
</style>
