/**
 * Created by henian.xu on 2020/5/26.
 *
 */
import { get, post } from 'api/request';

export function cooperationList({ cooperationStatus, currentPage, pageSize }) {
    return get(this.url, { cooperationStatus, currentPage, pageSize });
}
export function cooperationDetail(id) {
    return get(this.url, { id });
}
export function cooperationApplyList({ cooperationId, currentPage, pageSize }) {
    return get(`${this.baseUrl}/${cooperationId ? 'cooperationApplyList' : 'enterpriseMemberList'}`, {
        cooperationId,
        currentPage,
        pageSize,
    });
}
export function discountCodeList({ codeStatus, currentPage, pageSize }) {
    return get(this.url, { codeStatus, currentPage, pageSize });
}

export function enterpriseDetail() {
    return get(this.url);
}
export function applyMaterialsDetail({ applyMaterialsId }) {
    return get(this.url, { applyMaterialsId });
}
export function saveApplyMaterials({ applyMaterialsId, applyMaterialsValue }) {
    return post(this.url, { applyMaterialsId, applyMaterialsValue });
}
export function submitAudit() {
    return post(this.url);
}

export function uploadExcel() {
    return post(this.url);
}
export function saveEnterpriseCooperation({ enterpriseCooperationName, enterpriseRemarks, cooperationMemberListJson }) {
    return post(
        this.url,
        {
            enterpriseCooperationName,
            enterpriseRemarks,
            cooperationMemberListJson,
        },
        {
            isLoading: true,
        },
    );
}
export function cooperationMemberList({ fullName, enterpriseCooperationId, currentPage, pageSize }) {
    return get(this.url, { fullName, enterpriseCooperationId, currentPage, pageSize });
}
export function cooperationMemberDetail({ id }) {
    return get(this.url, { id });
}
export function verticalGroupingDetail({ verticalGroupingId, certExamApplyId }) {
    return get(this.url, { verticalGroupingId, certExamApplyId });
}
export function saveEnterpriseInfo({ enterpriseName, principalName, principalTel, regionId, address }) {
    return post(this.url, {
        enterpriseName,
        principalName,
        principalTel,
        regionId,
        address,
    });
}

export function enterprisePracticeMaterialsList({ currentPage, pageSize }) {
    return get(this.url, { currentPage, pageSize });
}

export function cancelCooperationMember({ id }) {
    return post(this.url, { id });
}

export function saveEnterpriseBillInfo({
    billCompanyName, // 公司名称
    billCompanyCode, // 组织架构代码
    billCompanyAddress, // 公司地址
    billCompanyTel, // 公司电话
    billBankAccount, // 账户号码
    billBankName, // 开户银行
}) {
    return post(this.url, {
        billCompanyName,
        billCompanyCode,
        billCompanyAddress,
        billCompanyTel,
        billBankAccount,
        billBankName,
    });
}
