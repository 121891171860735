<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PageBar',
    data() {
        return {};
    },
    props: {
        position: {
            type: String,
            default: 'top', // top;bottom
        },
        direction: {
            type: String,
            default: '', // row;column
        },
    },
    computed: {
        classes() {
            const { position, direction } = this;
            return [
                'page-bar',
                position,
                direction,
                // add
            ];
        },
    },
};
</script>

<style lang="scss">
.page-bar {
    position: relative;
    z-index: $z-index-3;
    flex: 0 0 auto;
    min-height: $navHeight;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    //border-top: 1px solid $color-border;

    > .item {
        flex: 1 1 1%;
        align-self: stretch;
    }
    > .stretch {
        align-self: stretch;
    }
    > .x-button {
        min-width: 160px;
        //height: 100%;
    }
    > .footer-button-group {
        flex: 1 1 1%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: stretch;
    }

    &.column {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

    &.top {
        //box-shadow: 0 10px 10px -5px $gray4;
        border-bottom: 1px solid $color-border;
    }
    &.bottom {
        //box-shadow: 0 -10px 10px -5px $gray4;
        border-top: 1px solid $color-border;
    }
}
</style>
