<!-- Created by henian.xu on 2019/7/4. -->

<template>
    <div class="quick-navigation">
        <div
            ref="inner"
            :class="['inner',{open:isOpen,move:isMove}]"
            :style="innerStyle"
        >
            <div
                class="header"
                @touchstart="onTouchstart"
                @touchend="onTouchend"
                @mousedown="onTouchstart"
            >
                快速<br>导航
                <!--<em
                    v-if="unreadCount"
                    class="badge badge-red"
                >{{ unreadCount }}</em>-->
            </div>
            <div class="body">
                <div
                    class="item"
                    @click="onHome"
                >
                    <XIcon
                        content="f045"
                        size="big"
                    />
                    <div class="label">
                        首页
                    </div>
                </div>
                <div
                    class="item"
                    @click="onServe"
                >
                    <XIcon
                        content="f088"
                        size="big"
                    />
                    <div class="label">
                        报名
                    </div>
                </div>
                <div
                    class="item"
                    @click="onApply"
                >
                    <XIcon
                        content="f089"
                        size="big"
                    />
                    <div class="label">
                        学习
                    </div>
                </div>
                <div
                    class="item"
                    @click="onMy"
                >
                    <XIcon
                        content="f003"
                        size="big"
                        :badge="userInfo.userMessageNum?' ':''"
                    />
                    <div class="label">
                        我的
                    </div>
                </div>
                <div
                    v-if="meiQiaAllSet"
                    key="meiqia"
                    class="item"
                    @click="onService"
                >
                    <XIcon
                        content="f024"
                        size="big"
                        :badge="unreadMsgCount?unreadMsgCount:''"
                    />
                    <div class="label">
                        客服
                    </div>
                </div>
            </div>
        </div>

        <transition name="show">
            <div
                v-show="isOpen"
                class="quick-navigation-popup"
                @click.self="onHeader"
            />
        </transition>
    </div>
</template>

<script>
// import { Device } from 'utils';
import { mapGetters } from 'vuex';
import MeiQiaControl from 'store/meiQia/MeiQiaControl';
import { meiQiaMixin } from 'mixins';

const $body = document.body;
export default {
    name: 'QuickNavigation',
    mixins: [meiQiaMixin],
    data() {
        return {
            isOpen: false,
            isMove: false,
            currentPageY: 0,
            offsetTop: null,
            startPageY: 0,
            storePathMap: {
                1: '/store',
                2: '/proxyStore',
            },
        };
    },
    computed: {
        ...mapGetters('User', ['userInfo']),
        /**
         * 页脚显示规则
         * 0/空、不显示
         * 1、店铺
         * 2、代理店铺
         * @returns {computed.footerShow|number|*}
         */
        type() {
            return +this.$route.meta.footerShow || 0;
        },
        storePathType() {
            const pathArr = this.$route.path.split('/');
            if (pathArr.length < 2) return '';
            return pathArr[1];
        },
        storeUserName() {
            const { storeUserName } = this.$route.params;
            return storeUserName ? `/${storeUserName}` : '';
        },
        customerServiceImage() {
            return this.$store.state.store.customerServiceImage;
        },
        innerStyle() {
            const { offsetTop } = this;
            if (offsetTop === null) return {};
            return {
                top: `${offsetTop}px`,
                bottom: 'auto',
            };
        },
        cartCount() {
            return this.$store.getters['subscript/cartCount'];
        },
        isIndexPage() {
            return this.$route.name && this.$route.name === 'Index';
        },
    },
    methods: {
        onHeader() {
            if (this.isMove) return;
            this.isOpen = !this.isOpen;
        },
        getToucheData($event) {
            const { touches, changedTouches, timeStamp } = $event;
            // eslint-disable-next-line no-nested-ternary
            const touche = touches ? (touches.length ? touches[0] : changedTouches[0]) : $event;
            return {
                timeStamp: timeStamp || new Date().getTime(),
                pageY: touche.pageY,
            };
        },
        onTouchstart(event) {
            event.stopPropagation();
            event.preventDefault();
            this.isMove = false;
            const { pageY } = this.getToucheData(event);
            this.startPageY = pageY;
            this.currentPageY = pageY;
            if (event.type === 'mousedown') {
                $body.addEventListener('mousemove', this.onTouchmove, false);
                $body.addEventListener('mouseup', this.onTouchend, false);
            } else {
                $body.addEventListener('touchmove', this.onTouchmove, false);
                $body.addEventListener('touchend', this.onTouchend, false);
            }
        },
        onTouchmove(event) {
            event.stopPropagation();
            event.preventDefault();
            this.isMove = true;
            const { pageY } = this.getToucheData(event);
            const offset = this.offsetTop + pageY - this.currentPageY;
            this.offsetTop = offset < 0 ? 0 : offset;
            this.currentPageY = pageY;
        },
        onTouchend(event) {
            event.stopPropagation();
            event.preventDefault();
            const { pageY } = this.getToucheData(event);
            if (!this.isMove || Math.abs(this.startPageY - pageY) < 10) {
                this.isMove = false;
                this.isOpen = !this.isOpen;
            }
            setTimeout(() => {
                this.isMove = false;
            }, 200);
            $body.removeEventListener('mousemove', this.onTouchmove);
            $body.removeEventListener('mouseup', this.onTouchend);
            $body.removeEventListener('touchmove', this.onTouchmove);
            $body.removeEventListener('touchend', this.onTouchend);
        },
        /*----------------*/
        onHome() {
            this.onHeader();
            if (this.isIndexPage) {
                this.$router.replace(`/index/home`);
            } else {
                this.$navigation.reLaunch(`/index/home`);
            }
        },
        onServe() {
            this.onHeader();
            if (this.isIndexPage) {
                this.$router.replace(`/index/serve`);
            } else {
                this.$navigation.reLaunch(`/index/serve`);
            }
        },
        onApply() {
            this.onHeader();
            if (this.isIndexPage) {
                this.$router.replace(`/index/apply`);
            } else {
                this.$navigation.reLaunch(`/index/apply`);
            }
        },
        onMy() {
            this.onHeader();
            if (this.isIndexPage) {
                this.$router.replace(`/index/my`);
            } else {
                this.$navigation.reLaunch(`/index/my`);
            }
        },
        onService() {
            this.onHeader();
            /* const { userInfo } = this;
            if (!userInfo || !userInfo.id) {
                this.$router.replace('/login');
                return;
            } */
            MeiQiaControl.showPanel();
        },
    },
    mounted() {
        // console.log(this.$refs.inner.offsetTop, Device.height * 0.8);
        // this.offsetTop = this.$refs.inner.offsetTop || Device.height * 0.8;
    },
};
</script>

<style lang="scss">
.quick-navigation {
    > .inner {
        position: absolute;
        z-index: $z-index-2;
        right: 0;
        bottom: $padding + $navHeight;
        //top: 80%;
        transform: translateX(100%);
        transition-duration: 0.5s;

        > .header {
            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            line-height: 1.2;
            padding: $padding-small;
            border-top-left-radius: $padding-small/2;
            border-bottom-left-radius: $padding-small/2;
            background-color: $color-main;
            color: #fff;
            font-size: $little * 100%;
        }
        > .body {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: $padding-small;
            background-color: $gray8;
            color: $gray2;
            border-top-left-radius: $padding-small/2;
            border-bottom-left-radius: $padding-small/2;

            > .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: $padding-small;
                min-width: 100px;
                > .label {
                    font-size: $little * 100%;
                    margin-top: $margin-small;
                    line-height: 1;
                }
            }
        }

        &.move {
            transition-duration: 0s;
        }
        &.open {
            transform: translateX(0);
        }
    }
    > .quick-navigation-popup {
        position: absolute;
        z-index: $z-index-1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
    }
}
</style>
