<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div
        :class="classes"
        v-on="$listeners">
        <slot>{{ label }}</slot>
    </div>
</template>

<script>
import { labelMixin, themeMixin } from '../mixins';

export default {
    name: 'Badge',
    mixins: [labelMixin, themeMixin],
    data() {
        return {};
    },
    props: {
        theme: {
            type: String,
            default: 'danger',
        },
        plain: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { theme, size, dot, plain } = this;
            return ['badge', theme, size, { dot }, { plain }];
        },
        dot() {
            return this.label === ' ';
        },
    },
};
</script>

<style lang="scss">
.badge {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    //background-color: $color-danger;
    //color: #fff;
    border-radius: 100vw;
    line-height: 1.5;
    min-width: 1.5em;
    padding: 0 $padding-small;

    &.dot {
        min-width: auto;
        width: 20px;
        height: 20px;
        padding: 0;
    }

    @each $key, $value in $colorList {
        &.#{$key} {
            $color-text: $color-text;
            @if (lightness($value) <71) {
                $color-text: #fff;
            }
            background-color: $value;
            color: $color-text;
            &.plain {
                background-color: transparent;
                border: 1px solid $value;
                color: $value;
            }
        }
    }
    /* 尺寸大小 */
    @each $key, $value in $sizeList {
        &.#{$key} {
            font-size: $value * 100%;
        }
    }
}
</style>
