<!-- Created by henian.xu on 2019/5/22. -->

<template>
    <transition
        v-if="!lazy"
        name="show"
    >
        <div
            ref="popup"
            class="popup dialog"
            v-show="popupShow"
        >
            <transition name="bounce">
                <div
                    class="inner"
                    v-show="popupShow"
                >
                    <slot
                        v-if="hasLabel"
                        name="header">
                        <div class="header">
                            <XLabel v-bind="labelProps" />
                        </div>
                    </slot>
                    <slot name="footer" />
                    <slot name="body">
                        <div class="body">
                            <slot />
                        </div>
                    </slot>

                    <div
                        v-if="showCloseBtn"
                        class="close-btn"
                        @click="close">
                        <XIcon content="f018" />
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import { labelMixin, popupMixin } from '../mixins';

export default {
    name: 'Dialog',
    mixins: [labelMixin, popupMixin],
    data() {
        return {
            lazy: true,
        };
    },
    props: {
        showCloseBtn: {
            type: Boolean,
            default: true,
        },
        useRouter: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.popup.dialog {
    > .inner {
        position: relative;
        width: 80%;
        max-height: 80%;
        background-color: transparent;

        > .header {
            order: 0;
            border-bottom: 1px solid $color-border;
            background-color: $color-component-bgc;
            border-top-left-radius: $padding-small;
            border-top-right-radius: $padding-small;
            overflow: hidden;

            ~ .body {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        > .body {
            order: 1;
            background-color: $color-component-bgc;
            border-radius: $padding-small;
            padding: 0;
        }
        > .footer {
            order: 2;
            border-bottom-left-radius: $padding-small;
            border-bottom-right-radius: $padding-small;
            overflow: hidden;
            ~ .body {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        > .close-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            position: absolute;
            left: 50%;
            bottom: -80px;

            margin-left: -25px;
            width: 50px;
            height: 50px;
            font-size: 26px;
            color: #fff;
            background-color: rgba(255, 255, 255, 0.5);
            border: 1px solid $color-border;
            border-radius: 100vw;
        }
    }
}
</style>
