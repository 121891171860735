/**
 * Created by henian.xu on 2020/4/1.
 *
 */

import { get } from 'api/request';

export function getChild(pId) {
    return get(this.url, { pId });
}
