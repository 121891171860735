<template>
    <div
        id="app"
        :class="classes">
        <PageNav
            v-if="isPageNav"
            :label="meta.title"
            :sub-label="meta.subTitle" />
        <div class="pages">
            <transition :name="transitionName">
                <navigation>
                    <router-view />
                </navigation>
            </transition>
        </div>
        <QuickNavigation v-show="showQuickNavigation" />
        <!--<FooterMain />-->
    </div>
</template>

<script>
import { GlobalVar, Device } from 'utils';

export default {
    data() {
        return {
            transitionName: '',
        };
    },
    head() {
        const { meta } = this;
        return {
            title: `${meta.title || ''}${meta.subTitle ? ` - ${meta.subTitle}` : ''}` || GlobalVar.DocTitle,
        };
    },
    computed: {
        meta() {
            return this.$route.meta;
        },
        classes() {
            return [
                {
                    'device-pc': Device.desktop,
                },
            ];
        },
        isPageNav() {
            return Device.desktop;
        },
        showQuickNavigation() {
            // return this.$route.name && this.$route.name !== 'Index';
            return true;
        },
    },
    created() {
        this.$navigation.on('forward', (to, from) => {
            if (from.route.path === '/') {
                this.transitionName = '';
            } else {
                this.transitionName = 'left';
            }
        });
        this.$navigation.on('replace', () => {
            this.transitionName = '';
        });

        this.$navigation.on('back', () => {
            this.transitionName = 'right';
        });
        this.$navigation.on('quit', ({ resolve, reject }) => {
            this.$messageBox
                .confirm('是否要退出程序', '温馨提示', {
                    isClickOtherClose: false,
                })
                .then(resolve)
                .catch(reject);
        });

        if (Device.desktop) {
            document.body.style.overflowX = 'auto';
        }
    },
};
</script>

<style lang="scss">
#app {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.device-pc {
        width: 1000px;
        margin: 0 auto;
    }
}
</style>
