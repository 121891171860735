/**
 * Created by henian.xu on 2019/10/25.
 * XIcon 图标混合器
 */

export default {
    props: {
        // 对应于 XIcon 组件的 content 属性
        icon: {
            type: String,
            default: '',
        },
        iconTheme: {
            type: String,
            default: '',
        },
        iconSize: {
            type: String,
            default: '',
        },
    },
    computed: {
        iconProps() {
            const { theme, iconTheme, icon, size, iconSize, disabled } = this;
            return { content: icon, theme: iconTheme || theme, size: iconSize || size, disabled };
        },
        hasIcon() {
            return !!this.iconProps.content;
        },
    },
};
