<!-- Created by henian.xu on 2019/10/25. -->

<template>
    <XImage
        :class="classes"
        :style="selfStyles"
        :src="src || $defaultAvatar"
        :alt="alt"
        :width="size_"
        :height="size_"
        :radius="radius"
        fit="cover"
        v-bind="linkerProps_"
        v-on="excludeInputListeners"
    />
</template>

<script>
import XImage from 'packages/Image/index';
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'Avatar',
    mixins: [linkerMixin, listenersMixin],
    components: { XImage },
    // extends: XImage,
    data() {
        return {};
    },
    props: {
        src: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: undefined,
        },
        // 圆角
        radius: {
            type: [Boolean, String],
            default: false,
            validator(val) {
                return !(typeof val === 'string' && val !== 'all');
            },
        },
        size: {
            type: [String, Number],
            default: '1rem',
        },
        border: {
            type: [Boolean, String],
            default: false,
        },
    },
    computed: {
        linkerProps_() {
            const res = { ...this.linkerProps };
            delete res.is;
            return res;
        },
        size_() {
            return this.$utils.transformCssValue(this.size);
        },
        classes() {
            const { border } = this;
            return ['avatar', { border }];
        },
        selfStyles() {
            const { border } = this;
            if (typeof border !== 'string') return {};
            return {
                border,
            };
        },
    },
};
</script>

<style lang="scss">
.avatar {
    &.border {
        border: $margin-small/2 solid #fff;
    }
}
</style>
