<!-- Created by henian.xu on 2018/9/12. -->

<template>
    <div class="widget-shell">
        <div
            ref="widget"
            v-if="isSupport"
            :is="componentName"
            :style="componentStyles"
            is-widget
            @complete="onWidgetComplete"
            v-bind="data.props"
        />
        <div
            v-else
            class="ta-c pa-a bc-g2 tc-g6"
        >
            暂不支持 {{ componentName }} 组件
        </div>
    </div>
</template>

<script>
import * as Components from './decoration';

export default {
    name: 'Shell',
    components: { ...Components },
    data() {
        return {
            timerId: 0,
            timerCount: 0,
        };
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        componentName() {
            return this.data.componentType;
        },
        isSupport() {
            const { componentName } = this;
            return !!this.$options.components[componentName];
        },
        hasComponentMargin() {
            return !Number.isNaN(+this.data.componentMargin);
        },
        componentStyles() {
            if (this.hasComponentMargin) {
                return {
                    margin: `${this.data.componentMargin / 100}rem 0`,
                };
            }
            return {};
        },
        isPaginationComponent() {
            if (!this.isSupport) return false;
            switch (this.componentName) {
                case 'GoodsList':
                    return true;
                default:
                    return false;
            }
        },
    },
    methods: {
        onWidgetComplete() {
            this.$emit('complete');
        },
        isLoaded() {
            if (!this.isSupport) {
                // 不支持的组件 直接完成
                this.$emit('complete');
                return;
            }
            if (this.isPaginationComponent) {
                // 是颁布组件就不在这里处理
                return;
            }
            const { widget } = this.$refs;
            let oldHeight = 0;
            this.timerId = setInterval(() => {
                console.log(`${this.componentName} isLoaded`);
                this.timerCount += 1;
                if (this.timerCount < 100 && (!widget.$el || !widget.$el.clientHeight)) return;
                const { clientHeight } = widget.$el;
                if (oldHeight !== clientHeight) {
                    oldHeight = clientHeight;
                    return;
                }
                clearInterval(this.timerId);
                this.$emit('complete');
            }, 200);
        },
    },
    mounted() {
        this.isLoaded();
    },
    deactivated() {
        clearInterval(this.timerId);
        console.log('timerId');
    },
};
</script>

<style lang="scss">
</style>
