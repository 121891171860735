<!-- Created by henian.xu on 2020/3/26. -->

<template>
    <div
        class="training-live-item"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="header">
            <XIcon
                content="f093"
                theme="main"
            />
            <XLabel>{{data.trainingLiveName}}</XLabel>
        </div>
        <div class="body">
            <div class="inner">
                <dl>
                    <dt>上课时间:</dt>
                    <dd>{{data.classTimeBegin | moment('YYYY-MM-DD HH:mm')}}</dd>
                </dl>
                <dl>
                    <dt>上课老师:</dt>
                    <dd>{{data.teacherName}}</dd>
                </dl>
                <dl>
                    <dt>时　　长:</dt>
                    <dd>{{data.classDuration}}课时</dd>
                </dl>
            </div>
            <XIcon
                v-if="to"
                content="f012"
                size="small"
                theme="g5"
            />
        </div>
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'TrainingLiveItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {},
};
</script>

<style lang="scss">
.training-live-item {
    display: block;
    background-color: $color-component-bgc;
    //border-radius: $padding;
    border: 1px solid $color-border;
    overflow: hidden;
    @include boxShadow();

    > .header {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .x-icon {
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1;
            font-weight: bold;
        }
    }
    > .body {
        margin: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .inner {
            flex: 1;
            dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                + dl {
                    margin-top: $margin-small;
                }
                > dt {
                    flex: 0 0 auto;
                }
                > dd {
                    flex: 1;
                }
            }
        }
    }
    > .footer {
        margin: $padding 0;
        text-align: right;
    }
}
</style>
