<!-- Created by henian.xu on 2018/9/13. -->

<template>
    <List
        :margin="type_!==7"
        :border="type_===7"
        :column="type_===5?2:1"
        :padding="type_===5?'all':false"
    >
        <CmsItem
            v-for="item in listData"
            :key="item.id"
            :data="item"
            :type="type"
            :to="`/cms/detail/${item.id}`"
        />
    </List>
</template>

<script>
export default {
    name: 'Goods',
    data() {
        return {
            listData: [],
        };
    },
    props: {
        // 单列:1;双列:2;
        type: {
            type: [Number, String],
            default: 1,
        },
        ids: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        type_() {
            return +this.type || 1;
        },
    },
    watch: {
        ids: {
            async handler(val) {
                if (!val || !val.length) {
                    return;
                }
                const res = await this.$api.Comm.Pt.Cms.cmsArticleList({
                    ids: val.join(','),
                });
                this.listData = res.data;
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
</style>
