/**
 * Created by henian.xu on 2020/4/5.
 *
 */

import jsSdk from 'jweixin';
import { GlobalVar, Device } from 'utils';
import { Api } from 'api/index';

const defaultConfig = {
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    jsApiList: [
        'openEnterpriseChat',
        'openEnterpriseContact',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'onVoicePlayEnd',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'translateVoice',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'closeWindow',
        'scanQRCode',
    ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
};

let readyed = false;

async function initJweixinSdk() {
    const wxCfg = GlobalVar.wx || {};
    const { success, msg, data } = await Api.Member.Mp.Wechat.sign(window.location.href.split('#')[0]);
    // console.log(success);
    if (!success) {
        if (Device.weixin) console.error(msg);
        return;
    }
    jsSdk.config({
        ...defaultConfig,
        ...wxCfg,
        ...data,
    });
}

async function isReady() {
    return new Promise((resolve, reject) => {
        if (!Device.weixin) {
            return reject(new Error('当前非微信浏览器环境'));
        }
        if (readyed) return resolve(true);
        // config信息验证后会执行ready方法，
        // 所有接口调用都必须在config接口获得结果之后，
        // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
        // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        jsSdk.ready(() => {
            readyed = true;
            resolve(readyed);
        });
        return null;
    });
}

/**
 * 微信扫码
 * @param needResult
 * @param scanType
 * @returns {Promise<unknown>}
 */
async function scanQRCode({ needResult, scanType } = {}) {
    await isReady();
    return new Promise((resolve, reject) => {
        jsSdk.scanQRCode({
            needResult: needResult || 1, // 默认为1，直接返回扫描结果，0则扫描结果由微信处理，
            scanType: scanType || ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: res => resolve(res),
            error: res => reject(res),
        });
    });
}

async function previewImage(current, urls) {
    return isReady().then(() => {
        jsSdk.previewImage({
            current, // 当前显示图片的http链接
            urls, // 需要预览的图片http链接列表
        });
    });
}

async function chooseWXPay(paySign) {
    paySign.timestamp = paySign.timestamp || paySign.timeStamp;
    await isReady();
    return new Promise((resolve, reject) => {
        jsSdk.chooseWXPay({
            ...paySign,
            success: res => resolve(res),
            error: res => reject(res),
        });
    });
}
async function closeWindow() {
    jsSdk.closeWindow();
}

export default Vue => {
    if (jsSdk) {
        initJweixinSdk();
    } else {
        console.error('微信 jweixin.js 文件未加载!');
    }

    Vue.prototype.$wx = jsSdk;
    Vue.prototype.$wxSDK = {
        isReady,
        scanQRCode,
        previewImage,
        chooseWXPay,
        closeWindow,
    };
};
