<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <component
        :is="isSwiper?'swiper':'div'"
        ref="topicList"
        class="topic-list"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
    >
        <component
            :is="isSwiper?'swiper-slide':'div'"
            v-for="(item,i) in data"
            :key="item?item.id:`index${i}`"
        >
            <Topic
                v-if="item"
                :data="item"
                :index="startIndex+i+1"
                :value="(model[startIndex+i]||{}).selectOption"
                :is-analysis="(isAnalysis || isPractice === 2 || (isPractice === 1 && (model[startIndex+i]||{}).isConfirm)&&!hideAnalysis)"
                :hide-answer-result="isPractice === 2"
                @input="($event)=>{onTopicInput($event,item,startIndex+i)}"
            />
            <div
                v-if="item && isPractice === 1 && (item.questionType === 3||strictConfirm) && !(model[startIndex+i]||{}).isConfirm"
                class="pa-a ta-c">
                <XButton
                    v-if="answerConfirming"
                    class="w-6em"
                    theme="g5"
                    radius="all"
                >确认中...</XButton>
                <XButton
                    v-else
                    class="w-6em"
                    theme="primary"
                    radius="all"
                    @click="($event)=>{onAnswerConfirm($event,item,startIndex+i)}"
                >确认</XButton>
            </div>
        </component>
    </component>
</template>

<script>
import { debounce } from 'utils/index';
import { refMixin } from 'packages/mixins';

export default {
    name: 'TopicList',
    mixins: [refMixin],
    data() {
        return {
            answerConfirming: false,
            slideTo_: debounce(this.slideTo),
        };
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        data: {
            type: Array,
            default: () => [],
        },
        index: {
            type: Number,
            default: 0,
        },
        startIndex: {
            type: Number,
            default: 0,
        },
        lazy: {
            type: [Boolean, Number],
            default: false,
        },
        isAnalysis: {
            type: Boolean,
            default: false,
        },
        // 0:非练习;1:答题模式;2:记题模式
        isPractice: {
            type: Number,
            default: 0,
        },
        strictConfirm: {
            type: Boolean,
            default: false,
        },
        isSwiper: {
            type: Boolean,
            default: true,
        },
        hideAnalysis: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                const { value, data } = this;
                if (value !== null) return value;
                return data.map(item => {
                    const { id, selectOption } = item;
                    return {
                        id,
                        selectOption: selectOption || 0,
                    };
                });
            },
            set(val) {
                if (this.value === null) return;
                this.$emit('input', val);
            },
        },
    },
    watch: {
        index: {
            async handler(val) {
                if (!val && val !== 0) return;
                this.slideTo_(val);
            },
            immediate: true,
        },
    },
    methods: {
        async getSwiper() {
            const topicList = await this.getRefsChildren('topicList');
            await this.$nextTick();
            if (topicList.$swiper) return topicList.$swiper;
            return Promise.reject();
        },
        async getSwiperIndex() {
            const swiper = await this.getSwiper();
            if (swiper.activeIndex === this.index) return null;
            return swiper.activeIndex;
        },
        async slideTo(val) {
            const swiper = await this.getSwiper();
            if (swiper.activeIndex === null) return;
            if (val === swiper.activeIndex || swiper.activeIndex === null) return;
            console.log('slideTo', swiper.initialized, val, swiper.activeIndex);
            await this.$nextTick();
            swiper.slideTo(val, 0, false);
        },
        async onSlideChangeTransitionEnd() {
            const swiperIndex = await this.getSwiperIndex();
            if (swiperIndex === null) return;
            this.$emit('index-change', swiperIndex);
        },
        async onTopicInput(val, item, index) {
            const res = {
                id: item.id,
                selectOption: val,
                isConfirm: this.strictConfirm ? false : item.questionType !== 3,
            };
            if (res.isConfirm) await this.saveAnswer(item, res);

            const oldValue = this.value[index];
            if (!oldValue) {
                this.$emit('add-answer');
            }

            this.value.splice(index, 1, res);
            this.model = this.value;
        },
        async onAnswerConfirm($event, item, index) {
            console.log($event, item, index);
            let currentAnswer = this.model[index];
            if (!currentAnswer) {
                await this.$messageBox.confirm('您还未答题是否要确认');
                currentAnswer = {
                    id: item.id,
                    selectOption: 0,
                };
            }
            const res = {
                ...currentAnswer,
                isConfirm: true,
            };
            this.answerConfirming = true;
            try {
                await this.saveAnswer(item, res, index);

                const oldValue = this.value[index];
                if (!oldValue) this.$emit('add-answer');

                this.value.splice(index, 1, res);
                this.model = this.value;
            } catch (e) {
                // ;
            }
            this.answerConfirming = false;
        },
        async saveAnswer(question, answer, index) {
            return new Promise((resolve, reject) => {
                if (!this.$listeners['confirm-answer']) {
                    resolve();
                    return;
                }
                this.$emit('confirm-answer', { question, answer, index, resolve, reject });
            });
        },
    },
};
</script>

<style lang="scss">
.topic-list {
    &.swiper-container {
        flex: 1 1 1%;
        //height: 100%;
        background-color: $color-component-bgc;
        > .swiper-wrapper {
            > .swiper-slide {
                @include setScrollAxis('y');
            }
        }
    }
}
</style>
