<!-- Created by henian.xu on 2019/2/28. -->

<!--<template>
    <div class="page">
        <slot />
    </div>
</template>-->

<script>
import { addListener, removeListener } from 'resize-detector';
import { debounce } from 'utils';

export default {
    name: 'Page',
    provide() {
        return { Page: this };
    },
    data() {
        return {
            container: {},
            slotNodes: [],
            slotElm: [],
            computedHeight_: debounce(this.computedHeight),
            surplusHeight: null,
        };
    },
    methods: {
        addContainer(container) {
            this.container = container;
        },
        resizeHandler(/* elm */) {
            // console.log(elm);
            this.computedHeight_();
        },
        computedHeight() {
            const height = this.slotElm.reduce((pre, elm) => {
                return pre + elm.offsetHeight;
            }, 0);
            const pageHeight = this.$el.offsetHeight;
            this.surplusHeight = pageHeight - height;
            // console.log(this.surplusHeight);
        },
    },
    render() {
        const defaultReader = this.$scopedSlots.default;
        const slotNodes = defaultReader
            ? defaultReader().reduce((pre, node) => {
                  // console.log(node);
                  pre.push(node);
                  return pre;
              }, [])
            : [];
        this.slotNodes = slotNodes;
        return <div class="page">{slotNodes}</div>;
    },
    mounted() {
        const { slotNodes } = this;
        this.slotElm = slotNodes.reduce((pre, node) => {
            const { elm, tag } = node;
            if (!tag || (node.componentOptions && node.componentOptions.Ctor.options.name === 'Container')) return pre;
            // if (!tag) return pre;
            // console.log(elm);
            if (!elm.resizeHandler) {
                elm.resizeHandler = debounce(this.resizeHandler);
            }
            addListener(elm, elm.resizeHandler);
            pre.push(elm);
            return pre;
        }, []);
        this.computedHeight_();
    },
    beforeDestroy() {
        this.slotElm.forEach(elm => {
            removeListener(elm, elm.resizeHandler);
        });
        // console.log(this.slotElm);
    },
};
</script>

<style lang="scss">
.pages,
.page {
    position: relative;
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    background-color: $color-body;
}
.pages {
    overflow: hidden;
}
.page {
    background-color: $color-page;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
