/**
 * Created by henian.xu on 2019/10/22.
 * 移动端布局适配方案
 */

import { GlobalVar, Device } from 'utils';

(win => {
    if (!document.addEventListener) return;
    const viewWidth = 750;
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    const docEl = document.documentElement;
    const reCalc = () => {
        const { clientWidth, clientHeight } = docEl;
        if (!clientWidth) return;
        let fontSize = Device.desktop ? 50 : 100;
        const width = Math.min(clientWidth, clientHeight);
        if (!Device.desktop && width < viewWidth) {
            fontSize = (width / viewWidth) * 100;
        }
        GlobalVar.zoom = width / viewWidth;
        GlobalVar.rootFontSize = fontSize;
        docEl.style.fontSize = `${fontSize}px`;
    };
    win.addEventListener(resizeEvt, reCalc, false);
    document.addEventListener('DOMContentLoaded', reCalc, false);
})(window);
