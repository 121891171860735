/**
 * Created by henian.xu on 2019/3/8.
 *
 */

// import idCardValid from './idCardValid';
import { isArray, isBoolean, isObject } from './Common';

const emailReg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
// const mobileReg = /(^((166|198|199)|(15[^4])|(14[5-9])|17[0-3]|(17[5-8])|((13|18)[0-9]))\d{8}$)|(^(1749)\d{7}$)/;
const mobileReg = /^1\d{10}$/;
const iDCardOldReg = /^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$/;
const iDCardReg = /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}[\dXx]$/;

/**
 * 确认密码验证
 * @param rule
 * @param value
 * @param callback
 */
export function againPassword(rule, value, callback /* , source, options */) {
    if (value !== this.formModel.password) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 手机验证
 * @param rule
 * @param value
 * @param callback
 */
export function mobile(rule, value, callback /* , source, options */) {
    // value = (value + '').replace(/\s*/g, '');
    const val = `${value}`.replace(/^\s+|\s+$/gm, '');
    if (!mobileReg.test(val)) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 邮箱验证
 * @param rule
 * @param value
 * @param callback
 */
export function email(rule, value, callback /* , source, options */) {
    if (!emailReg.test(value)) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 账号验证
 * @param rule
 * @param value
 * @param callback
 */
export function account(rule, value, callback) {
    if (!emailReg.test(value) && !mobileReg.test(value)) {
        callback(new Error(rule.message));
    } else {
        callback();
    }
}

/**
 * 身份证校验
 * @param rule
 * @param value
 * @param callback
 */
export function iDCard(rule, value, callback /* , source, options */) {
    // value = (value + '').replace(/\s*/g, '');
    value = `${value}`.replace(/^\s+|\s+$/gm, '');
    if (!value || iDCardOldReg.test(value) || iDCardReg.test(value)) {
        callback();
    } else {
        callback(new Error(rule.message));
    }
}
/* export function idCard(...arg) {
    return idCardValid.apply(this, arg);
} */

export function RegExp(rule, value, callback) {
    value = `${value}`.replace(/^\s+|\s+$/gm, '');
    const { message, regExpStr } = rule;
    const regExp = new window.RegExp(regExpStr);
    if (!value || regExp.test(value)) {
        callback();
    } else {
        callback(new Error(message));
    }
}

export function strictRequired(rule, value, callback) {
    const { key = 'id', message } = rule;
    let val = value;
    if (isArray(value)) {
        val = value.length;
    } else if (isObject(value)) {
        val = value[key];
    } else if (isBoolean(value)) {
        val = true;
    }
    if (val) {
        callback();
    } else {
        callback(new Error(message));
    }
}
