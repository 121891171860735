/**
 * Created by henian.xu on 2020/4/2.
 *
 */

import { get, post } from 'api/request';

export function invoiceList({ invoiceStatus }) {
    return get(this.url, { invoiceStatus });
}
export function invoiceDetail({ orderId }) {
    return get(this.url, { orderId });
}
export function saveOrderInvoice({
    orderId,
    invoiceType,
    invoiceTitleType,
    invoiceTitle,
    taxIdentificationSn,
    receiveEmail,
    consignee,
    phone,
    regionId,
    address,
    bankName,
    bankAccount,
    registerAddress,
    registerTel,
    invoiceRemarks,
}) {
    return post(this.url, {
        orderId,
        invoiceType,
        invoiceTitleType,
        invoiceTitle,
        taxIdentificationSn,
        receiveEmail,
        consignee,
        phone,
        regionId,
        address,
        bankName,
        bankAccount,
        registerAddress,
        registerTel,
        invoiceRemarks,
    });
}

export function enterpriseList({ keywords }) {
    return get(
        this.url,
        {
            keywords,
        },
        { isHandleError: true },
    );
}
export function enterpriseDetail({ code }) {
    return get(
        this.url,
        {
            code,
        },
        { isHandleError: true },
    );
}
export function createOrderInvoice({ orderId, invoiceType }) {
    return get(this.url, {
        orderId,
        invoiceType,
    });
}
