/**
 * Created by henian.xu on 2020/1/14.
 *
 */
import Vue from 'vue';

let keyName;
let entranceKeyName;
let router = null;
export const navComponentList = [];
export const bus = new Vue();

// keyName
export const setKeyName = name => {
    keyName = name;
};
export const getKeyName = () => keyName;

// entranceKeyName
export const setEntranceKeyName = name => {
    entranceKeyName = name;
};
export const getEntranceKeyName = () => entranceKeyName;

// router
export const setRouter = routerInstance => {
    router = routerInstance;
};
export const getRouter = () => router;

// components
export function addNavComponent(vueComponent) {
    navComponentList.push(vueComponent);
}
export function removeNavComponent(vueComponent) {
    const uid = vueComponent._uid;
    navComponentList.some((component, index, array) => {
        if (component._uid === uid) {
            const { cache } = component;
            Object.values(cache).forEach(vnode => {
                if (vnode) vnode.componentInstance.$destroy();
            });
            array.splice(index, 1);
            return true;
        }
        return false;
    });
}

// 获取当前路由
export function getCurrentRoute() {
    if (!router) return null;
    return router.currentRoute;
}

export function getKeyByRouter(route = getCurrentRoute()) {
    if (!route) return '';
    return `${route.name || route.path}?${route.query[keyName]}`;
}
