<!-- Created by henian.xu on 2020/3/27. -->

<template>
    <div :class="classes">
        <div
            class="header"
            @click="onClick"
        >
            <div class="inner">
                <slot name="icon">
                    <XIcon
                        v-if="hasIcon"
                        v-bind="iconProps" />
                </slot>
                <XLabel v-bind="labelProps" />
                <slot name="extra">
                    <div class="extra">
                        {{ extra }}
                    </div>
                </slot>
                <slot name="badge">
                    <Badge
                        v-if="badge"
                        :content="icon"
                        :theme="theme"
                        :label="badge"
                        size="small"
                    />
                </slot>
            </div>
            <XIcon
                class="link"
                :content="!isFold?'f010':'f012'"
                theme="g5"
                size="small"
            />
        </div>
        <div
            class="body"
            v-show="!isFold"
        >
            <slot
                v-if="hasChildren"
                name="body"
            >
                <slot></slot>
            </slot>
            <div
                v-else
                class="pa-a ta-c">暂无数据</div>
        </div>
    </div>
</template>

<script>
import { labelMixin, iconMixin, linkerMixin } from '../mixins';

export default {
    name: 'CollapseItem',
    mixins: [labelMixin, iconMixin, linkerMixin],
    data() {
        return {
            appliedToLabelScopedSlots: true,
            isFold: true,
        };
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        iconTheme: {
            type: String,
            default: '',
        },
        iconSize: {
            type: String,
            default: '',
        },
        badge: {
            type: String,
            default: '',
        },
        extra: {
            type: String,
            default: '',
        },
        link: {
            type: Boolean,
            default: null,
        },
        focusExtra: {
            type: Boolean,
            value: null,
        },
        direction: {
            type: String,
            default: '', // row;column
        },
        fold: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        classes() {
            const { theme, focusExtra, direction } = this;
            return ['collapse-item', theme, { 'focus-extra': focusExtra }, direction];
        },
        hasChildren() {
            const { $scopedSlots } = this;
            return !!$scopedSlots.default || !!$scopedSlots.body;
        },
    },
    watch: {
        fold: {
            handler(val) {
                this.isFold = !!val;
            },
            immediate: true,
        },
    },
    methods: {
        onClick() {
            // if (!this.hasChildren) return;
            this.isFold = !this.isFold;
        },
    },
};
</script>

<style lang="scss">
.collapse-item {
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        background-color: #fff;
        min-height: $navHeight;

        > .inner {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            > .extra,
            > .badge,
            > .x-icon {
                flex: 0 0 auto;
                margin-left: $margin-small;
            }

            > .x-label {
                flex: 1 1 1%;
                //min-height: $formItemHeight;
            }

            > .extra {
                color: $color-text-minor;
            }
        }
        > .link {
            margin-left: $margin-small;
        }
    }

    > .body {
        //padding: $padding;
        border-top: 1px solid $color-border;
        background-color: $gray1;
    }

    &.focus-extra {
        > .header {
            > .inner {
                > .x-label {
                    flex: 0 0 auto;
                }

                > .extra {
                    flex: 1 1 1%;
                    text-align: right;
                }
            }
        }
    }

    &.column {
        > .header {
            > .inner {
                flex-direction: column;
                align-items: stretch;
                > .extra {
                    margin-left: 0;
                    margin-top: $margin-small;
                }
            }
        }
    }
}
</style>
