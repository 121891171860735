<!-- Created by henian.xu on 2018/9/7. -->

<template>
    <div class="goods">
        <List
            class="goods"
            :column="+type===1?2:1"
            :padding="+type===1?'all':false"
            :border="+type===2"
        >
            <GoodsItem
                v-for="item in goodsList"
                :key="item.id"
                :data="item"
                :type="+type"
                :to="`/serve/detail/${item.id}`"
            />
        </List>
        <Pagination
            ref="infinite"
            :disabled="paginationDisabled"
            @load="onload"
        />
    </div>
</template>

<script>
import { paginationMixin } from 'mixins/index';

export default {
    name: 'GoodsList',
    mixins: [paginationMixin],
    data() {
        return {
            goodsList: [],
        };
    },
    props: {
        // 单列:1;双列:2;
        type: {
            type: [Number, String],
            default: 1,
        },
        orderBy: {
            type: String,
            default: 'onSaleTime,asc',
            validator(val) {
                return val.split(',').length >= 2;
            },
        },
    },
    computed: {
        orderByKey() {
            const orderByArr = this.orderBy.split(',');
            return orderByArr[0];
        },
        orderByType() {
            const orderByArr = this.orderBy.split(',');
            return orderByArr[1];
        },
    },
    watch: {
        orderBy: {
            async handler(val) {
                if (!val) return;
                const orderByArr = val.split(',');
                if (!orderByArr || !orderByArr.length) return;
                const res = await this.$api.Member.Gd.Goods.goodsList({
                    orderByKey: orderByArr[0],
                    orderByType: orderByArr[1],
                    pageSize: 6,
                });
                this.goodsList = res.data;
            },
            // immediate: true,
        },
    },
    methods: {
        async getListData(params) {
            const res = await this.$api.Member.Gd.Goods.goodsList({
                orderByKey: this.orderByKey,
                orderByType: this.orderByType,
                ...params,
            });
            this.goodsList = this.goodsList.concat(res.data);
            this.isComplete(res);
            return res;
        },
        isComplete(result) {
            const { currentPage, totalRows, totalPages } = result.pagination;
            if (!totalRows || currentPage === totalPages) {
                this.paginationDisabled = true;
                this.$emit('complete');
            }
        },
    },
};
</script>

<style lang="scss">
</style>
