/**
 * Created by henian.xu on 2019/10/22.
 *
 */

const GlobalVar = window.__GLOBAL_VAR__ || {};

GlobalVar.DocTitle = document.title; // 默认页面标题
GlobalVar.isDev = process.env.NODE_ENV === 'development';

const requireConfig = require.context(
    '@',
    true,
    /\.(\/.[^/]*)?\/(config|config\/index)\.js$/,
    // 只搜索1、2级目录下的的config.js或config/index.js
);

function mergeConfig(config) {
    // TODO 写一个配置合并方法,目前只是简单的覆盖
    GlobalVar.appConfig = {
        ...GlobalVar.appConfig,
        ...config,
    };
}

requireConfig.keys().forEach(fileName => {
    const config = requireConfig(fileName);
    if (!config) return;
    mergeConfig(config);
    // console.log(config);
});

export { GlobalVar };
export default GlobalVar;
