<!-- Created by henian.xu on 2019/3/4. -->

<template>
    <div :class="classes">
        <!--{{ fieldValue }}-->
        <div
            v-if="isHeader"
            class="header"
            :style="headerStyle">
            <slot name="icon">
                <XIcon
                    v-if="icon"
                    v-bind="iconProps" />
            </slot>
            <XLabel
                v-if="hasLabel"
                v-bind="labelProps" />
            <!--<slot name="extra" />-->
        </div>
        <slot name="body">
            <div class="body">
                <slot />
            </div>
        </slot>
        <div class="extra">
            <slot name="extra" />
        </div>
        <div
            v-if="isError"
            :class="['feedback tc-danger', { show: showFeedback_ }]"
            @click="onError">
            <template>
                <!--<XIcon content="f019" />-->
                <XIcon content="f041" />
            </template>
            <template v-if="showFeedback_">
                {{ validateMessage }}
            </template>
        </div>
    </div>
</template>

<script>
import ValidatorSchema from 'async-validator';
import { debounce, isBoolean, pluckDeep } from 'utils';
import { labelMixin, iconMixin } from '../mixins';

export default {
    name: 'FormItem',
    mixins: [labelMixin, iconMixin],
    provide() {
        return { FormItem: this };
    },
    inject: ['Form'],
    data() {
        return {
            appliedToLabelScopedSlots: true,
            validateStatus: '',
            validateMessage: '',
            showMessage: false,
            validate_: debounce(this.validate, 200, true),
        };
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        prop: {
            type: String,
            default: '',
        },
        rules: {
            type: [Object, Array],
            default: () => [],
        },
        direction: {
            type: String,
            default: '', // row;column
        },
        align: {
            type: String,
            default: null, // left;right
        },
        showFeedback: {
            type: Boolean,
            default: null,
        },
        labelWidth: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: null,
        },
    },
    computed: {
        classes() {
            const { direction_, align_ } = this;
            return ['form-item', direction_, align_];
        },
        align_() {
            const { align, Form } = this;
            return align !== null ? align : Form.align;
        },
        headerStyle() {
            const labelWidth = this.Form.labelWidth || this.labelWidth;
            return {
                width: labelWidth,
            };
        },
        direction_() {
            return this.Form.direction || this.direction;
        },
        showFeedback_() {
            const { showFeedback, Form } = this;
            return (isBoolean(showFeedback) ? showFeedback : Form.showFeedback) || this.showMessage;
        },
        isSuccess() {
            return this.validateStatus === 'success';
        },
        isError() {
            return this.validateStatus === 'error';
        },
        fieldValue: {
            get() {
                const { model } = this.Form;
                const propPath = this.prop;
                if (!model || !propPath) return {};
                return pluckDeep(model, propPath);
            },
            cache: false,
        },
        rulesList() {
            const formRules = pluckDeep(this.Form.rules, this.prop) || [];
            return [].concat(this.rules, formRules);
        },
        isHeader() {
            return this.icon || this.hasLabel;
        },
        disabled_() {
            const { disabled } = this;
            const { Form } = this;
            if (!Form) return disabled;
            const { disabled: disabledForm } = Form;
            return disabled !== null ? disabled : disabledForm;
        },
        readonly_() {
            const { readonly } = this;
            const { Form } = this;
            if (!Form) return readonly;
            const { readonly: readonlyForm } = Form;
            return readonly !== null ? readonly : readonlyForm;
        },
    },
    watch: {
        fieldValue() {
            this.validate_('change')
                // .then(() => console.log('watch:change validate = true'))
                .catch(() => {});
        },
    },
    methods: {
        getRules(trigger) {
            return this.rulesList.filter(rule => !rule.trigger || rule.trigger.indexOf(trigger) !== -1).map(rule => {
                // 拷贝规则保证不在后续操作中修改到原始规则
                const obj = { ...rule };
                delete obj.trigger;
                return obj;
            });
        },
        validate(trigger) {
            let callback;
            const promise = new Promise((resolve, reject) => {
                callback = msg => (msg ? reject(msg) : resolve(true));
            });
            this.validateStatus = 'inValidate';
            this.showMessage = false;
            const rules = this.getRules(trigger);
            if (!rules.length) {
                // 没有验证规则就直接通过
                callback();
            } else {
                const validator = new ValidatorSchema({
                    [this.prop]: rules,
                });
                validator.validate(
                    {
                        [this.prop]: this.fieldValue,
                    },
                    { firstFields: true },
                    (errors /* , fields */) => {
                        // console.log(fields);
                        this.validateStatus = !errors ? 'success' : 'error';
                        this.validateMessage = errors ? errors[0].message : '';
                        callback(this.validateMessage);
                    },
                );
            }
            return promise;
        },
        clearValidate() {
            this.validateStatus = '';
            this.validateMessage = '';
        },
        resetField() {
            const { model } = this.Form;
            const propPath = this.prop;
            if (!model || !propPath) return;
            // TODO 完成这里的 表单 重置
            const propArr = propPath.split('.');
            const lng = propArr.length - 1;
            propArr.reduce((pre, curr, index) => {
                if (index !== lng) {
                    return pre[curr];
                }
                // console.log(propPath, this.initFieldValue);
                // eslint-disable-next-line no-param-reassign
                pre[curr] = this.initFieldValue;
                return null;
            }, model);
            this.$nextTick(() => {
                this.clearValidate();
            });
        },
        onError() {
            this.showMessage = true;
        },
    },
    created() {
        this.Form.addField(this);
    },
    mounted() {
        let initFieldValue = this.fieldValue;
        if (typeof initFieldValue === 'object') {
            initFieldValue = JSON.parse(JSON.stringify(initFieldValue));
        }
        Object.defineProperty(this, 'initFieldValue', {
            value: initFieldValue,
        });
    },
    beforeDestroy() {
        this.Form.removeField(this);
    },
};
</script>

<style lang="scss">
.form-item {
    padding: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #fff;

    > .header {
        order: 0;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-height: $formItemHeight;
        max-width: 50%;
        &,
        > .x-icon {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
        > .x-label {
            flex: 1 1 1%;
            > .label,
            > .sub-label {
                @include text-line(1);
            }
        }
    }
    > .body {
        min-height: $formItemHeight;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        order: 1;
        flex: 1 1 1%;
        word-break: break-all;
        // line-height: $formItemHeight;
    }
    > .extra {
        width: 100%;
        order: 3;
        align-self: center;
    }
    > .feedback {
        order: 2;
        flex: 0 0 auto;
        min-height: $formItemHeight;
        margin-left: $margin-small;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // 明文显示提示
        &.show {
            order: 4;
            width: 100%;
            display: block;
            min-height: auto;
            margin-left: 0;
        }
        > .x-icon {
            vertical-align: inherit;
        }
    }

    &.column {
        > .header {
            flex: 1 1 1%;
            max-width: none;
            margin-right: 0;
        }
        > .body {
            flex: 0 0 auto;
            width: 100%;
        }
        > .extra {
            order: 0;
        }
        > .feedback {
            order: 0;
            &.show {
                order: 2;
            }
        }
    }
    &.right:not(.column) {
        > .body {
            &,
            > .x-select.tiling > .inner {
                justify-content: flex-end;
            }
            text-align: right;
        }
    }
}
</style>
