/**
 * Created by henian.xu on 2020/4/8.
 *
 */

import Vue from 'vue';
import { Api } from 'api';
import { GlobalVar } from 'utils/GlobalVar';
import Router from 'router';
import { Valid } from 'utils';

const editFormModelJson = JSON.stringify({
    id: '',
    isDefault: false,
    consignee: GlobalVar.isDev ? 'xhn' : '',
    mobile: GlobalVar.isDev ? '13812345678' : '',
    regionId: GlobalVar.isDev ? '1319' : '',
    address: GlobalVar.isDev ? '就在这里' : '',
});
let choosePromise = null;
export const state = Vue.observable({
    listData: [],
    listDataMap: {},
    pagination: {},
    editFormModel: JSON.parse(editFormModelJson),
    editFormRules: {
        consignee: {
            required: true,
            message: '不能为空',
        },
        mobile: {
            validator: Valid.mobile,
            message: '手机号码格式不确认',
        },
        regionId: {
            required: true,
            message: '不能为空',
        },
    },
});

export async function getDetail(id) {
    const { listData } = state;
    let data = null;
    listData.some(item => {
        const b = `${item.id}` === `${id}`;
        if (b) data = item;
        return b;
    });
    if (!data) {
        const res = await Api.Member.Mb.DeliveryAddress.detail(id);
        data = res.data;
    }
    return data;
}
export async function resetEditFormModel(id) {
    state.editFormModel = JSON.parse(editFormModelJson);
    if (!id) return;
    const data = await getDetail(id);
    state.editFormModel = {
        id: data.id,
        isDefault: data.isDefault,
        consignee: data.consignee,
        mobile: data.phone,
        regionId: data.regionId,
        address: data.address,
    };
}
export async function getListData(query) {
    const params = {
        ...query,
    };
    const res = await Api.Member.Mb.DeliveryAddress.list(params);
    const { pagination, data } = res;
    if (pagination.currentPage === 1) state.listData = [];
    state.pagination = pagination;
    state.listData.push(...data);
    return res;
}
export async function saveAddress(query) {
    const res = await Api.Member.Mb.DeliveryAddress.save(query);
    const { id } = query;
    const { listData } = state;
    if (id) {
        let index = -1;
        listData.some((item, i) => {
            const b = `${item.id}` === `${id}`;
            if (b) {
                index = i;
            }
            return b;
        });
        if (index !== -1) {
            listData.splice(index, 1, res.data);
        } else {
            listData.unshift(res.data);
        }
    } else {
        listData.unshift(res.data);
    }
    await resetEditFormModel();
    return res;
}
export async function deleteAddress(ids) {
    const res = await Api.Member.Mb.DeliveryAddress.Delete(ids);
    const { listData } = state;
    const idList = `${ids}`.split(',').filter(Boolean);
    const indexList = [];
    const idListLen = idList.length;
    const len = listData.length;
    for (let i = 0; i < len; i += 1) {
        const item = listData[i];
        if (idList.includes(`${item.id}`)) {
            const n = indexList.push(i);
            if (n >= idListLen) break;
        }
    }
    indexList.sort((a, b) => b - a).forEach(index => {
        listData.splice(index, 1);
    });
    return res;
}
export async function choose() {
    const { pagination } = state;
    console.log(11, pagination.totalPages);
    if (!pagination.totalPages) await getListData();
    const {
        pagination: { totalPages },
    } = state;
    console.log(totalPages);
    const pathName = totalPages ? 'list' : 'edit';
    return new Promise((resolve, reject) => {
        choosePromise = { resolve, reject };
        Router.push(`/deliveryAddress/${pathName}?isPicker`);
    });
}
export function handlerChoose(data) {
    if (choosePromise) {
        if (data) {
            choosePromise.resolve({ ...data });
        } else {
            choosePromise.reject();
        }
    }
    choosePromise = null;
}
