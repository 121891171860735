/**
 * Created by henian.xu on 2020/1/16.
 *
 */

export default {
    data() {
        return {
            scrollData: {
                top: 0,
                left: 0,
                height: 0,
                width: 0,
            },
        };
    },
    computed: {
        $$scrollbar() {
            return this.$refs.scrollbar;
        },
    },
    methods: {
        recordScrollBarPosition() {
            const { $$scrollbar, scrollData } = this;
            if (!$$scrollbar) return;
            scrollData.top = $$scrollbar.scrollTop;
            scrollData.left = $$scrollbar.scrollLeft;
            scrollData.height = $$scrollbar.scrollHeight;
            scrollData.width = $$scrollbar.scrollWidth;
        },
        recoverScrollBarPosition() {
            const { $$scrollbar, scrollData } = this;
            if (!$$scrollbar) return;
            $$scrollbar.scrollTop = scrollData.top;
            $$scrollbar.scrollLeft = scrollData.left;
        },
    },
    activated() {
        this.recoverScrollBarPosition();
    },
    deactivated() {
        this.recordScrollBarPosition();
    },
};
