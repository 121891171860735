/**
 * Created by henian.xu on 2019/3/6.
 *
 */

import { getUniqueId } from 'utils';

export default {
    inject: { FormItem: { default: () => ({}) } },
    props: {
        id: {
            type: String,
            default() {
                return getUniqueId(`field-`);
            },
        },
        placeholder: {
            type: String,
            default: '请输入...',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: null,
        },
        isClear: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        __attrProps() {
            const { id, placeholder, readonly, disabled, $attrs } = this;
            return { ...$attrs, id, placeholder, readonly, disabled };
        },
        disabled_() {
            const { disabled } = this;
            const { FormItem } = this;
            if (!FormItem) return disabled;
            const { disabled_: disabledForm } = FormItem;
            return disabled !== null ? disabled : disabledForm;
        },
        readonly_() {
            const { readonly } = this;
            const { FormItem } = this;
            if (!FormItem) return readonly;
            const { readonly_: readonlyForm } = FormItem;
            return readonly !== null ? readonly : readonlyForm;
        },
        isDisabled() {
            return this.readonly_ || this.disabled_;
        },
    },
};
