<!-- Created by henian.xu on 2019/10/28. -->

<template>
    <div
        :class="classes"
        v-show="!disabled&& !(noShowComplete && status === statusMap.COMPLETE)"
    >
        <template v-if="auto">
            <div
                v-if="isSemi"
                class="pagination-semi"
                v-show="status === statusMap.READY"
                @click="onSemi"
            >
                <slot name="semi">
                    <XButton
                        label="加载更多"
                        radius="all"
                    />
                </slot>
            </div>
            <div
                v-show="status === statusMap.LOADING || (!isSemi && status === statusMap.READY)"
                class="pagination-loading"
            >
                <slot name="loading">
                    加载中...
                </slot>
            </div>
            <div
                v-show="status === statusMap.COMPLETE"
                class="pagination-complete"
            >
                <slot name="complete">
                    没有更多了
                </slot>
            </div>
            <div
                v-show="status === statusMap.NOTHING"
                class="pagination-nothing"
            >
                <slot name="nothing">
                    暂无数据
                </slot>
            </div>
        </template>
        <template v-else>
            <XButton
                icon="f011"
                :disabled="model===1"
                @click="onGoto(1)"
            />
            <XButton
                v-for="item in pageList"
                :key="item.value"
                :label="item.label"
                :theme="model===item.value?'main':''"
                @click="onGoto(item.value)"
            />
            <XButton
                icon="f012"
                :disabled="model===totalPages"
                @click="onGoto(totalPages)"
            />
        </template>
    </div>
</template>

<script>
import autoMixin from './autoMixin';

export default {
    name: 'Pagination',
    mixins: [autoMixin],
    props: {
        value: {
            // 等于 currentPage
            type: Number,
            default: null,
        },
        totalPages: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                const { value, count } = this;
                if (value === null) return count;
                return value;
            },
            set(val) {
                const { value } = this;
                if (value === null) {
                    this.count = val;
                } else {
                    this.$emit('input', val);
                }
            },
        },
        classes() {
            const { auto } = this;
            return ['pagination', { auto }];
        },
        pageList() {
            const { auto, model, totalPages } = this;
            if (auto || !model) return [];
            const num = 5;
            let first = Math.min(Math.max(Math.ceil(model - num / 2), 1), totalPages - num + 1);
            const res = Array(num)
                .fill(1)
                .map((item, index) => ({
                    label: first + index,
                    value: first + index,
                }));
            first = res[0].value;
            if (first > num) {
                res.unshift({
                    label: '...',
                    value: Math.max(first - num, 1),
                });
            }
            const last = res[res.length - 1].value;
            if (last < totalPages - num) {
                res.push({
                    label: '...',
                    value: Math.min(last + num, totalPages),
                });
            }
            return res;
        },
    },
    methods: {
        onGoto(val) {
            this.model = val;
        },
    },
    created() {
        if (!this.auto) {
            this.model = Math.max(this.model, 1);
        }
    },
};
</script>

<style lang="scss">
.pagination {
    padding: $padding;

    .pagination-semi,
    .pagination-loading,
    .pagination-complete,
    .pagination-nothing {
        text-align: center;
    }

    &:not(.auto) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        > .x-button {
            margin-left: -1px;
            flex: 1 1 1%;
            padding-left: 0;
            padding-right: 0;
            min-height: $formItemHeight;
        }
    }
}
</style>
