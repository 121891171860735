/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get, post } from 'api/request';

export function memberDetail() {
    return get(this.url);
}
export function createFeedback({ feedbackType, content, feedbackImage }) {
    return post(this.url, { feedbackType, content, feedbackImage });
}
export function realNameAuth({ idCardType, fullName, idCard }) {
    return post(this.url, { idCardType, fullName, idCard });
}
