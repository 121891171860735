/**
 * Created by henian.xu on 2020/5/7.
 *
 */

export default {
    methods: {
        getRefsChildren: (() => {
            const cacheResolve = [];
            let timer = null;
            return function handler(ref) {
                return new Promise(resolve => {
                    let refInstance = this.$refs[ref];
                    if (refInstance) {
                        resolve(refInstance);
                        return;
                    }
                    cacheResolve.push(resolve);
                    if (timer) return;
                    timer = setInterval(() => {
                        console.log('getRefsChildren');
                        refInstance = this.$refs[ref];
                        if (!refInstance) return;
                        clearInterval(timer);
                        timer = null;
                        cacheResolve.forEach(fn => fn(refInstance));
                    }, 200);
                });
            };
        })(),
    },
};
