<!-- Created by henian.xu on 2019/3/28. -->

<template>
    <div class="picker">
        <template v-for="(item, index) in options">
            <Pulley
                :key="index"
                :data="item"
                :prop="prop_"
                v-model="model[index]"
                @input="value => onInput(index, value)"
            />
        </template>
        <!--<div class="console">
            {{ model }}
        </div>-->
    </div>
</template>

<script>
import Pulley from './Pulley.vue';

export default {
    name: 'Picker',
    components: { Pulley },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
        prop: { type: Object, default: () => ({ id: 'id', name: 'name' }) },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        deleteRemaining: { type: Boolean, default: false },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        prop_() {
            return {
                id: 'id',
                name: 'name',
                ...this.prop,
            };
        },
        options() {
            const { data } = this;
            let typeSign = 0; // 元素数据类型 0:无;1:数组;2:其它;
            return data.reduce((pre, curr) => {
                if ((typeSign === 1 && !Array.isArray(curr)) || (typeSign === 2 && Array.isArray(curr))) {
                    throw new Error('[Pricker] data 数组每个元素应该统一为数组或其它类型');
                }
                typeSign = Array.isArray(curr) ? 1 : 2;
                if (typeSign === 1) {
                    pre.push(curr);
                } else {
                    if (!pre.length) {
                        pre.push([]);
                    }
                    pre[0].push(curr);
                }
                return pre;
            }, []);
        },
    },
    watch: {
        options: {
            handler(val) {
                if (!val || !val.length || (this.model && this.model.length >= val.length)) return;
                const value = [];
                val.forEach((item, index) => {
                    const pulleyItem = item && item.length ? item[0] : '';
                    let defaultVal = '';
                    if (typeof pulleyItem === 'string' || typeof pulleyItem === 'number') {
                        defaultVal = pulleyItem;
                    } else if (pulleyItem[this.prop_.id] !== undefined) {
                        defaultVal = pulleyItem[this.prop_.id];
                    }
                    value.push(this.model[index] || defaultVal);
                });
                // console.log(value);
                this.model = value;
            },
            immediate: true,
        },
    },
    methods: {
        onInput(index, val) {
            const { deleteRemaining, model } = this;
            const value = [...model];
            value.splice(index, deleteRemaining ? value.length : 1, val);
            this.model = value;
        },
    },
};
</script>

<style lang="scss">
.picker {
    flex: 1 1 1%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;

    > .console {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
    }
}
</style>
