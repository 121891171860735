<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <div :class="classes">
        <div class="input">
            <div
                v-if="iconText"
                class="text-icon">
                <XIcon
                    v-if="isCorrect"
                    content="f017"
                />
                <XIcon
                    v-else-if="isError"
                    content="f018"
                    size="small"
                />
                <template v-else>{{iconText}}</template>
            </div>
            <XIcon
                v-else
                :content="icon" />
            <input
                :type="type"
                v-bind="__attrProps"
                v-model="model"
                :value="value" />
        </div>
        <label :for="id">
            <XLabel v-bind="labelProps">
                <slot/>
            </XLabel>
        </label>
    </div>
</template>

<script>
import { formMixin, labelMixin } from 'packages/mixins';

export default {
    name: 'ExamCheckbox',
    mixins: [labelMixin, formMixin],
    model: {
        prop: 'checked',
        // event: 'change',
    },
    data() {
        return {
            appliedToLabelScopedSlots: true,
        };
    },
    props: {
        /* eslint-disable vue/require-prop-types */
        /* eslint-disable vue/require-default-prop */
        value: {},
        answerModel: {},
        checked: {},
        type: {
            validator(value) {
                return /(checkbox|radio)/.test(value);
            },
            default: 'checkbox',
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        iconText: {
            type: String,
            value: '',
        },
        isAnalysis: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.checked;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        isCheckbox() {
            return this.type === 'checkbox';
        },
        isRadio() {
            return this.type === 'radio';
        },
        isChecked() {
            const { model, value, isCheckbox } = this;
            if (Array.isArray(model) && isCheckbox) {
                return model.some(item => item === value);
            }
            if (isCheckbox) {
                if (typeof model !== 'boolean') {
                    // throw new Error('[Checkbox]: type="checkbox" model 类型应该为 Boolean/Array');
                }
                return model;
            }
            if (typeof model === 'object') {
                // throw new Error('[Checkbox]: type="radio" model 类型应该为 String/Number');
            }
            return model === value;
        },
        isAnswerChecked() {
            const { isAnalysis, answerModel: model, value, isCheckbox } = this;
            if (!isAnalysis) return false;
            if (Array.isArray(model) && isCheckbox) {
                return model.some(item => item === value);
            }
            if (isCheckbox) {
                if (typeof model !== 'boolean') {
                    // throw new Error('[Checkbox]: type="checkbox" model 类型应该为 Boolean/Array');
                }
                return model;
            }
            if (typeof model === 'object') {
                // throw new Error('[Checkbox]: type="radio" model 类型应该为 String/Number');
            }
            return model === value;
        },
        icon() {
            const { isChecked, isCheckbox } = this;
            const checked = isCheckbox ? 'f026' : 'f019';
            const normal = isCheckbox ? 'f025' : 'f01b';
            return isChecked ? checked : normal;
            // if (isCheckbox) return isChecked ? 'f026' : 'f025';
            // return isChecked ? 'f019' : 'f01b';
        },
        classes() {
            const { reverse, disabled, isChecked, isAnalysis, isAnswerChecked } = this;
            return [
                'exam-checkbox',
                { reverse },
                { disabled },
                { checked: isChecked },
                { 'answer-checked': isAnalysis && isAnswerChecked },
                { correct: isAnalysis && isChecked && isAnswerChecked },
                { error: isAnalysis && isChecked && !isAnswerChecked },
                // add
            ];
        },
        isCorrect() {
            const { isAnalysis, isAnswerChecked, isChecked } = this;
            if (!isAnalysis) return false;
            return isChecked && isAnswerChecked;
        },
        isError() {
            const { isAnalysis, isAnswerChecked, isChecked } = this;
            if (!isAnalysis) return false;
            return isChecked && !isAnswerChecked;
        },
    },
};
</script>

<style lang="scss">
$size: 50px !default;
.exam-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > .input {
        flex: 0 0 auto;
        position: relative;
        width: $size;
        height: $size;
        > .x-icon {
            font-size: $size;
        }
        > .text-icon {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-text;
            border-radius: 100vw;
        }
        > input {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            appearance: none;
            outline: none;
        }
    }
    > label {
        flex: 1;
        margin-left: $margin-small;
        min-height: $formItemHeight;
        padding: $padding-big * 1.5 0;
    }
    &.reverse {
        > .input {
            order: 1;
        }
        > label {
            margin-left: 0;
            margin-right: $margin-small;
        }
    }
    &.disabled {
        //opacity: 0.5;
    }

    &.checked {
        > .input {
            > .text-icon {
                background-color: $color-text;
                border-color: $color-text;
                color: #fff;
            }
        }
    }
    &.error {
        > .input {
            > .text-icon {
                background-color: $color-danger;
                border-color: $color-danger;
                color: #fff;
            }
        }
    }
    &.answer-checked {
        > .input {
            > .text-icon {
                background-color: $color-primary;
                border-color: $color-primary;
                color: #fff;
            }
        }
    }
    &.correct {
        > .input {
            > .text-icon {
                background-color: $color-success;
                border-color: $color-success;
                color: #fff;
            }
        }
    }
}
</style>
