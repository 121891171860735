<!-- Created by henian.xu on 2019/4/4. -->

<template>
    <div :class="classes">
        <div
            :class="['out-show',{noop:!model}]"
            @click="onSwitch"
        >
            <div class="label">{{ model || placeholder }}</div>
            <XIcon
                content="f012"
                theme="g5"
                size="small" />
        </div>

        <!--popup-->
        <transition name="show">
            <div
                ref="popup"
                class="popup popup-date-picker down"
                v-show="popupShow"
                @click.self="onSwitch">
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="popupShow">
                        <div class="header">
                            <!--<XButton
                                label="取消"
                                theme="danger"
                                link
                                @click="close"
                            />-->
                            <XButton
                                label="清空"
                                theme="primary"
                                link
                                @click="onClear"
                            />
                            <XButton
                                v-if="isSoFar !== null"
                                label="至今"
                                theme="second"
                                link
                                @click="onSoFar"
                            />
                            <XLabel
                                v-else
                                :label="currentDate || '请选择'" />
                            <XButton
                                label="确认"
                                theme="success"
                                link
                                @click="onConfirm"
                            />
                        </div>
                        <Date
                            :format="format"
                            v-model="currentDate"
                            :start-date="startDate"
                            :end-date="endDate" />
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import moment from 'dayjs';
import { isString } from '@/utils';
import { popupMixin, formMixin } from '../mixins';
import Date from './Date.vue';

const DATE_PARTICULAR_VALUES = {
    SO_FAR: -1,
};

export default {
    name: 'DatePicker',
    mixins: [formMixin, popupMixin],
    components: { Date },
    data() {
        return {
            inPage: false,
            currentDate: '',
            DATE_PARTICULAR_VALUES,
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        format: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        startDate: {
            type: [String, Number],
            default: undefined,
        },
        endDate: {
            type: [String, Number],
            default: undefined,
        },
        isSoFar: {
            type: [Boolean, Number],
            default: null,
        },
    },

    computed: {
        model: {
            get() {
                const { value, isSoFar } = this;
                if (!value) return '';
                if (isSoFar !== null && value === this.DATE_PARTICULAR_VALUES.SO_FAR) return '至今';
                let format;
                if (isString(value)) format = this.format;
                const now = moment(value, format);
                return now.format(format);
            },
            set(val) {
                this.currentDate = val;
            },
        },
        classes() {
            const { isDisabled } = this;
            return ['date-picker', { disabled: isDisabled }];
        },
    },
    watch: {
        popupShow(val) {
            const { value } = this;
            if (!val || (value && value < 0)) return;
            this.currentDate = value;
        },
    },
    methods: {
        onConfirm() {
            this.$emit('input', this.currentDate);
            this.$emit('update:is-so-far', 0);
            this.close();
        },
        onClear() {
            this.$emit('input', '');
            this.$emit('update:is-so-far', 0);
            this.close();
        },
        onSoFar() {
            this.$emit('input', this.DATE_PARTICULAR_VALUES.SO_FAR);
            this.$emit('update:is-so-far', 1);
            this.close();
        },
    },
};
</script>

<style lang="scss">
.date-picker {
    flex: 1 1 1%;

    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        //padding: 0 $padding-small;
        line-height: $formItemHeight;
        > .label {
            flex: 1;
        }

        &.noop {
            color: $gray6;
        }
    }
}

.popup.popup-date-picker {
    z-index: $z-index-7 + 100;

    > .inner {
        > .header {
            border-bottom: 1px solid $color-border;
        }
    }
}
</style>
