/**
 * Created by henian.xu on 2019/2/28.
 * XLabel 标签混合器
 */

export default {
    data() {
        return {
            appliedToLabelScopedSlots: false, // true:让 XLabel 组件直接绑定当前组件的插槽
        };
    },
    props: {
        label: {
            type: [String, Number],
            default: '',
        },
        subLabel: {
            type: [String, Number],
            default: '',
        },
    },
    computed: {
        scopedSlots__() {
            const { appliedToLabelScopedSlots } = this;
            return appliedToLabelScopedSlots ? { ...this.$scopedSlots, default: undefined } : {};
        },
        labelProps() {
            const { theme, label, subLabel, scopedSlots__: scopedSlots } = this;
            return { theme, label, subLabel, scopedSlots };
        },
        hasLabel() {
            const { label, subLabel, scopedSlots__: scopedSlots } = this;
            const isLabel = label || `${label}` === '0';
            const isSubLabel = subLabel || `${subLabel}` === '0';
            return !!(isLabel || isSubLabel || scopedSlots.default || scopedSlots.label || scopedSlots.subLabel);
        },
    },
};
