/**
 * Created by henian.xu on 2020/1/6.
 *
 */

export default {
    data() {
        return {
            listData_: [],
            paginationDisabled: false,
            pagination_: {},
        };
    },
    computed: {
        listData: {
            get() {
                return this.listData_;
            },
            set(val) {
                this.listData_ = val;
            },
        },
        pagination: {
            get() {
                return this.pagination_;
            },
            set(val) {
                this.pagination_ = val;
            },
        },
    },
    methods: {
        async onload({ next, statusMap }) {
            const { currentPage: oldCurrentPage, pageSize: oldPageSize } = this.pagination;
            const { pagination } = await this.getListData({
                currentPage: oldCurrentPage ? oldCurrentPage + 1 : 1,
                pageSize: oldPageSize,
            });
            this.pagination = pagination;
            const { currentPage, totalPages } = pagination;
            if (totalPages <= 0) next(statusMap.NOTHING);
            else if (currentPage >= totalPages) next(statusMap.COMPLETE);
            else next(statusMap.READY);
        },
        refreshList() {
            this.paginationDisabled = true;
            this.listData = [];
            this.pagination = {};
            this.$nextTick(() => {
                this.paginationDisabled = false;
            });
        },
    },
};
