<!-- Created by henian.xu on 2019/3/12. -->

<template>
    <div :class="classes">
        <div
            :class="['header', isFold ? 'fold' : '']"
            v-bind="linkerProps"
            v-on="$listeners">
            <div class="inner">
                <XIcon
                    v-if="fold"
                    class="fold-icon"
                    :content="isFold ? 'f010' : 'f00e'"
                    @click="onFold" />
                <slot name="icon">
                    <XIcon v-bind="iconProps" />
                </slot>
                <XLabel v-bind="labelProps" />
                <slot name="extra">
                    <div class="extra">
                        {{ extra }}
                    </div>
                </slot>
                <slot name="badge">
                    <Badge
                        v-if="badge"
                        :content="icon"
                        :theme="theme"
                        :label="badge"
                        size="small" />
                </slot>
                <XIcon
                    v-if="isLink"
                    class="link"
                    content="f012"
                    theme="g5"
                    size="small" />
            </div>
        </div>
        <slot
            name="body"
            v-show="!isFold">
            <div
                class="body"
                v-show="!isFold">
                <slot />
            </div>
        </slot>
    </div>
</template>

<script>
import { linkerMixin, iconMixin, labelMixin, themeMixin } from '../mixins';

export default {
    name: 'Panel',
    mixins: [labelMixin, iconMixin, linkerMixin, themeMixin],
    data() {
        return {
            labelScopedSlots__: false,
            isFold: false,
        };
    },
    props: {
        badge: {
            type: String,
            default: '',
        },
        extra: {
            type: String,
            default: '',
        },
        link: {
            type: Boolean,
            default: false,
        },
        fold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { theme } = this;
            return ['panel', theme];
        },
        isLink() {
            const { to, link } = this;
            return link || !!to;
        },
    },
    methods: {
        onFold() {
            this.isFold = !this.isFold;
        },
    },
};
</script>

<style lang="scss">
.panel {
    > .header {
        padding: 0 $padding;
        background-color: #fff;

        > .inner {
            padding: $padding 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //border-bottom: 1px solid $color-border;

            > .extra,
            > .badge,
            > .x-icon {
                flex: 0 0 auto;
                margin-left: $margin-small;
            }

            > .x-icon:not(.link) {
                margin-left: 0;
                margin-right: $margin-small;
            }

            > .x-icon,
            > .x-label {
                vertical-align: middle;
            }

            > .x-label {
                flex: 1 1 1%;
                //min-height: $formItemHeight;
            }

            > .extra {
                color: $gray6;
            }

            > .fold-icon {
                background-color: $color-main;
                color: #fff;
                padding: $padding-small;
                font-size: 50%;
                border-radius: 100vw;
            }
        }

        &.fold {
            > .inner {
                border-bottom: none;
            }
        }
    }
    > .body {
        background-color: #fff;
        overflow: hidden;
        //padding: $padding;
    }
}
</style>
