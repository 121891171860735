/**
 * Created by henian.xu on 2020/4/2.
 *
 */

import { get, post } from 'api/request';

/**
 * 获取支付相关信息
 * @param module
 * @param orderIds
 * @returns {Promise<*>}
 */
export function toPay({ module, orderIds }) {
    return get(this.url, { module, orderIds });
}

export function isPay({ module, paymentId }) {
    return get(
        this.url,
        { module, paymentId },
        {
            isHandleError: true,
        },
    );
}

/**
 * 微信支付
 * @param module
 * @param orderIds
 * @returns {Promise<*>}
 */
export function payforWeixin({ module, orderIds }) {
    return post(this.url, { module, orderIds });
}
export function payforWeixinByScanCode({ module, orderIds }) {
    return post(this.url, { module, orderIds });
    /* return new Promise(resolve => {
        resolve({
            data: {
                QRCodeUrl: `${module}:${orderIds}`,
                paymentAmount: '888',
                payment: {
                    module,
                    paymentId: 1,
                    paymentSn: 'sn123456',
                },
            },
        });
    }); */
}

/**
 * 零钱支付
 * @param module
 * @param orderIds
 * @returns {Promise<*>}
 */
export function payforBalance({ module, orderIds }) {
    return post(this.url, { module, orderIds });
}

/**
 * 确认支付是否成功 (由于支付异步延迟而存在)
 * @param module
 * @param paymentSn
 * @returns {Promise<*>}
 */
export function checkPayment({ module, paymentSn }) {
    return get(this.url, { module, paymentSn });
}
