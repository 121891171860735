<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <XForm
        class="cert-cat-picker"
        v-if="optionList && optionList.length">
        <FormItem
            v-for="(option,index) in optionList"
            :key="index"
        >
            <XSelect
                :data="option"
                :value="valueList[index+1]"
                @input="onSelectInput($event,index+1)"
            />
        </FormItem>
    </XForm>
</template>

<script>
import { isDef } from '@/utils';
import { state, getChild } from './store';

export default {
    name: 'CertCatPicker',
    data() {
        return {
            valueList: [],
            optionList: [],
        };
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        pId: {
            type: String,
            default: '',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        modelStr() {
            const { model } = this;
            const { childMap } = state;
            console.log(childMap, childMap[model]);
            return (childMap[model] || {}).name || '';
        },
    },
    watch: {
        pId: {
            handler(val) {
                if (!isDef(val)) return;
                this.valueList = [val];
            },
            immediate: true,
        },
        valueList: {
            handler(val) {
                if (!val || !val.length) return;
                this.buildOptions();
                this.onConfirm();
            },
            immediate: true,
        },
    },
    methods: {
        async buildOptions() {
            const { valueList } = this;
            this.optionList = await valueList.reduce(async (pre, cur) => {
                const list = await pre.then();
                const data = await getChild(cur);
                if (data && data.length) {
                    list.push(data);
                    this.$emit('last-item', false);
                } else {
                    this.$emit('last-item', true);
                }
                return list;
            }, Promise.resolve([]));
        },
        onSelectInput(val, index) {
            const { valueList } = this;
            if (valueList[index] === val) return;
            // console.log(val, index);
            if (val) {
                valueList.splice(index, valueList.length, val);
            } else {
                valueList.splice(index, valueList.length);
            }
        },
        onReset() {
            this.valueList = [this.pId];
        },
        onConfirm() {
            const { valueList, pId } = this;
            const value = [...valueList].pop();
            if (!value || value === pId) {
                this.model = '';
            } else {
                this.model = value;
            }
        },
        getItemName(val = this.model) {
            const { childMap } = state;
            // console.log(childMap, childMap[val]);
            return (childMap[val] || {}).name || '';
        },
    },
};
</script>

<style lang="scss">
.cert-cat-picker {
    flex: 1;
}
</style>
