<!-- Created by henian.xu on 2020/4/10. -->

<template>
    <div class="answer-card">
        <div
            class="out-show"
            @click="onSwitch"
        >
            <XIcon
                class="icon"
                content="f01c"
                theme="g6"
            ></XIcon> {{index_ + 1}} / {{data.length}}
        </div>

        <!--popup-->
        <transition name="show">
            <div
                ref="popup"
                v-show="popupShow"
                class="popup down popup-answer-card"
                @click.self="close"
            >
                <transition name="popup-down">
                    <div
                        class="inner"
                        v-show="popupShow"
                    >
                        <div class="header">
                            <span class="pa-a">{{index_ + 1}} / {{data.length}}</span>
                            <XLabel
                                :label="label_"
                                :sub-label="subLabel_"
                            />
                            <XButton
                                v-if="showSubmit"
                                theme="main"
                                label="交卷"
                                link
                                @click="()=>{onSubmit()}"
                            />
                            <div
                                v-else
                                class="pa-a"
                            >
                                <XIcon content="f000"></XIcon>
                                <XIcon content="f000"></XIcon>
                            </div>
                        </div>
                        <div class="body">
                            <div
                                v-for="(item,i) in data"
                                :key="i"
                                :class="getItemClasses(item,i)"
                                @click="onItem(item,i)"
                            >
                                <div class="inner">
                                    <div class="content">
                                        <div
                                            v-if="item"
                                            class="answer">{{getAnswerStr(item,i)}}</div>
                                        <div class="inner">{{i+1}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { popupMixin, labelMixin } from 'packages/mixins';
// import { hasOwn } from 'utils/index';

export default {
    name: 'AnswerCard',
    mixins: [popupMixin, labelMixin],
    data() {
        return {};
    },
    props: {
        value: {
            type: Array,
            default: null,
        },
        data: {
            type: Array,
            default: () => [],
        },
        index: {
            type: Number,
            default: 0,
        },
        isAnalysis: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        index_() {
            return this.index;
        },
        model: {
            get() {
                const { value, data } = this;
                if (value !== null) return value;
                return data.map(item => {
                    const { id, selectOption } = item;
                    return {
                        id,
                        selectOption: selectOption || 0,
                    };
                });
            },
            /* set(val) {
                if (this.value === null) return;
                this.$emit('input', val);
            }, */
        },
        unanswered() {
            const { data, model } = this;
            return data.reduce((pre, cur) => {
                const { id } = cur || {};
                return pre + (model[id] ? 0 : 1);
            }, 0);
        },
        label_() {
            const { label } = this;
            return label || '答题卡';
        },
        subLabel_() {
            const { label, unanswered, isAnalysis } = this;
            return label || `${isAnalysis ? '' : '还'}有 ${unanswered} 题未答`;
        },
        showSubmit() {
            return !!this.$listeners.submit;
        },
    },
    methods: {
        getAnswerStr(item, index) {
            const { selectOption } = this.model[index] || {};
            const { optionList } = this.data[index] || {};
            if (!selectOption || !optionList) return '';
            return optionList
                .reduce((pre, cur) => {
                    const { option, text } = cur;

                    if (option & selectOption) pre.push(text);
                    return pre;
                }, [])
                .join(',');
        },
        getItemClasses(item, index) {
            const { index_, isAnalysis } = this;

            const res = [
                'item',
                { active: index_ === index },
                /* isAnalysis && item.selectOption
                    ? item.selectOption === item.questionAnswer
                        ? 'correct'
                        : 'error'
                    : '', */
            ];

            if (isAnalysis && item.selectOption) {
                res.push({ correct: item.selectOption === item.questionAnswer });
                res.push({ error: item.selectOption !== item.questionAnswer });
            } else if (!isAnalysis) {
                const { selectOption } = this.model[index] || {};
                res.push({ correct: selectOption });
            }

            return res;
        },
        onItem(item, index) {
            this.$emit('index-change', index);
            this.close();
        },
        async onSubmit() {
            await this.close();
            this.$emit('submit');
        },
    },
};
</script>

<style lang="scss">
.answer-card {
    > .out-show {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        line-height: 1;
        > .x-icon {
            margin-right: $margin-small;
        }
    }
}
.popup.popup-answer-card {
    > .inner {
        > .body {
            padding: $padding-small;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            > .item {
                flex: 0 0 auto;
                width: (100%/5);
                padding: $padding-small;

                > .inner {
                    width: 100%;
                    padding-top: 100%;
                    height: 0;
                    position: relative;
                    background-color: $gray3;
                    border-radius: $padding;
                    > .content {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        > .answer {
                            position: absolute;
                            z-index: $z-index-1;
                            top: 0;
                            right: 0;
                            width: 100%;
                            white-space: normal;
                            word-break: break-all;
                            padding: 0 $padding-small;
                            border-radius: $padding;
                            background-color: rgba(0, 0, 0, 0.2);
                            color: #fff;
                            opacity: 0.2;
                        }
                        > .index {
                            position: relative;
                            z-index: $z-index-2;
                        }
                    }
                }

                &.correct {
                    > .inner {
                        background-color: mix(#fff, $color-success, 50%);
                        color: #fff;
                    }
                }
                &.error {
                    > .inner {
                        background-color: mix(#fff, $color-danger, 50%);
                        color: #fff;
                    }
                }
                &.active {
                    > .inner {
                        background-color: mix(#fff, $color-primary, 50%);
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
