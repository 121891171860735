const qualityHtml = `<div class="prism-fullscreen-btn" style="float: right; margin-right: 10px; margin-top: 12px;"></div>`;
function parseDom(html) {
    const ele = document.createElement('div');
    ele.innerHTML = html;
    return ele.childNodes[0];
}
export class FullScreenComponent {
    constructor() {
        this._El = null;
        this._parent = null;
        this._player = null;
        this.html = parseDom(qualityHtml);
        this.Name = '全屏';
        this.Type = 'fullScreen';
        this.isFullscreen = false;
    }

    onClick() {
        this.isFullscreen = !this.isFullscreen;
        if (this.isFullscreen) {
            this.html.classList.add('fullscreen');
            this._El.classList.add('fullscreen');
            document.body.appendChild(this._El);
        } else {
            this.html.classList.remove('fullscreen');
            this._El.classList.remove('fullscreen');
            this._parent.appendChild(this._El);
        }
    }

    createEl(el, player) {
        this._player = player;
        this._El = el;
        this._parent = el.parentNode;
        const eleControlbar = this._El.querySelector('.prism-controlbar');
        const prismVolumeEl = eleControlbar.querySelector('.prism-volume');
        eleControlbar.insertBefore(this.html, prismVolumeEl);

        // eleControlbar.appendChild(this.html);
    }

    created() {
        this.html.addEventListener('click', e => this.onClick(e));
    }

    dispose() {
        if (this.isFullscreen) {
            this.html.classList.remove('fullscreen');
            this._El.classList.remove('fullscreen');
            if (this._parent) this._parent.appendChild(this._El);
        }
    }
    // ready() {}
}
