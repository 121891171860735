/**
 * Created by henian.xu on 2020/3/15.
 *
 */

import { addScript, addStyle } from '@/utils';

export async function loadAliplayer() {
    if (window.Aliplayer) return window.Aliplayer;
    await Promise.all([
        addScript('//g.alicdn.com/apsara-media-box/imp-web-player/2.25.1/aliplayer-h5-min.js'),
        addStyle('//g.alicdn.com/apsara-media-box/imp-web-player/2.25.1/skins/default/aliplayer-min.css'),
    ]);
    const { Aliplayer } = window;
    return Aliplayer;
}
