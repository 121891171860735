/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get } from 'api/request';

export function dicEntry(dicName) {
    return get(`${this.url}/${dicName}`, null, {
        isRepeat: true,
    });
}
