/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import Md5 from 'md5';
import { get, post } from 'api/request';

export function login({ account, password, verificationCode, validateCode }) {
    return post(this.url, {
        account,
        password: password ? Md5(password) : password,
        verificationCode: verificationCode || validateCode,
    });
}
export function logout() {
    return post(this.url);
}

export function modPwd({ password, oldPassword }) {
    return post(this.url, {
        password: password ? Md5(password) : password,
        oldPassword: oldPassword ? Md5(oldPassword) : oldPassword,
    });
}

export function resetPassword({ countryCodeId, account, password, jCaptcha, verificationCode, validateCode }) {
    return post(this.url, {
        countryCodeId,
        account,
        password: password ? Md5(password) : password,
        jCaptcha,
        verificationCode: verificationCode || validateCode,
    });
}
export function register({ countryCodeId, account, password, jCaptcha, verificationCode, validateCode }) {
    return post(this.url, {
        countryCodeId,
        account,
        password: password ? Md5(password) : password,
        jCaptcha,
        verificationCode: verificationCode || validateCode,
    });
}

export function checkAccount({ account }) {
    return get(
        this.url,
        { account },
        {
            isHandleError: true,
            isRepeat: true,
        },
    );
}
