/**
 * Created by henian.xu on 2020/4/1.
 *
 */

import { Api } from 'api/index';

export default {
    namespaced: true,
    state: {
        rawData: null,
    },
    getters: {
        servantCat(state) {
            const { rawData } = state;
            if (rawData === null) this.dispatch('updated');
            return rawData || [];
        },
        servantCatMap(state, getters) {
            const { servantCat } = getters;
            return servantCat.reduce((pre, cur, __index) => {
                pre[cur.id] = {
                    ...cur,
                    __index,
                };
                return pre;
            }, {});
        },
    },
    mutations: {
        // 设置原始数据
        _setRawData(state, data) {
            state.rawData = data;
        },
    },
    actions: {
        async updated({ commit }) {
            const res = await Api.Member.Gd.Goods.servantGoodsCatList();
            commit('_setRawData', res.data);
            return res;
        },
    },
};
