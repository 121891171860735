/**
 * Created by henian.xu on 2020/4/7.
 *
 */

import { get } from 'api/request';

export function previewApplyMaterials({ certExamApplyId, applyMaterialsId, applyMaterialsValue }) {
    return get(this.url, {
        certExamApplyId,
        applyMaterialsId,
        applyMaterialsValue,
    });
}

export function previewAdmitCard(admitCardId) {
    return get(this.url, { admitCardId });
}
