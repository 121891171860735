<!-- Created by henian.xu on 2020/4/15. -->

<template>
    <div class="experience-list">
        <CellGroup
            v-if="listData && listData.length"
            border
        >
            <Cell
                v-for="item in listData"
                :key="item.id"
                :label="item.examName"
                :to="`/experience/paper/${item.id}`"
            ></Cell>
        </CellGroup>
        <div
            v-else
            class="item noop"
        ><div class="label">暂无数据</div></div>
    </div>
</template>

<script>
export default {
    name: 'Experience',
    data() {
        return {
            listData: [],
        };
    },
    props: {
        ids: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        ids: {
            async handler(val) {
                const ids = val.join(',');
                if (!val || !val.length || !ids) return;
                const { data } = await this.$api.Member.Sv.ExamPaperExperience.examPaperExperienceList({ ids });
                this.listData.push(...data);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.experience-list {
    > .item {
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        + .item {
            border-top: 1px solid $color-border;
        }

        > .label {
            flex: 1;
        }

        &.noop {
            > .label {
                text-align: center;
                color: $gray6;
            }
        }
    }
}
</style>
