/**
 * Created by henian.xu on 2019/6/10.
 *
 */

// import MeiQia from '_MEIQIA';
// import loadMeiQia from './loadMeiQia';
import GlobalVar from 'globalVar';

const { userInfo } = GlobalVar;

export default new class MeiQiaControl {
    constructor() {
        // console.log('MeiQia class init');
        this.MeiQia = null;
        this.isShowPanel = false;
        this.meiQiaAllSet = false;
        // 未读消息
        this.unreadMsg = [];

        if (GlobalVar.meiQiaEntId) {
            // loadMeiQia(GlobalVar.meiQiaEntId).then(MeiQia => {
            this.MeiQia = this.loadMeiQia();
            this.MeiQia('entId', GlobalVar.meiQiaEntId);
            // 在这里开启手动模式（必须紧跟美洽的嵌入代码）
            this.MeiQia('manualInit');
            this.MeiQia('withoutBtn');
            this.initClient();
            this.initEvent();
            this.MeiQia('init');
            // });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    loadMeiQia(global = window, doc = document) {
        const name = '_MEIQIA';
        global[name] =
            global[name] ||
            function func() {
                // eslint-disable-next-line prefer-rest-params
                (global[name].a = global[name].a || []).push(arguments);
            };
        const scriptNode = doc.createElement('script');
        const s = doc.getElementsByTagName('script')[0];
        scriptNode.async = true;
        scriptNode.charset = 'UTF-8';
        scriptNode.src = 'https://static.meiqia.com/dist/meiqia.js?_=t';
        s.parentNode.insertBefore(scriptNode, s);
        return global[name];
    }

    initClient() {
        // 设置 clientId
        if (!userInfo) return;
        const clientId = `${userInfo.countryCode || ''}${userInfo.mobile || ''}`;
        if (clientId) this.MeiQia('clientId', clientId);
        this.MeiQia('metadata', {
            name: userInfo.distributorName || userInfo.mobile, // 名字
            tel: userInfo.mobile, // 电话
            // weixin: '', // 微信
            // qq: '', // QQ
            // email: '', // 邮箱
            // address: '', // 地址
            // gender: '', // 性别
            // age: '', // 年龄
            // weibo: '', // 微博
            // comment: '', // 备注
        });
    }

    initEvent() {
        // 初始化成功时的回调
        this.MeiQia('allSet', this.onAllSet.bind(this));
        // 获取未读消息
        this.MeiQia('getUnreadMsg', this.onUnreadMsg.bind(this));
    }

    onAllSet() {
        // console.log('onAllSet');
        this.MeiQia = window._MEIQIA;
        this.meiQiaAllSet = true;
    }

    onUnreadMsg(msg) {
        if (msg === 'hasBeenRead') {
            this.clearUnread();
            return;
        }
        if (!Array.isArray(msg)) {
            console.error('meiQia 返回的消息不是数组类型');
            return;
        }
        this.unreadMsg = this.unreadMsg.concat(msg);
    }

    /**
     * 清空未读消息
     */
    clearUnread() {
        this.unreadMsg = [];
    }

    showPanel() {
        this.MeiQia('showPanel');
        this.isShowPanel = true;
    }

    hidePanel() {
        this.MeiQia('hidePanel');
        this.isShowPanel = false;
    }
}();
