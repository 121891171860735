/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

export function applyDetail(id) {
    return get(this.url, { id });
}

export function applyList({ currentPage, pageSize }) {
    return get(this.url, {
        currentPage,
        pageSize,
    });
}

export function applyMaterialsDetail({ continuingEducationApplyId, applyMaterialsId }) {
    return get(this.url, {
        continuingEducationApplyId,
        applyMaterialsId,
    });
}

/**
 * 保存材料
 * @param continuingEducationApplyId
 * @param applyMaterialsId
 * @param applyMaterialsValue       json 串
 * @returns {Promise<*>}
 */
export function saveApplyMaterials({ continuingEducationApplyId, applyMaterialsId, applyMaterialsValue }) {
    return post(this.url, {
        continuingEducationApplyId,
        applyMaterialsId,
        applyMaterialsValue,
    });
}
export function submitAudit({
    companyName,
    workRegionId,
    continuingEducationApplyId,
    memberMsg,
    subsidyApplyType,
    signature,
    subsidyBankCard,
    bankAccount,
    openingBank,
}) {
    return post(this.url, {
        companyName,
        workRegionId,
        continuingEducationApplyId,
        memberMsg,
        subsidyApplyType,
        signature,
        subsidyBankCard,
        bankAccount,
        openingBank,
    });
}
