<!-- Created by henian.xu on 2018/9/12. -->

<template>
    <div class="widget">
        <!--<div class="console">{{ loadedLength }} / {{ componentLength }}</div>-->
        <template v-for="(item,index) in componentList">
            <x-shell
                v-if="index<loadedLength"
                :key="item.id"
                :data="item"
                @complete="onShellComplete"
            >
                {{ item.componentType }}
            </x-shell>
        </template>
        <Pagination
            ref="infinite"
            :no-show-complete="noShowComplete"
            :disabled="infiniteDisabled"
            @load="onload"
        />
    </div>
</template>

<script>
import storeMixin from './storeMixin';
import Shell from './_shell';

export default {
    name: 'Widget',
    mixins: [storeMixin],
    components: { XShell: Shell },
    data() {
        return {
            infiniteDisabled: true,
            loadedLength: 0,
        };
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        json: {
            type: String,
            default: '',
        },
        noShowComplete: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'page', // page;ad
        },
    },
    computed: {
        componentLength() {
            return this.componentList.length;
        },
        hasComponent() {
            return !!this.componentLength;
        },
        checkChange() {
            return this.json + this.id;
        },
        pageInfo() {
            const pageComponent = this.decorationData.find(item => item.componentType === 'Page');
            if (!pageComponent || !pageComponent.props) return null;
            return pageComponent.props;
        },
    },
    watch: {
        checkChange: {
            handler(/* val */) {
                // console.log(val);
                this.setDecoration();
            },
            immediate: true,
        },
        pageInfo: {
            handler(val) {
                if (!val) return;
                this.$emit('pageInfo', val);
            },
            immediate: true,
        },
        hasComponent: {
            handler(val) {
                this.infiniteDisabled = !val;
            },
            immediate: true,
        },
    },
    methods: {
        async getJsonData() {
            const { id, json, type } = this;
            if (json) return json;
            const fnMap = {
                ad: 'getAdPageJson',
                page: 'getServantIndexJson',
                activity: 'getActivityPageJson',
            };
            const { data } = await this.$api.Member.Sv.ServantDecoration[fnMap[type]](id);
            return data;
        },
        onShellComplete() {
            this.infiniteDisabled = false;
        },
        onload({ next, statusMap }) {
            this.loadedLength += 3;
            if (this.loadedLength >= this.componentLength) {
                next(statusMap.COMPLETE);
                console.log('complete');
            } else {
                next(statusMap.READY);
                this.infiniteDisabled = true;
            }
        },
    },
};
</script>

<style lang="scss">
.console {
    position: fixed;
    z-index: $z-index-10;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: $padding;
}
</style>
