<!-- Created by henian.xu on 2019/2/28. -->

<template>
    <div :class="classes">
        <div class="wrap">
            <div class="inner">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GridGroup',
    provide() {
        return { GridGroup: this };
    },
    data() {
        return {};
    },
    props: {
        column: {
            type: Number,
            default: 3,
        },
        border: {
            type: Boolean,
            default: false,
        },
        itemBorder: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            const { border, itemBorder } = this;
            return ['grid-group', { border }, { 'item-border': itemBorder }];
        },
    },
};
</script>

<style lang="scss">
.grid-group {
    padding: $padding;
    background-color: #fff;
    > .wrap {
        overflow: hidden;
        > .inner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            margin: -1px;
        }
    }
    &.border {
        > .wrap {
            border: 1px solid $color-border;
        }
    }
    &.item-border {
        > .wrap > .inner > .grid {
            border-style: solid;
            border-width: 1px 1px 1px 0;
            margin-top: -1px;
            border-color: $color-border;
        }
    }
}
</style>
