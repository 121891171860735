<!-- Created by henian.xu on 2019/3/1. -->

<script>
import { scrollMemory } from 'packages/mixins';

export default {
    name: 'TabNav',
    mixins: [scrollMemory],
    inject: ['Tab'],
    data() {
        return {
            selfEl: null,
            swiperEl: null,
            innerEl: null,
            itemElList: null,
            direction: 'forward',
        };
    },
    props: {
        panels: {
            type: Array,
            default: () => [],
        },
        button: {
            type: Boolean,
            default: false,
        },
        average: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentIndex: {
            get() {
                return this.Tab.currentIndex;
            },
            set(val) {
                this.Tab.currentIndex = val;
            },
        },
        selfElWidth: {
            get() {
                if (!this.selfEl) return 0;
                return (this.selfEl.$el || this.selfEl).offsetWidth;
            },
            cache: false,
        },
        innerElWidth: {
            get() {
                if (!this.innerEl) return 0;
                return this.innerEl.offsetWidth;
            },
            cache: false,
        },
        currentItemEl() {
            const { itemElList, currentIndex } = this;
            if (!itemElList) return {};
            const item = itemElList[currentIndex] || {};
            return item.$el || item || {};
        },
        currentItemElRect() {
            const { currentItemEl, currentIndex } = this;
            return {
                width: currentItemEl.offsetWidth,
                left: currentItemEl.offsetLeft,
                right: currentItemEl.offsetLeft + currentItemEl.offsetWidth + (currentIndex ? 2 : 1),
            };
        },
        barStyles() {
            const { currentItemElRect, innerElWidth } = this;
            const { left, right } = currentItemElRect;
            return {
                left: `${(left / innerElWidth) * 100}%`,
                right: `${100 - (right / innerElWidth) * 100}%`,
            };
        },
        classes() {
            const { button, average, border, Tab } = this;
            return [
                'tab-nav beautify-scrollbar',
                { button: button || Tab.button },
                { average: average || Tab.average },
                { border: border || Tab.border },
                // add
            ];
        },
        $$scrollbar() {
            return this.$refs.self;
        },
    },
    watch: {
        currentIndex: {
            handler(val, oldVal) {
                this.direction = val > oldVal ? 'forward' : 'backward';
                this.$nextTick(() => {
                    this.keepCenter();
                });
            },
            immediate: true,
        },
        panels: {
            handler() {
                this.$nextTick(() => {
                    this.itemElList = this.$refs.item;
                });
            },
        },
    },
    methods: {
        onItem($event, panel, index) {
            this.currentIndex = index;
            // console.log($event, panel, index);
        },
        keepCenter() {
            // console.log('keepCenter');
            if (this.selfEl) {
                this.scrollToActive();
            } else {
                this.$nextTick(this.keepCenter);
            }
        },
        scrollToActive() {
            const { requestAnimationFrame } = window;
            const { swiperEl, currentItemElRect, innerElWidth } = this;
            const {
                $swiper,
                $el: { offsetWidth },
            } = swiperEl;
            const { left, width } = currentItemElRect;
            let count = 0;
            const scrollDuration = 15;
            let currentOffset = $swiper.getTranslate();
            const totalOffset = left - (offsetWidth - width) / 2;
            const offset = (totalOffset + currentOffset) / scrollDuration;
            const maxOffset = offsetWidth - innerElWidth;
            const step = () => {
                currentOffset = $swiper.getTranslate();
                $swiper.setTranslate(Math.max(Math.min(currentOffset - offset, 0), maxOffset));
                count += 1;
                if (count < scrollDuration) requestAnimationFrame(step);
            };
            requestAnimationFrame(step);
        },

        buildLabel(panel /* , index */) {
            const { label } = panel.$scopedSlots;
            const { subLabel } = panel.$scopedSlots;
            const props = {
                ...panel.labelProps,
                // theme: this.Tab.currentIndex === index ? 'main' : '',
            };
            return <x-label props={props} scopedSlots={{ label, subLabel }} />;
        },
        buildIcon(panel, index) {
            const { icon } = panel.$scopedSlots;
            if (icon) return icon();
            if (!panel.icon) return '';
            const props = {
                content: panel.icon,
                size: panel.subLabel ? 'big' : '',
                theme: this.Tab.currentIndex === index ? 'main' : '',
            };
            return <x-icon props={props} />;
        },
        buildBadge(panel, index) {
            const { badge } = panel.$scopedSlots;
            if (badge) return badge();
            if (!panel.badge) return '';
            const { badgeTheme } = panel;
            const props = {
                label: panel.badge,
                size: 'small',
                theme: badgeTheme || (this.Tab.currentIndex === index ? 'main' : 'g5'),
            };
            return <Badge props={props} />;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.selfEl = this.$refs.self;
            this.swiperEl = this.$refs.swiper;
            // this.innerEl = this.$refs.inner;
            this.innerEl = this.swiperEl.$el.firstChild;
            this.itemElList = this.$refs.item;
        });
    },
    /* render2() {
        const { panels, /!* barStyles, direction, *!/ classes, Tab } = this;
        const { currentIndex } = Tab;
        return (
            <div ref="self" class={classes}>
                <div ref="inner" class="inner">
                    {panels.map((panel, index) => {
                        panel.index = index;
                        return (
                            <div
                                key={panel._uid}
                                ref="item"
                                refInFor={true}
                                class={['item', { active: currentIndex === index }]}
                                onClick={$event => this.onItem($event, panel, index)}
                            >
                                <div class="inner">
                                    {this.buildIcon(panel, index)}
                                    {this.buildLabel(panel, index)}
                                    {this.buildBadge(panel, index)}
                                </div>
                            </div>
                        );
                    })}
                    {/!* <div class={['bar', direction]} style={barStyles} /> *!/}
                </div>
            </div>
        );
    }, */
    render() {
        const { panels, /* barStyles, direction, */ classes, Tab } = this;
        const { currentIndex } = Tab;
        const swiperOption = {
            nested: true,
            // direction: 'vertical',
            // resistanceRatio: 0,
            slidesPerView: 'auto',
            freeMode: true,
            // slidesPerView: 'auto',
            // freeMode: true,
            // mousewheel: true,
        };
        return (
            <div ref="self" class={classes}>
                <swiper ref="swiper" class="inner" options={swiperOption}>
                    {panels.map((panel, index) => {
                        panel.index = index;
                        return (
                            <swiper-slide
                                key={panel._uid}
                                ref="item"
                                refInFor={true}
                                class={['item', { active: currentIndex === index }]}
                                onClick={$event => this.onItem($event, panel, index)}
                            >
                                <div class="inner">
                                    {this.buildIcon(panel, index)}
                                    {this.buildLabel(panel, index)}
                                    {this.buildBadge(panel, index)}
                                </div>
                            </swiper-slide>
                        );
                    })}
                    {/* <div class={['bar', direction]} style={barStyles} /> */}
                </swiper>
            </div>
        );
    },
};
</script>

<style lang="scss">
.tab-nav {
    overflow-y: hidden;
    overflow-x: auto;
    background-color: $color-component-bgc;
    padding: 0 $padding-small;

    &::-webkit-scrollbar {
        opacity: 0;
        display: none;
    }

    > .inner {
        > .swiper-wrapper {
            width: auto;
            position: relative;
            display: inline-flex;
            min-width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;

            > .item {
                flex: 0 0 auto;
                width: auto;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: $padding $padding-small;
                //background-color: #fff;
                cursor: pointer;
                min-height: $navHeight;

                + .item {
                    //border-left: 1px solid $color-border;
                }

                > .inner {
                    position: relative;
                    padding: 0 $padding-small;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    > .x-icon {
                        margin-right: $margin-small;
                    }

                    > .badge {
                        position: absolute;
                        z-index: $z-index-2;
                        top: 0;
                        left: calc(100% - 0.5em);
                        transform: translateY(-50%);
                        //margin-left: $margin-small;
                    }
                    > .x-label {
                        > .label,
                        > .sub-label {
                            white-space: nowrap;
                        }
                    }
                }

                &.active {
                    color: $color-main;
                    z-index: $z-index-1;
                }
            }

            > .bar {
                position: absolute;
                left: 0;
                bottom: -1px;
                /*width: 100px;*/
                height: 5px;
                background-color: $color-main;

                &.forward {
                    transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1),
                        left 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s;
                }
                &.backward {
                    transition: right 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.09s,
                        left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
                }
            }
        }
    }

    &.button {
        > .inner {
            > .swiper-wrapper {
                border: none;

                > .item {
                    > .inner {
                        background-color: $gray5;
                        color: #fff;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0 $padding-big;
                        border-radius: 100vw;
                        min-width: 160px;
                    }

                    &.active {
                        > .inner {
                            background-color: $color-main;
                        }
                    }
                }

                > .bar {
                    display: none;
                }
            }
        }
    }
    &.border {
        border-top: 1px solid $color-main;
        border-bottom: 1px solid $color-main;
    }

    &.average {
        > .inner {
            > .swiper-wrapper {
                > .item {
                    flex: 1 1 1%;
                }
            }
        }
    }
}
</style>
