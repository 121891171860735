/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

/**
 *
 * 报名列表
 * @param applyStatus   可以是逗号分割，如 1,2,3
 * @param currentPage
 * @param pageSize
 * @returns {Promise<*>}
 */
export function applyList({ applyStatus, currentPage, pageSize }, cfg = {}) {
    return get(
        this.url,
        {
            applyStatus,
            currentPage,
            pageSize,
        },
        {
            ...cfg,
        },
    );
}
export function applyDetail(id) {
    return get(this.url, { id });
}
export function applyMaterialsDetail({ certExamApplyId, applyMaterialsId }) {
    return get(this.url, {
        certExamApplyId,
        applyMaterialsId,
    });
}

/**
 * 保存材料
 * @param certExamApplyId
 * @param applyMaterialsId
 * @param applyMaterialsValue       json 串
 * @returns {Promise<*>}
 */
export function saveApplyMaterials({ certExamApplyId, applyMaterialsId, applyMaterialsValue }) {
    return post(this.url, {
        certExamApplyId,
        applyMaterialsId,
        applyMaterialsValue,
    });
}
export function submitAudit({
    companyName,
    workRegionId,
    certExamApplyId,
    memberMsg,
    certExamApplyType,
    signature,
    politicalLandscape,
}) {
    return post(this.url, {
        companyName,
        workRegionId,
        certExamApplyId,
        memberMsg,
        certExamApplyType,
        signature,
        politicalLandscape,
    });
}

// 公司选择 分页
export function companySelect({ keywords, currentPage, pageSize }) {
    return get(this.url, {
        keywords,
        currentPage,
        pageSize,
    });
}

export function applyInfo({ applyNo }, cfg = {}) {
    return get(this.url, { applyNo }, { ...cfg });
}

export function saveSignature({ certExamApplyId, certExamCourseDetailId, signature }, cfg = {}) {
    return post(
        this.url,
        {
            certExamApplyId,
            certExamCourseDetailId,
            signature,
        },
        {
            ...cfg,
        },
    );
}

export function applyPracticalList({ certExamApplyId }, cfg = {}) {
    return get(
        this.url,
        {
            certExamApplyId,
        },
        {
            ...cfg,
        },
    );
}

export function saveApplyPractical({ certExamApplyId, practicalProjectId, examTime, examScore }, cfg = {}) {
    return post(
        this.url,
        {
            certExamApplyId,
            practicalProjectId,
            examTime,
            examScore,
        },
        {
            ...cfg,
        },
    );
}

export function applyCourseLogList({ applyNo, currentPage, pageSize }, cfg = {}) {
    return get(
        this.url,
        {
            applyNo,
            currentPage,
            pageSize,
        },
        {
            ...cfg,
        },
    );
}
