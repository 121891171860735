/**
 * Created by henian.xu on 2020/4/21.
 *
 */

import { get } from 'api/request';

export function userMessageList({ currentPage, pageSize }) {
    return get(this.url, { currentPage, pageSize });
}

export function userMessageDetail(id) {
    return get(this.url, { id });
}
