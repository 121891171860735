/**
 * Created by henian.xu on 2020/3/31.
 *
 */

import { get, post } from 'api/request';

export function surveyItemList({ surveyItemStatus, currentPage, pageSize }) {
    return get(this.url, {
        surveyItemStatus,
        currentPage,
        pageSize,
    });
}
export function surveyItemDetail(id) {
    return get(this.url, { id });
}

export function saveSurveyItem({ id, evaluateListJson, suggest }) {
    return post(this.url, {
        id,
        evaluateListJson,
        suggest,
    });
}
export function submitAudit({
    companyName,
    workRegionId,
    certExamApplyId,
    memberMsg,
    certExamApplyType,
    signature,
    politicalLandscape,
}) {
    return post(this.url, {
        companyName,
        workRegionId,
        certExamApplyId,
        memberMsg,
        certExamApplyType,
        signature,
        politicalLandscape,
    });
}

// 公司选择 分页
export function companySelect({ keywords, currentPage, pageSize }) {
    return get(this.url, {
        keywords,
        currentPage,
        pageSize,
    });
}

export function applyInfo({ applyNo }) {
    return get(this.url, { applyNo });
}
