<!-- Created by henian.xu on 2019/5/14. -->

<!--<template>
    <div class="category-tab-nav">CategoryTabNav</div>
</template>-->

<script lang="jsx">
export default {
    name: 'CategoryTabNav',
    inject: ['Tab'],
    data() {
        return {};
    },
    props: {
        panels: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        currentIndex: {
            get() {
                return this.Tab.currentIndex;
            },
            set(val) {
                this.Tab.currentIndex = val;
            },
        },
    },
    methods: {
        onItem($event, panel, index) {
            this.currentIndex = index;
            // console.log($event, panel, index);
        },
    },
    render() {
        const { currentIndex, panels, onItem } = this;
        return (
            <div class="category-tab-nav beautify-scrollbar">
                {panels.map((panel, index) => {
                    // eslint-disable-next-line no-param-reassign
                    panel.index = index;
                    return (
                        <div
                            class={['item', { active: currentIndex === index }]}
                            key={panel._uid}
                            ref="item"
                            refInFor={true}
                            onClick={$event => onItem($event, panel, index)}
                        >
                            <XIcon content={panel.icon}></XIcon>
                            <div class="label">{panel.label}</div>
                        </div>
                    );
                })}
            </div>
        );
    },
};
</script>

<style lang="scss">
.category-tab-nav {
    flex: 0 0 auto;
    //min-width: 4em;
    //max-width: 6em;
    width: 160px;
    background-color: $gray2;
    overflow-scrolling: touch;
    > .item {
        padding: $padding ($formItemHeight - 1.2 * $body-font-size)/2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        border-left: 5px solid transparent;
        min-height: 104px;

        &.active {
            color: $color-main;
            background-color: #fff;
            border-color: $color-main;
        }
    }
}
</style>
