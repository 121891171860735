<!-- Created by henian.xu on 2019/5/14. -->

<template>
    <div class="category-tab">
        <CategoryTabNav :panels="panels" />
        <div class="content">
            <div
                v-if="isPanel"
                ref="float"
                class="category-tab-panel-header float"
                :style="floatStyle" />
            <div
                class="scroll"
                ref="scroll"
                @scroll="onScroll">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import Animate from 'utils/Animate';
import CategoryTabNav from './categoryTabNav';

import { scrollMemory } from '../mixins';

export default {
    name: 'CategoryTab',
    mixins: [scrollMemory],
    components: { CategoryTabNav },
    provide() {
        return { Tab: this };
    },
    data() {
        return {
            isMounted: false,
            onScrolling: false,
            scrollTop: 0,
            floatHeight: 0,
            panels: [],
            currentIndex_: 0, // 当未绑定 model 时才有用
            scrollTopAnimate: null,
            isAnimate: false,
        };
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        data: {
            type: Array,
            default: null,
        },
        prop: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        prop_() {
            const { prop } = this;
            return {
                id: 'id',
                label: 'label',
                icon: 'icon',
                ...prop,
            };
        },
        isPanel() {
            return !this.data;
        },
        currentIndex: {
            get() {
                const { value, currentIndex_ } = this;
                return value === null ? currentIndex_ : value;
            },
            set(val) {
                if (this.value === null) {
                    this.currentIndex_ = val;
                } else {
                    this.$emit('input', val);
                }
                this.$emit('change', this.panels[val], val);
            },
        },
        defaultPanels() {
            return this.$slots.default.filter(VNode => /^vue-component(.*)TabPanel$/.test(VNode.tag));
        },
        currentPanel() {
            const { currentIndex, panels } = this;
            if (!panels || !panels.length) return null;
            return panels[currentIndex];
        },
        floatStyle() {
            const { currentPanel, floatHeight } = this;
            if (!currentPanel) return {};
            const { currentTop, currentBottom } = currentPanel;
            const top = currentTop + currentBottom;
            if (top > floatHeight) return {};
            return {
                top: `${top - floatHeight + 1}px`,
            };
        },
        $$float() {
            const { $refs, isMounted } = this;
            const { float } = $refs;
            return !isMounted ? null : float || null;
        },
        $$scroll() {
            const { $refs, isMounted } = this;
            const { scroll } = $refs;
            return !isMounted ? null : scroll || null;
        },

        $$scrollbar() {
            return this.$refs.scroll;
        },
    },
    watch: {
        currentIndex: {
            handler() {
                this.checkPosition();
            },
            immediate: true,
        },
        data: {
            handler(val) {
                if (!val || !val.length) return;
                const { prop_: prop } = this;
                this.panels = val.map((item, index) => {
                    return {
                        index,
                        id: item[prop.id],
                        _uid: item[prop.id],
                        label: item[prop.label],
                        icon: item[prop.icon],
                    };
                });
                // this.currentIndex = 0; // 应该在组件外部自行处理
            },
            immediate: true,
        },
    },
    methods: {
        addPanel(panel) {
            const { panels, defaultPanels, isPanel } = this;
            if (!isPanel) return;
            const index = defaultPanels.indexOf(panel.$vnode);
            if (index === -1) return;
            panels.splice(index, 0, panel);
        },
        floatAppendChild(el) {
            const { $$float } = this;
            $$float.appendChild(el);
            this.$nextTick(() => {
                this.floatHeight = $$float.clientHeight;
            });
        },
        onScroll() {
            if (this.onScrolling || !this.isPanel) return;
            this.onScrolling = true;
            window.requestAnimationFrame(() => {
                this.onScrolling = false;
                this.scrollTop = this.$$scroll.scrollTop;
            });
        },
        checkPosition() {
            if (!this.isPanel) return;
            const { currentPanel, scrollTopAnimate, scrollTop } = this;
            if (!currentPanel) {
                this.$nextTick(() => this.checkPosition());
                return;
            }
            if (currentPanel.inRange) return;
            // $$scroll.scrollTop = currentPanel.$el.offsetTop;
            scrollTopAnimate.stop();
            scrollTopAnimate.start(scrollTop, currentPanel.$el.offsetTop, 1000);
        },
        onScrollTopAniStep(val) {
            this.isAnimate = true;
            this.$$scroll.scrollTop = val;
        },
        onScrollTopAniCompleted() {
            this.isAnimate = false;
        },
    },
    created() {
        this.scrollTopAnimate = this.isPanel
            ? new Animate(this.onScrollTopAniStep, this.onScrollTopAniCompleted, this.onScrollTopAniCompleted)
            : null;
    },
    mounted() {
        this.isMounted = true;
    },
};
</script>

<style lang="scss">
.category-tab {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .content {
        position: relative;
        height: 100%;
        flex: 1 1 1%;
        background-color: #fff;
        .category-tab-panel-header {
            > .inner {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: $formItemHeight + $padding-small * 2;
                padding: 0 $padding-small;
                background-color: rgba(246, 249, 252, 0.9);
                border-top: 1px solid $color-border;
                border-bottom: 1px solid $color-border;
                > .label {
                }
            }
            &.float {
                position: absolute;
                z-index: $z-index-2;
                top: 0;
                left: 0;
                right: 0;
            }
        }
        > .scroll {
            position: relative;
            z-index: $z-index-1;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            overflow-scrolling: touch;
        }
    }
}
</style>
