<!-- Created by henian.xu on 2020/4/9. -->

<template>
    <div
        :class="classes"
        v-bind="linkerProps"
        v-on="excludeInputListeners"
    >
        <div class="content">
            <template v-if="type_!==7">
                <XImage
                    v-if="type_!==3"
                    class="img-obj"
                    fit="cover"
                    :src="data.titleImageUrl"
                    :aspect-ratio="imageAspectRatio"
                />
                <ImageList
                    v-else-if="data.imageList && data.imageList.length"
                    class="img-obj"
                    fit="cover"
                    :data="data.imageList"
                    :columns="3"
                    :max-show="3"
                    item-height="100%"
                    :aspect-ratio="imageAspectRatio"
                />
            </template>
            <div class="info">
                <div class="header">
                    <div class="label">{{data.title}}</div>
                    <XIcon
                        v-if="type_===7"
                        content="f012"
                        size="small"
                        theme="g5"></XIcon>
                </div>
                <div
                    v-if="type_===2||type_===4"
                    class="body"
                >
                    <div class="sub-label">{{data.subtitle}}</div>
                    <div class="digest">{{data.digest}}</div>
                </div>
            </div>
        </div>
        <div
            v-if="type_!==7"
            class="footer">
            <div class="item">{{data.source}}</div>
            <div class="item">{{data.releaseTime | moment}}</div>
        </div>
        <!--type:&#45;&#45;{{type}}&#45;&#45;-->
    </div>
</template>

<script>
import { linkerMixin, listenersMixin } from 'packages/mixins';

export default {
    name: 'CmsItem',
    mixins: [linkerMixin, listenersMixin],
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: [Number, String],
            default: 1,
        },
    },
    computed: {
        type_() {
            return +this.type || 1;
        },
        classes() {
            const { type_: type } = this;
            return [
                'cms-item',
                { double: type === 5 || type === 6 },
                { customize: type === 6 },
                { 'img-top': type === 1 },
                { 'img-right': type === 2 },
                { 'img-bottom': type === 3 },
                { 'img-left': type === 4 },
                { 'img-none': type === 7 },
                // add
            ];
        },
        imageAspectRatio() {
            const { type_ } = this;
            if (type_ === 1) {
                return 9 / 16;
            }
            return 1;
        },
        imageList() {
            const { data, type_: type } = this;
            let res = [data.titleImageUrl];
            if (type === 3) {
                res = [...data.imageList];
            }
            return res.splice(0, 3);
        },
    },
};
</script>

<style lang="scss">
.cms-item {
    display: block;
    padding: $padding;
    background-color: $color-component-bgc;

    > .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        > .img-obj {
            flex: 0 0 auto;
        }
        > .info {
            flex: 1;

            > .header {
                margin-top: $margin;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                > .label {
                    flex: 1;
                    @include text-line(2);
                    font-weight: bold;
                }
            }
            > .body {
                margin-top: $margin;
            }
        }
    }
    > .footer {
        margin: $margin 0 0 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &.img-left,
    &.img-right {
        > .content {
            flex-direction: row;
            > .img-obj {
                width: 30%;
                margin-right: $margin-small;
            }
            > .info {
                > .header {
                    margin-top: 0;
                }
            }
        }
    }
    &.img-right {
        > .content {
            > .img-obj {
                order: 2;
                margin-right: 0;
                margin-left: $margin-small;
            }
            > .info {
                order: 0;
            }
        }
    }

    &.img-bottom {
        > .content {
            > .img-obj {
                order: 2;
                margin-top: $margin;
            }
            > .info {
                order: 0;
                > .header {
                    margin-top: 0;
                }
            }
        }
    }

    &.double {
        padding: 0;
        > .content {
            > .info {
                padding: 0 $padding;
            }
        }
        > .footer {
            padding: 0 $padding $padding $padding;
        }
    }
    &.img-none {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        min-height: $navHeight;
        > .content {
            > .info {
                > .header {
                    margin-top: 0;
                    > .label {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
</style>
