/**
 * Created by henian.xu on 2020/4/7.
 *
 */
import { Api } from 'api/index';

export default {
    namespaced: true,
    state: {
        rawData: {
            practiceTypeList: [
                {
                    id: 1,
                    name: '顺序练习',
                },
                {
                    id: 2,
                    name: '章节练习',
                },
                {
                    id: 3,
                    name: '专项练习',
                },
                {
                    id: 4,
                    name: '随机练习',
                },
                {
                    id: 5,
                    name: '错题本',
                },
            ],
        },
    },
    getters: {
        practiceTypeList(state) {
            return state.rawData.practiceType;
        },
        feedbackTypeList(state) {
            const dicName = 'feedbackType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        shipMethod(state) {
            const dicName = 'shipMethod';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        courseType(state) {
            const dicName = 'courseType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        courseTypeMap(state, getters) {
            return getters.courseType.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        cmsArticleType(state) {
            const dicName = 'cmsArticleType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        questionType(state) {
            const dicName = 'questionType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        questionTypeMap(state, getters) {
            return getters.questionType.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        applyStatusList(state) {
            const dicName = 'applyStatus';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        applyStatusMap(state, getters) {
            return getters.applyStatusList.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        educationList(state) {
            const dicName = 'education';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        educationMap(state, getters) {
            return getters.educationList.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        certExamApplyType(state) {
            const dicName = 'certExamApplyType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        certExamApplyTypeMap(state, getters) {
            return getters.certExamApplyType.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        invoiceType(state) {
            const dicName = 'invoiceType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        invoiceTypeMap(state, getters) {
            return getters.invoiceType.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        invoiceTitleType(state) {
            const dicName = 'invoiceTitleType';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        invoiceTitleTypeMap(state, getters) {
            return getters.invoiceTitleType.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        enterpriseCooperationStatusList(state) {
            const dicName = 'enterpriseCooperationStatus';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        enterpriseCooperationStatusMap(state, getters) {
            return getters.enterpriseCooperationStatusList.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        codeStatusList(state) {
            const dicName = 'codeStatus';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        codeStatusMap(state, getters) {
            return getters.codeStatusList.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
        refundApplyStatusList(state) {
            const dicName = 'refundApplyStatus';
            const { rawData } = state;
            const dic = rawData[dicName];
            if (!dic) this.dispatch('getDictionary', { dicName });
            return dic || [];
        },
        refundApplyStatusMap(state, getters) {
            return getters.refundApplyStatusList.reduce((pre, cur) => {
                pre[cur.id] = cur;
                return pre;
            }, {});
        },
    },
    mutations: {
        _setDictionary(state, { dicName, data, addAll = false }) {
            if (!dicName) return;
            const temObj = { ...state.rawData };
            if (addAll) data.unshift({ id: '', name: '全部' });
            temObj[dicName] = data;
            state.rawData = temObj;
        },
    },
    actions: {
        async getDictionary({ commit }, { dicName, addAll }) {
            const res = await Api.Comm.Rs.Dic.dicEntry(dicName);
            commit('_setDictionary', { dicName, data: [...res.data], addAll });
            return res;
        },
    },
};
