/**
 * Created by henian.xu on 2020/2/6.
 * 该插件使 getters 中的方法 this 指向 {store,dispatch,commit}
 * store: 当前根 store 实例
 * dispatch: 当前模块 dispatch 方法
 * commit: 当前模块 commit 方法
 */

function forEachModules(store, modules, path = []) {
    Object.keys(modules).forEach(moduleKey => {
        const module = modules[moduleKey];
        const {
            namespaced,
            _rawModule: rawModule,
            _children: children,
            context,
            // add
        } = module;
        const newPath = namespaced ? path.concat(moduleKey) : path;
        const { getters } = rawModule;
        Object.keys(getters).forEach(key => {
            const methodsKey = newPath.concat(key).join('/');
            const { _vm: { _computedWatchers: computedWatchers = {} } = {} } = store;
            if (computedWatchers[methodsKey]) {
                computedWatchers[methodsKey].getter = () => {
                    return getters[key].call(
                        {
                            store,
                            dispatch: context.dispatch,
                            commit: context.commit,
                        }, // local context
                        context.state, // local state
                        context.getters, // local getters
                        store.state, // root state
                        store.getters, // root getters
                    );
                };
            }
        });
        forEachModules(store, children, newPath);
    });
}

export default store => forEachModules(store, store._modules);
