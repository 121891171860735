/**
 * Created by henian.xu on 2020/4/2.
 *
 */

import { get, post } from 'api/request';

/**
 * 确认订单
 * @param skuId
 * @param quantity
 * @param uuid
 * @returns {Promise<*>}
 */
export function buyNowConfirm({ applyId, orderType, skuId, quantity, uuid }, config) {
    return get(
        this.url,
        {
            applyId,
            orderType,
            skuId,
            quantity,
            uuid,
        },
        {
            ...config,
        },
    );
}

/**
 * 创建订单
 * @param shipMethod
 * @param usePoint
 * @param deliveryConsignee
 * @param deliveryPhone
 * @param deliveryRegionId
 * @param deliveryAddress
 * @param memberMsg
 * @param extendFieldInfo
 * @param discountCodeId        学习码id
 * @param uuid
 * @returns {Promise<*>}
 */
export function createOrder({
    shipMethod,
    usePoint,
    deliveryConsignee,
    deliveryPhone,
    deliveryRegionId,
    deliveryAddress,
    memberMsg,
    extendFieldInfo,
    discountCodeId,
    uuid,
}) {
    return post(this.url, {
        shipMethod,
        usePoint,
        deliveryConsignee,
        deliveryPhone,
        deliveryRegionId,
        deliveryAddress,
        memberMsg,
        extendFieldInfo,
        discountCodeId,
        uuid,
    });
}

/**
 * 订单列表
 * @param orderStatus            // 订单状态 10待付款 20待发货 30已发货 99已取消 100已完成
 * @param invoiceStatus            // 开票状态 ''全部 1未开票 100已开票
 * @param currentPage
 * @param pageSize
 * @returns {Promise<*>}
 */
export function orderList({ orderStatus, invoiceStatus, currentPage, pageSize }) {
    return get(this.url, { orderStatus, invoiceStatus, currentPage, pageSize });
}

export function orderDetail(orderId) {
    return get(this.url, { orderId });
}

export function orderRefundApply(orderId) {
    return post(this.url, { orderId });
}
