/**
 * Created by henian.xu on 2020/4/10.
 *
 */

import { get, post } from 'api/request';

export function examPaperExperienceList({ ids, currentPage, pageSize }) {
    return get(this.url, { ids, currentPage, pageSize });
}
export function getExamPaper(id) {
    return get(this.url, { id });
}

/**
 * 提交试卷
 * @param id
 * @param questionJson          [{id:1,selectOption:1}]
 * @returns {Promise<*>}
 */
export function completeExamPaper({ id, questionJson }) {
    return post(this.url, { id, questionJson });
}
